import { useState, useEffect, useMemo } from 'react'
import { SteppedModalProps } from './SteppedModal'
import { ValidationSummary } from './ValidationSummary'

interface ComponentProps extends Record<string, unknown> {
  onSubmit: (...args: unknown[]) => Promise<void>
}

export type ValidationItem = {
  component: React.ComponentType<any>
  componentProps: ComponentProps
  message: string
  nextStep: string
  stepKey: string
  stepName: string
}

type StepAction = {
  closeModal: boolean
  text: string
  onAction: () => Promise<void>
}

export type Step = {
  component: React.ComponentType<any>
  componentProps: Record<string, any>
  key: string
  showBreadcrumb: boolean
  wrapper: 'MODAL' | 'DIALOG'
  actions?: {
    cancel: StepAction
    confirm: StepAction
  }
  name?: string
  modalTitle?: string
}

const convertValidationItemsToSteps = (
  validation: ValidationItem[],
  goToStep: (step: string) => void
): Step[] => {
  return validation.map(
    ({ stepName, component, componentProps, stepKey, nextStep }) => {
      return {
        component,
        componentProps: {
          ...componentProps,
          onSubmit: async (...args: unknown[]) => {
            await componentProps.onSubmit(...args)
            goToStep(nextStep)
          }
        },
        key: stepKey,
        name: stepName,
        showBreadcrumb: true,
        wrapper: 'MODAL'
      }
    }
  )
}

export const useSteppedModal = (props: SteppedModalProps) => {
  const { onClose, onFinalConfirm } = props
  const [activeStepName, setActiveStepName] = useState<string>('')
  const goToStep = (stepKey: string) => setActiveStepName(stepKey)

  const summaryStep: Step = {
    actions: {
      cancel: {
        closeModal: false,
        text: 'Skip for now',
        onAction: () => Promise.resolve(goToStep('CONFIRM'))
      },
      confirm: {
        closeModal: false,
        text: 'Add missing information',
        onAction: () => Promise.resolve()
      }
    },
    component: ValidationSummary,
    componentProps: { validation: props.validation },
    key: 'SUMMARY',
    modalTitle: 'Before you continue...',
    showBreadcrumb: false,
    wrapper: 'DIALOG'
  }

  const confirmationStep: Step = {
    actions: {
      cancel: {
        closeModal: true,
        text: 'Cancel',
        onAction: () => Promise.resolve(onClose())
      },
      confirm: {
        closeModal: true,
        text: 'Start schedule',
        onAction: onFinalConfirm
      }
    },
    component: () =>
      'The schedule will start generating invoices based on the defined prices and billing dates.',
    componentProps: {},
    key: 'CONFIRM',
    modalTitle: 'Start schedule',
    showBreadcrumb: false,
    wrapper: 'DIALOG'
  }

  const validationSteps: Array<Step> = convertValidationItemsToSteps(
    props.validation,
    goToStep
  )

  useEffect(() => {
    if (props.validation.length === 0) {
      setActiveStepName(confirmationStep.key)
      return
    }

    setActiveStepName(summaryStep.key)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const steps =
    props.validation.length === 0
      ? [confirmationStep]
      : [summaryStep, ...validationSteps, confirmationStep]

  const activeStep = useMemo(
    () => steps.find(({ key }) => key === activeStepName),
    [activeStepName]
  )

  const transitionToNextStep = () => {
    if (activeStep && steps.indexOf(activeStep) !== steps.length - 1) {
      const nextStepIndex = steps.indexOf(activeStep) + 1
      const nextStep = steps[nextStepIndex]
      setActiveStepName(nextStep.key)
    }
  }

  return { activeStep, transitionToNextStep }
}
