import {
  LinearPrice,
  PackagedPrice,
  Price,
  Product
} from 'common/drawers/PricingEditor/domain'
import { Flex, Grid, Text } from '@chakra-ui/react'
import { Badge } from '@sequencehq/core-components'
import {
  GreyGrey100,
  GreyGrey70,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { capitalize, kebabCase } from 'lodash'
import ClipboardIcon from '@heroicons/react/16/solid/ClipboardIcon'
import {
  enforceMinimumPrecision,
  toBillingFrequencyLabel,
  toBillingType,
  toMoney,
  toPriceSummary
} from '@sequencehq/utils'
import {
  NEW_PRICE_PATTERN,
  PRICING_TYPES_WITH_PERCENTAGE
} from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { match } from 'ts-pattern'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { Link } from 'react-router-dom'
import { internalPriceToApiPrice } from 'common/drawers/PricingEditor/communication/external/v1/adapters/pricingEditor.adapters'

const pricingTypeLabel = (pricingModel: Price['common']['pricingModel']) => {
  return match(pricingModel)
    .with('SEAT_BASED_GRADUATED', 'SEAT_BASED_LINEAR', () => 'Seat based')
    .otherwise(() => capitalize(kebabCase(pricingModel)))
}

const deriveIsPricePercentage = (price: Price): boolean => {
  if (
    price.common.pricingModel === 'VOLUME' &&
    'percentageTiers' in price.modelSpecific
  ) {
    return price.modelSpecific.usageTierType === 'PERCENTAGE'
  }

  if (
    price.common.pricingModel === 'GRADUATED' &&
    'percentageTiers' in price.modelSpecific
  ) {
    return price.modelSpecific.usageTierType === 'PERCENTAGE'
  }

  if (
    price.common.pricingModel === 'LINEAR' &&
    'linearPriceType' in price.modelSpecific
  ) {
    return price.modelSpecific.linearPriceType === 'PERCENTAGE'
  }

  return false
}

const LinearPricingFields = ({ price }: { price: LinearPrice }) => {
  return (
    <>
      {price.modelSpecific.linearPriceType === 'PERCENTAGE' ? (
        <>
          <Text {...Lato13Regular}>Percentage</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {enforceMinimumPrecision(2)(price.modelSpecific.price)}%
          </Text>
        </>
      ) : (
        <>
          <Text {...Lato13Regular}>Price per unit</Text>
          <Text
            {...Lato13Regular}
            color={GreyGrey90}
            data-testid="pricingEditor.review.pricePerUnit"
          >
            {toMoney({
              currency: price.common.currency,
              value: price.modelSpecific.price
            })}
          </Text>
        </>
      )}
      {price.modelSpecific.minPrice && (
        <>
          <Text {...Lato13Regular}>Min. Price</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {toMoney({
              currency: price.common.currency,
              value: price.modelSpecific.minPrice
            })}
          </Text>
        </>
      )}
      {price.modelSpecific.maxPrice && (
        <>
          <Text {...Lato13Regular}>Max. Price</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {toMoney({
              currency: price.common.currency,
              value: price.modelSpecific.maxPrice
            })}
          </Text>
        </>
      )}
    </>
  )
}

const PackagePricingFields = ({ price }: { price: PackagedPrice }) => {
  return (
    <>
      {price.modelSpecific.pricePerPackage && (
        <>
          <Text {...Lato13Regular}>Price per package</Text>
          <Text
            {...Lato13Regular}
            color={GreyGrey90}
            data-testid="pricingEditor.review.pricePerPackage"
          >
            {toMoney({
              currency: price.common.currency,
              value: price.modelSpecific.pricePerPackage
            })}
          </Text>
        </>
      )}
      {price.modelSpecific.packageSize && (
        <>
          <Text {...Lato13Regular}>Package size</Text>
          <Text
            {...Lato13Regular}
            color={GreyGrey90}
            data-testid="pricingEditor.review.packageSize"
          >
            {price.modelSpecific.packageSize}
          </Text>
        </>
      )}
    </>
  )
}

const PriceUsageMetric = ({ usageMetricId }: { usageMetricId: string }) => {
  const pricingEditorContext = usePricingEditorDomainContext()
  const usageMetric =
    pricingEditorContext.queries.rawData.data.usageMetrics[usageMetricId]

  const linkToUsageMetric = `/usage-metrics/${usageMetric.id}`

  return (
    <>
      <Text {...Lato13Regular}>Usage metric</Text>
      <Link to={linkToUsageMetric}>
        <Text
          {...Lato13Bold}
          color={IndigoIndigo50}
          data-testid="pricingEditor.review.usageMetric"
        >
          {usageMetric.name}
        </Text>
      </Link>
    </>
  )
}

export const PriceMeta = ({
  price,
  product
}: {
  price: Price
  product?: Product
}) => {
  const handleCopy = () => {
    void navigator.clipboard.writeText(price.id)
  }

  return (
    <Flex flexDirection="column">
      <Text {...Lato14Bold} color={GreyGrey100}>
        Details
      </Text>
      <Grid templateColumns="160px 1fr" gap={3} mt={4} color={GreyGrey70}>
        {product && (
          <>
            <Text {...Lato13Regular}>Product name</Text>
            <Text
              {...Lato13Regular}
              color={GreyGrey90}
              data-testid="pricingEditor.review.productName"
            >
              {product.name}
            </Text>

            {product.label && (
              <>
                <Text {...Lato13Regular}>Product description</Text>
                <Text {...Lato13Regular} color={GreyGrey90}>
                  {product.label}
                </Text>
              </>
            )}
          </>
        )}
        <Text {...Lato13Regular}>Pricing model</Text>
        <Text
          {...Lato13Regular}
          color={GreyGrey90}
          data-testid="pricingEditor.review.pricingModel"
        >
          {`${pricingTypeLabel(price.common.pricingModel)} pricing`}
        </Text>
        <Text {...Lato13Regular}>Billing frequency</Text>
        <Text
          {...Lato13Regular}
          color={GreyGrey90}
          data-testid="pricingEditor.review.billingFrequency"
        >
          {toBillingFrequencyLabel(price.modelSpecific.billingFrequency)} /{' '}
          {toBillingType(price.modelSpecific.billingType)}
        </Text>

        {'usageMetricId' in price.modelSpecific && (
          <PriceUsageMetric usageMetricId={price.modelSpecific.usageMetricId} />
        )}

        {PRICING_TYPES_WITH_PERCENTAGE.includes(price.common.pricingModel) && (
          <>
            <Text {...Lato13Regular}>Type</Text>
            <Text {...Lato13Regular} color={GreyGrey90}>
              {deriveIsPricePercentage(price) ? 'Percentage' : 'Fixed'}
            </Text>
          </>
        )}

        {price.common.pricingModel === 'LINEAR' ? (
          <LinearPricingFields price={price as LinearPrice} />
        ) : price.common.pricingModel === 'PACKAGED' ? (
          <PackagePricingFields price={price as PackagedPrice} />
        ) : (
          <>
            <Text {...Lato13Regular}>Price</Text>
            <Text
              {...Lato13Regular}
              color={GreyGrey90}
              data-testid="pricingEditor.review.price"
            >
              {toPriceSummary({
                id: price.id,
                ...internalPriceToApiPrice(price.common.productId)(price)
              })}
            </Text>
          </>
        )}

        {!price.id.match(NEW_PRICE_PATTERN) && (
          <>
            <Text {...Lato13Regular}>Price ID</Text>
            <Flex gap={2}>
              <Badge
                sentiment="neutral"
                containerStyle={{
                  height: '16px',
                  padding: 1
                }}
              >
                {price.id}
              </Badge>
              <ClipboardIcon
                width="14px"
                color="gray.60"
                onClick={handleCopy}
                cursor="pointer"
              />
            </Flex>
          </>
        )}
      </Grid>
    </Flex>
  )
}
