import { v1ApiLoadedData } from '../../loadData'
import { PricingEditorPortErrors } from 'common/drawers/PricingEditor/domain'
import {
  INITIAL_PRODUCT_DATA,
  NEW_PRODUCT_PATTERN
} from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'

type LoadProduct = (id: string) => Promise<
  | {
      data: v1ApiLoadedData['product']
      error: null
    }
  | {
      data: null
      error: PricingEditorPortErrors
    }
>

export const loadProduct: LoadProduct = async (id: string) => {
  if (id.match(NEW_PRODUCT_PATTERN)) {
    return {
      data: {
        ...INITIAL_PRODUCT_DATA,
        id
      },
      error: null
    }
  }

  const product = await dashboardv99990101Client.getProduct({ id })

  if (!product.data) {
    return {
      data: null,
      error:
        product.res.status === 404
          ? PricingEditorPortErrors.NotFound
          : PricingEditorPortErrors.Other
    }
  }

  return {
    data: product.data,
    error: null
  }
}
