import { Grid, GridItem } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey70,
  GreyGrey90,
  Lato13Regular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { useJournalReportSummary } from './useJournalReportSummary'
import { formatTotal } from 'RevenueRecognition/view/utils/formatTotal'
import { useSelectedDates } from './selectorHooks'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'
import { Skeleton } from '@sequencehq/core-components'
import { format } from '@sequencehq/utils/dates'

export const JournalReportSummary = () => {
  const { currency } = useSelectedCurrency()
  const dates = useSelectedDates()
  const { summary, isLoading } = useJournalReportSummary({
    currency,
    dates
  })

  if (isLoading) {
    return <Skeleton height="96px" width="100%" />
  }

  return (
    <Grid
      data-testid="revrec.detail.reportSummary"
      gridTemplateRows="auto auto"
      justifyItems="center"
      padding="24px 16px"
      backgroundColor={GreyGrey10}
      borderRadius="8px"
      rowGap="8px"
    >
      <GridItem {...Lato16Bold} color={GreyGrey90}>
        {format(summary.date, 'MMMM yyyy')}
      </GridItem>
      <GridItem {...Lato13Regular} color={GreyGrey70}>
        Debit:{' '}
        <span style={{ fontWeight: 700 }}>
          {formatTotal({
            currency: summary.currency,
            value: summary.totals.debit
          })}
        </span>
        <span style={{ margin: '0 6px' }}>·</span>Credit:{' '}
        <span style={{ fontWeight: 700 }}>
          {' '}
          {formatTotal({
            currency: summary.currency,
            value: summary.totals.credit
          })}
        </span>
      </GridItem>
    </Grid>
  )
}
