import { useNotifications } from 'lib/hooks/useNotifications'
import { useLoadQuoteEditor } from 'modules/Cube/communication/external/quotes.api.v1/ports/useLoadQuoteEditor'
import { useSaveQuoteEditor } from 'modules/Cube/communication/external/quotes.api.v1/ports/useSaveQuoteEditor'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import {
  ContactPostBody,
  CorePortErrors,
  CubePortImplementation,
  CubePortImplementationProp,
  QuoteCubePorts
} from 'modules/Cube/domain/cube.domain.types'
import { useCallback, useState } from 'react'
import { quoteAdapters } from 'modules/Cube/communication/external/quotes.api.v1/adapters/quote.adapters'
import { useNavigate } from 'react-router-dom'
import { useLoadContacts } from './ports/entityLoaders'
import { useCreateContact } from './ports/entitySaving/useCreateContact'

type UseQuotePorts = (props: { quoteId?: string }) => CubePortImplementation

export const useQuotePorts: UseQuotePorts = props => {
  const quoteLoader = useLoadQuoteEditor()
  const saveQuoteEditor = useSaveQuoteEditor()
  const notifications = useNotifications()
  const navigate = useNavigate()
  const loadContacts = useLoadContacts()
  const createContactHook = useCreateContact()

  const [existingData, setExistingData] =
    useState<CubeDomainInterface['queries']['rawData']['data']>()

  const saveQuoteDraft = useCallback(
    async (domainOutput: CubeDomainInterface['queries']) => {
      if (!existingData) {
        throw new Error("Can't save without initialising first")
      }

      const dataToSave = quoteAdapters.out(domainOutput.rawData.data)
      const { products, ...data } = dataToSave

      if (domainOutput.rawData.data.common.id !== 'uninitialized') {
        const saveResult = await saveQuoteEditor.updateQuote(
          domainOutput.rawData.data.common.id
        )(data, products.new)

        if (saveResult.success) {
          return
        }
      } else {
        notifications.displayNotification('Creating quote...', {
          duration: 30000
        })

        const createResult = await saveQuoteEditor.createQuote()
        if (createResult.success) {
          notifications.displayNotification('Quote created', {
            type: 'success'
          })

          if (createResult.quote?.id) {
            navigate(`/quotes/${createResult.quote.id}`, { replace: true })
          }
        }
        return
      }

      notifications.displayNotification('Failed to save quote', {
        type: 'error'
      })
      return
    },
    [existingData, navigate, notifications, saveQuoteEditor]
  )

  const loadCore = useCallback(
    (ctx: CubePortImplementationProp) => async () => {
      const { data: quoteData, error: quoteError } = await quoteLoader(ctx)(
        props.quoteId
      )

      if (quoteError || !quoteData) {
        return {
          data: null,
          error: quoteError || CorePortErrors.Other
        }
      }

      const resolvedData = quoteAdapters.in(quoteData)

      if (resolvedData.data.common.id !== props.quoteId) {
        navigate(`/quotes/${resolvedData.data.common.id}`, { replace: true })
      }

      setExistingData(resolvedData.data)

      const data = await Promise.resolve({
        data: resolvedData.data,
        configuration: {
          ...ctx.configuration,
          currency: {
            default: quoteData.currencies[0],
            enabled: quoteData.currencies
          }
        }
      })

      return {
        data,
        error: null
      }
    },
    [quoteLoader, props.quoteId, navigate]
  )

  const reloadContacts = useCallback(
    async (args: { customerId: string }) => {
      const contacts = await loadContacts(args.customerId)

      return contacts.map(contact => ({
        ...contact,
        customerId: args.customerId
      }))
    },
    [loadContacts]
  )

  const publishQuote = useCallback(
    async (data: {
      sendRecipientsEmail: boolean
      emailMessage: string | undefined
    }) => {
      if (!existingData || !props.quoteId) {
        throw new Error("Can't save without initialising first")
      }

      notifications.displayNotification('Publishing quote...', {
        duration: 30000
      })

      const publishResult = await saveQuoteEditor.publishQuote(
        props.quoteId,
        data
      )

      if (publishResult.success) {
        notifications.displayNotification('Quote published', {
          type: 'success',
          confetti: true
        })
        return
      }

      notifications.displayNotification('Failed to publish quote', {
        type: 'error'
      })
      return
    },
    [existingData, notifications, props.quoteId, saveQuoteEditor]
  )

  const acceptQuote = useCallback(async () => {
    if (!existingData || !props.quoteId) {
      throw new Error("Can't save without initialising first")
    }

    notifications.displayNotification('Accepting quote...', {
      duration: 30000
    })

    const acceptResult = await saveQuoteEditor.acceptQuote(props.quoteId)

    if (acceptResult.success) {
      notifications.displayNotification('Quote accepted', {
        type: 'success'
      })
      return
    }

    notifications.displayNotification('Failed to accept quote', {
      type: 'error'
    })
    return
  }, [existingData, notifications, props.quoteId, saveQuoteEditor])

  const archiveQuote = useCallback(async () => {
    if (!existingData || !props.quoteId) {
      throw new Error("Can't save without initialising first")
    }

    notifications.displayNotification('Archiving quote...', {
      duration: 30000
    })

    const archiveResult = await saveQuoteEditor.archiveQuote(props.quoteId)

    if (archiveResult.success) {
      notifications.displayNotification('Quote archived', {
        type: 'success'
      })
      return
    }

    notifications.displayNotification('Failed to archive quote', {
      type: 'error'
    })
    return
  }, [existingData, notifications, props.quoteId, saveQuoteEditor])

  const deleteLatestQuoteDraft = useCallback(async () => {
    if (!existingData || !props.quoteId) {
      throw new Error("Can't save without initialising first")
    }

    notifications.displayNotification('Deleting changes...', {
      duration: 30000
    })

    const archiveResult = await saveQuoteEditor.deleteLatestQuoteDraft(
      props.quoteId
    )

    if (archiveResult.success) {
      notifications.displayNotification('Changes deleted', {
        type: 'success'
      })
      return
    }

    notifications.displayNotification('Failed to delete changes', {
      type: 'error'
    })
    return
  }, [existingData, notifications, props.quoteId, saveQuoteEditor])

  const executeQuote = useCallback(async () => {
    if (!existingData || !props.quoteId) {
      throw new Error("Can't save without initialising first")
    }

    notifications.displayNotification(
      'Converting quote to billing schedule...',
      {
        duration: 30000
      }
    )

    const executeResult = await saveQuoteEditor.executeQuote(props.quoteId)

    if (executeResult.success) {
      notifications.displayNotification('Draft billing schedule created', {
        type: 'success',
        confetti: true
      })
      return
    }

    notifications.displayNotification('Failed to convert quote', {
      type: 'error'
    })
    return
  }, [existingData, notifications, props.quoteId, saveQuoteEditor])

  const duplicateQuote = useCallback(async () => {
    if (!existingData || !props.quoteId) {
      throw new Error("Can't duplicate without initialising first")
    }

    notifications.displayNotification('Duplicating quote...', {
      duration: 30000
    })

    const duplicateResult = await saveQuoteEditor.duplicateQuote(props.quoteId)

    if (duplicateResult.success && duplicateResult.quote) {
      notifications.displayNotification('Quote duplicated', {
        type: 'success'
      })

      return { newQuoteId: duplicateResult.quote.id }
    }

    notifications.displayNotification('Failed to duplicate quote', {
      type: 'error'
    })

    return null
  }, [existingData, notifications, props.quoteId, saveQuoteEditor])

  const createContact = useCallback(
    async ({
      customerId,
      body
    }: {
      customerId: string
      body: ContactPostBody
    }) => {
      notifications.displayNotification('Creating new recipient', {
        type: 'neutral'
      })

      const newContact = await createContactHook({ customerId, body })

      if (!newContact) {
        notifications.displayNotification('Unable to create new recipient', {
          type: 'error'
        })

        return null
      }

      notifications.displayNotification('New recipient created', {
        type: 'success'
      })

      return {
        ...newContact,
        customerId
      }
    },
    [createContactHook, notifications]
  )

  const portImplementation: CubePortImplementation = useCallback(
    (ctx): QuoteCubePorts => ({
      in: {
        core: loadCore(ctx),
        customer: () => Promise.resolve(null),
        contacts: {
          reloadContacts
        }
      },
      out: {
        save: saveQuoteDraft,
        archive: archiveQuote,
        quote: {
          publish: publishQuote,
          accept: acceptQuote,
          execute: executeQuote,
          deleteLatestDraft: deleteLatestQuoteDraft,
          duplicate: duplicateQuote
        },
        contacts: {
          createContact
        }
      }
    }),
    [
      loadCore,
      saveQuoteDraft,
      publishQuote,
      acceptQuote,
      executeQuote,
      archiveQuote,
      deleteLatestQuoteDraft,
      reloadContacts,
      createContact,
      duplicateQuote
    ]
  )

  return portImplementation
}
