import { Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { Lato13Bold, Lato13Regular } from '@sequencehq/design-tokens'
import { FC } from 'react'
import { ValidationItem } from './useSteppedModal'

export const ValidationSummary: FC<{
  validation: Record<string, ValidationItem>
}> = ({ validation }) => {
  return (
    <Flex gap="8px" direction="column">
      <Text>
        You’re missing some information we need to generate ready-to-send
        invoices. This information can also be added later if needed.
      </Text>

      <Text {...Lato13Bold}>Missing information:</Text>
      <UnorderedList>
        {Object.entries(validation).map(([validationKey, validationItem]) => (
          <ListItem key={validationKey}>
            <Text {...Lato13Regular}>{validationItem.message}</Text>
          </ListItem>
        ))}
      </UnorderedList>
    </Flex>
  )
}
