import {
  ModalContextProvider,
  SimpleDialogUI
} from '@sequencehq/core-components'
import { FC } from 'react'
import { useSteppedConfirmStartModal } from './useSteppedConfirmStartModal'
import Spinner from 'components/Loading/Spinner'
import { SteppedModal } from 'common/components/SteppedModal/SteppedModal'

type SteppedConfirmStartModalProps = {
  onClose: () => void
  onConfirm: () => Promise<void>
  submitting: boolean
}

export const SteppedConfirmStartModal: FC<
  SteppedConfirmStartModalProps
> = props => {
  const { loading, validation } = useSteppedConfirmStartModal({
    onClose: props.onClose
  })

  if (loading || validation === null) {
    return (
      <ModalContextProvider isOpen>
        <SimpleDialogUI title="Start schedule">
          <Spinner />
        </SimpleDialogUI>
      </ModalContextProvider>
    )
  }

  return (
    <SteppedModal
      onClose={props.onClose}
      onFinalConfirm={props.onConfirm}
      submitting={props.submitting}
      validation={validation}
    />
  )
}
