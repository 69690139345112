import { CubeReducerState } from 'modules/Cube/domain/cube.domain.types'
import { getAutoIssueInvoicesAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/schedule/getAutoIssueInvoicesAvailable'
import { getLabelAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/schedule/getLabelAvailable'
import { getPublishAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/schedule/getPublishAvailable'
import { getPurchaseOrderNumberAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/schedule/getPurchaseOrderNumberAvailable'
import { getRecurrenceDateAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/schedule/getRecurrenceDateAvailable'
import { getReferenceAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/schedule/getReferenceAvailable'
import { getRollUpBillingAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/schedule/getRollUpBillingAvailable'
import { getSaveAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/schedule/getSaveAvailable'
import { getStripePaymentAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/schedule/getStripePaymentAvailable'
import { getTaxRateAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/schedule/getTaxRateAvailable'

export const getScheduleFeaturesAvailable = (
  prevState: CubeReducerState
): CubeReducerState['queries']['availableFeatures']['schedule'] => ({
  stripePayment: getStripePaymentAvailable(prevState),
  rollUpBilling: getRollUpBillingAvailable(prevState),
  publish: getPublishAvailable(prevState),
  save: getSaveAvailable(prevState),
  recurrenceDayOfMonth: getRecurrenceDateAvailable(prevState),
  taxRate: getTaxRateAvailable(prevState),
  label: getLabelAvailable(prevState),
  reference: getReferenceAvailable(prevState),
  purchaseOrderNumber: getPurchaseOrderNumberAvailable(prevState),
  autoIssueInvoices: getAutoIssueInvoicesAvailable(prevState)
})
