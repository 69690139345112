import { SimpleDialog, TextInputField } from '@sequencehq/core-components'
import { Box, Text } from '@chakra-ui/react'
import { GreyGrey80, Lato13Regular } from '@sequencehq/design-tokens'
import { useState } from 'react'

interface Props {
  onConfirm: (testEmail: string) => void
  trigger: (openDialog: () => void) => React.ReactNode
}

export const SendTestCreditNoteDialog = ({ onConfirm, trigger }: Props) => {
  const [testEmail, setTestEmail] = useState('')

  return (
    <SimpleDialog
      title="Send test credit note email"
      confirmButtonText="Send test credit note"
      onConfirm={() => {
        onConfirm(testEmail)
      }}
      confirmDisabled={testEmail.length === 0}
      closeOnCancelOrSubmit
      trigger={trigger}
    >
      <Text {...Lato13Regular} color={GreyGrey80}>
        Send a test email with this credit note attached.
      </Text>
      <Box mt={4} mb={-5}>
        <TextInputField
          data-testid="testCreditNoteDialog.email"
          value={testEmail}
          validationErrors={undefined}
          onChange={event => setTestEmail(event.target.value)}
          placeholder="test@sequencehq.com"
        />
      </Box>
    </SimpleDialog>
  )
}
