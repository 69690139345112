import CreateCreditNoteFromInvoiceForm from 'components/CreateCreditNoteFromInvoice/CreateCreditNoteFromInvoiceForm'
import {
  CreateCreditNoteFromInvoiceFormMutation,
  CreateCreditNoteFromInvoiceFormProps
} from 'components/CreateCreditNoteFromInvoice/types'
import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { usePostCreditNotesMutation } from 'features/api'

const CreateCreditNoteModal = createFormModal<
  CreateCreditNoteFromInvoiceFormMutation,
  CreateCreditNoteFromInvoiceFormProps
>({
  FormComponent: CreateCreditNoteFromInvoiceForm,
  useMutation: usePostCreditNotesMutation,
  modalTitle: 'Create credit note',
  successMessage: 'Credit note created',
  navigateTo: response => `/credit-notes/${response.id}`,
  submitTitle: 'Create',
  formId: 'createCreditNoteForm',
  successToast: () => (
    <Toast
      type="success"
      title={`Credit note created`}
      isClosable={true}
    ></Toast>
  )
})

export default CreateCreditNoteModal
