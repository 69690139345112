import { FC } from 'react'

interface TriangleUpIconProps {
  width?: number
  height?: number
  color?: string
}

export const TriangleUpIcon: FC<TriangleUpIconProps> = ({
  width = 10,
  height = 8,
  color
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.17801 1.18645C4.57569 0.61244 5.42431 0.612441 5.82199 1.18645L9.10877 5.93051C9.56825 6.59371 9.0936 7.5 8.28678 7.5H1.71322C0.906401 7.5 0.431746 6.59371 0.891226 5.93051L4.17801 1.18645Z"
      fill={color}
    />
  </svg>
)
