import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react'
import { InfoBox, InfoRow } from '@sequencehq/core-components'
import {
  AggregationType,
  CustomParameterModel,
  toAggregationType
} from '@sequencehq/core-models'
import { Lato16Bold } from '@sequencehq/design-tokens'
import { formatDateRange } from '@sequencehq/formatters'
import { FormErrors, TextInputField } from '@sequencehq/forms'
import { format } from '@sequencehq/utils/dates'
import {
  composeValidators,
  required,
  validateParameter
} from '@sequencehq/validation'
import { CustomerComboInputComplete } from 'components/FormComponents/CustomerComboInputComplete'
import { DateRangeInput } from 'components/FormInputs/Dates/DateRangeInput'
import { useDateRangeEndInput } from 'components/FormInputs/Dates/useDateRangeEndInput'
import { useDateRangeStartInput } from 'components/FormInputs/Dates/useDateRangeStartInput'
import {
  useLazyGetCustomersByIdAliasesQuery,
  useLazyGetUsageMetricsByUsageMetricIdCalculateQuery
} from 'features/api'
import { handleFormResponse } from 'lib/formValidation'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

type UsageMetricPreviewProps = {
  usageMetricId: string
  aggregationType: AggregationType
  parameters: CustomParameterModel[]
}

type FormValues = {
  customerId: string
  startDate: Date
  endDate: Date
  customParameters: Record<string, string>
}

export const UsageMetricPreview: FC<UsageMetricPreviewProps> = ({
  usageMetricId,
  aggregationType,
  parameters
}) => {
  const [calcTrigger, calcResult] =
    useLazyGetUsageMetricsByUsageMetricIdCalculateQuery()

  const [aliasTrigger] = useLazyGetCustomersByIdAliasesQuery()

  const calculation = calcResult.data?.value()

  const parameterDefaultValues: Record<string, string> = {}
  parameters.forEach(parameter => {
    parameterDefaultValues[parameter.id] = parameter.defaultValue
  })

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      destroyOnUnregister
      initialValues={{ customParameters: parameterDefaultValues }}
      onSubmit={async (values, form) => {
        const aliasRes = await aliasTrigger({
          id: values.customerId
        })

        const aliases = aliasRes?.data?.value()?.items
        const aliasesParam = aliases?.map(({ value }) => value).join(',') ?? ''
        const customerAliases =
          aliasesParam.length > 0
            ? `${values.customerId},${aliasesParam}`
            : values.customerId

        const calcRes = await calcTrigger({
          usageMetricId,
          customerAliases,
          periodStart: format(values.startDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
          periodEnd: format(values.endDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
          customParameters: JSON.stringify(values.customParameters)
        })
        return handleFormResponse(calcRes, form.getRegisteredFields())
      }}
      render={({ handleSubmit, submitError, values }) => (
        <>
          <Text {...Lato16Bold}>Preview</Text>
          <Box height={4} />
          {calculation ? (
            <InfoBox>
              <Flex flexDirection="column">
                {aggregationType !== 'CUSTOM' && (
                  <InfoRow label="Event count">
                    {calculation.eventCount}
                  </InfoRow>
                )}
                <Box height={2} />

                <InfoRow
                  label={`${toAggregationType(calculation.aggregationType)}:`}
                >
                  {calculation.value}
                </InfoRow>

                <Box height={2} />
                <InfoRow
                  label={formatDateRange(values.startDate, values.endDate)}
                />
              </Flex>
            </InfoBox>
          ) : null}
          <Box height={4} />
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
          >
            <FormErrors formError={submitError} />
            <CustomerComboInputComplete
              fieldName="customerId"
              validate={required}
            />
            <Box height={4} />
            <HStack spacing={4} alignItems="start">
              <DateRangeInput useDateRangeInput={useDateRangeStartInput} />

              <DateRangeInput
                useDateRangeInput={useDateRangeEndInput}
                className="end-date"
              />
            </HStack>
            <Box height={4} />
            {parameters.map(parameter => (
              <TextInputField
                key={parameter.id}
                fieldLabel={parameter.name}
                fieldName={`customParameters[${parameter.id}]`}
                validate={composeValidators(
                  required,
                  validateParameter(parameter.type)
                )}
                infoPopover={{
                  body: parameter.description
                }}
              />
            ))}
            <Box height={4} />
            <Flex justifyContent="flex-end">
              <Button type="submit" variant="primary">
                Calculate
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    />
  )
}
