import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import {
  CreditGrantModel,
  CustomerModel,
  toCurrencySymbol
} from '@sequencehq/core-models'
import { TextInputField } from '@sequencehq/forms'
import {
  composeValidators,
  dateMustBeInFuture,
  decimal,
  required
} from '@sequencehq/validation'
import { TaxRateField } from 'components/CreateCreditGrant/TaxRateField'
import { UsageMetricInput } from 'components/CreateUsageMetric'
import { CurrencyInputWrapper } from 'components/CurrencyInputWrapper/CurrencyInputWrapper'
import { useDefaultCurrency } from 'components/CurrencySettings/useCurrencies'
import { Blocker, isErrorResponse } from 'components/Form'
import { IntegerInput } from 'components/FormComponents'
import { DecimalInput } from 'components/FormComponents/DecimalInput'
import { DateInputField, TabSelectField } from 'components/FormFields'
import Spinner from 'components/Loading'
import { DrawerForm } from 'components/Modal'
import { Toast } from '@sequencehq/core-components'
import { CalendarIcon } from 'components/icons/CalendarIcon'
import {
  useGetCustomersByIdQuery,
  useGetTaxRatesQuery,
  usePostCreditsMutation
} from 'features/api'
import { Decorator } from 'final-form'
import createDecorator from 'final-form-calculate'
import { handleFormResponse } from 'lib/formValidation'
import { FC, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

const defaultValues: any = {
  amount: '',
  name: '',
  costOfCredit: '0',
  creditUnitType: 'METRIC',
  currency: 'GBP',
  pricePerUnit: ''
}

const createRequestFromValues = (
  formValues: CreditGrantModel,
  customer: CustomerModel
) => {
  const { id: customerId, sequenceAccountId } = customer
  const body = { ...formValues, customerId, sequenceAccountId }

  if (formValues.creditUnitType === 'CURRENCY') {
    body.amount = body.costOfCredit
  }

  return body
}

export const CreateCreditGrantEditor: FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { customerId } = useParams<{ customerId: string }>()
  const defaultCurrency = useDefaultCurrency()

  invariant(customerId, 'customerId is required')

  const { data: customerResponse, isFetching: isFetchingCustomer } =
    useGetCustomersByIdQuery({
      id: customerId
    })
  const customer = customerResponse?.value()

  const { data: taxRatesData, isFetching: isFetchingTaxRates } =
    useGetTaxRatesQuery({
      limit: 100
    })
  const taxRates = taxRatesData?.value()?.items ?? []
  const [createCreditGrant] = usePostCreditsMutation()

  const costDecorator = useMemo(
    () =>
      createDecorator({
        field: 'costOfCredit',
        updates: {
          pricePerUnit: (costOfCredit, allValues) => {
            const { amount } = allValues as CreditGrantModel

            if (!costOfCredit || !amount) {
              return ''
            }

            const numericCost = Number.parseFloat(costOfCredit as string)
            return (numericCost / amount).toString()
          }
        }
      }) as unknown as Decorator<CreditGrantModel>,
    []
  )

  const amountDecorator = useMemo(
    () =>
      createDecorator({
        field: 'amount',
        updates: {
          pricePerUnit: (amount, allValues) => {
            const { costOfCredit } = allValues as CreditGrantModel

            if (!costOfCredit || !amount) {
              return ''
            }

            const numericAmount = Number.parseFloat(amount as string)
            return (costOfCredit / numericAmount).toString()
          }
        }
      }) as unknown as Decorator<CreditGrantModel>,
    []
  )

  if (isFetchingCustomer || isFetchingTaxRates || !defaultCurrency) {
    return <Spinner />
  }

  return (
    <Form<CreditGrantModel>
      decorators={[costDecorator, amountDecorator]}
      onSubmit={async (values, form) => {
        if (customer === undefined) {
          navigate('/customers/')
          return
        }
        const creditGrantRes = await createCreditGrant({
          createCreditGrantEndpointCreateCreditGrantRequestModel:
            createRequestFromValues(values, customer)
        })

        if (isErrorResponse(creditGrantRes)) {
          return handleFormResponse(creditGrantRes, form.getRegisteredFields())
        }

        toast({
          position: 'bottom',
          isClosable: true,
          render: () => (
            <Toast
              type="success"
              title="Credit grant created"
              description="You have granted this customer credits. Review the draft invoice to issue the grant."
              url="/invoices"
              linkText="View invoice"
              isClosable={true}
            />
          )
        })

        navigate(`/customers/${customerId}`)
      }}
      initialValues={{
        ...defaultValues,
        currency: defaultCurrency
      }}
      render={formProps => (
        <DrawerForm
          {...formProps}
          handleCancel={() => navigate(`/customers/${customerId}`)}
          title="Add new credit grant"
          submitLabel="Save credit grant"
        >
          <Blocker
            dirty={formProps.dirty}
            submitting={formProps.submitting}
            name="credit grant"
            ignoreBlock={({ pathname }) => {
              const path = `/customers/${customerId}/credit-grant/metrics/new`
              return pathname.startsWith(path)
            }}
          />
          <TextInputField
            fieldName="name"
            fieldLabel="Name"
            placeholder="Enter name for the credit grant"
            validate={required}
            infoPopover={{
              title: 'Name of credit grant',
              body: 'The name, balance and expiry date of the credit grant will be displayed on each invoice when credits are used.'
            }}
          />
          <Box height={4} />
          <TabSelectField
            fieldName="creditUnitType"
            fieldLabel="Credit type"
            validate={required}
            options={[
              { label: 'Units', value: 'METRIC' },
              { label: 'Cash', value: 'CURRENCY' }
            ]}
          />
          <Box height={4} />
          {formProps.values.creditUnitType === 'METRIC' && (
            <>
              <UsageMetricInput
                fieldName="metricId"
                infoPopover={{
                  title: 'Usage metric',
                  body: 'Select the usage metric that refers to the usage event you want to credit.'
                }}
              />
              <Box height={4} />
              <IntegerInput
                fieldName="amount"
                fieldLabel="Number of units"
                placeholder="Enter number of units"
                validate={required}
              />
            </>
          )}
          <Box height={4} />
          <CurrencyInputWrapper />
          <Box height={4} />
          {formProps.values.creditUnitType === 'METRIC' && (
            <>
              <Flex justifyContent="space-between">
                <DecimalInput
                  fieldName="costOfCredit"
                  fieldLabel="Total price"
                  decimalPlaces="4"
                  placeholder="Enter value"
                  validate={required}
                  leftAddon={
                    formProps.values.currency
                      ? toCurrencySymbol(formProps.values.currency)
                      : '-'
                  }
                />
                <Box w={4} />
                <DecimalInput
                  fieldName="pricePerUnit"
                  fieldLabel="Price per unit"
                  decimalPlaces="10"
                  isDisabled={true}
                  leftAddon={`${
                    formProps.values.currency
                      ? toCurrencySymbol(formProps.values.currency)
                      : '-'
                  } / unit`}
                />
              </Flex>
            </>
          )}
          {formProps.values.creditUnitType === 'CURRENCY' && (
            <DecimalInput
              fieldName="costOfCredit"
              fieldLabel="Amount of credit grant"
              placeholder="Enter amount"
              validate={composeValidators(required, decimal('4'))}
              decimalPlaces="4"
              leftAddon={
                formProps.values.currency
                  ? toCurrencySymbol(formProps.values.currency)
                  : 'Value'
              }
            />
          )}
          <Box height={4} />
          <TaxRateField
            customerCountry={customer?.address.country}
            customerTaxStatus={customer?.taxStatus ?? 'TAX_EXEMPT'}
            isLoading={isFetchingTaxRates && !taxRates}
            taxRates={taxRates}
          />
          <Box height={4} />
          <DateInputField
            fieldName="expiryDate"
            fieldLabel="Expiry date (optional)"
            placeholder="Select date"
            minDate={new Date()}
            validate={dateMustBeInFuture}
            leftAddon={false}
            rightAddon={<CalendarIcon />}
            isSelectable={() => true}
          />

          <Box h={6} />
          <Flex align="end" flex={1}>
            <Text>
              Saving this credit grant will create a draft invoice for the
              charge
            </Text>
          </Flex>
          <Box h={6} />
        </DrawerForm>
      )}
    />
  )
}
