import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'

export type v1ApiNewBillingSchedule =
  Dashboardv20240509Api.PostBillingSchedule.PostBillingScheduleBody
export type v1ApiUpdateBillingSchedule =
  Dashboardv20240509Api.PutBillingSchedule.PutBillingScheduleBody
type BillingSchedule =
  Dashboardv20240509Api.PostBillingSchedule.Response['data']

type UseSaveBillingSchedule = () => {
  createBillingSchedule: (
    billingSchedule: v1ApiNewBillingSchedule
  ) => Promise<BillingSchedule>
  updateBillingSchedule: (args: {
    id: string
    data: v1ApiUpdateBillingSchedule
  }) => Promise<BillingSchedule>
}

export const useSaveBillingSchedule: UseSaveBillingSchedule = () => {
  const createBillingSchedule = async (data: v1ApiNewBillingSchedule) => {
    const response = await dashboardv20240509Client.postBillingSchedule({
      body: {
        ...data,
        isDraft: true
      }
    })

    return response.data
  }

  const updateBillingSchedule = async ({
    id,
    data
  }: {
    id: string
    data: v1ApiUpdateBillingSchedule
  }) => {
    const response = await dashboardv20240509Client.putBillingSchedule({
      id,
      body: data
    })

    return response.data
  }

  return {
    createBillingSchedule,
    updateBillingSchedule
  }
}
