import { Flex, Grid, Link, List, ListItem, Text } from '@chakra-ui/react'
import ArrowRightIcon from '@heroicons/react/20/solid/ArrowRightIcon'
import { Badge } from '@sequencehq/core-components'
import { SeatSnapshotModel } from '@sequencehq/core-models'
import {
  IndigoIndigo50,
  Lato14Bold,
  Lato14Regular
} from '@sequencehq/design-tokens'
import { SeatChangeBadge } from 'components/SeatSnapshots/common/SeatChangeBadge'
import { getSeatAddedRemoved } from 'components/SeatSnapshots/helpers'
import { useGetCustomersByAliasByAliasQuery } from 'features/api'
import { PropsWithChildren } from 'react'

const SnapshotInformation = ({ snapshot }: { snapshot: SeatSnapshotModel }) => {
  const { id, snapshotTimestamp, seatType, customerAlias, seatChanges, total } =
    snapshot

  const { data: customer } = useGetCustomersByAliasByAliasQuery({
    alias: customerAlias
  })

  const { added, removed } = getSeatAddedRemoved(seatChanges)

  return (
    <>
      <List spacing={3.5}>
        <LineItem>
          <Text {...Lato14Regular}>Event ID</Text>
          <Text {...Lato14Regular}>{id}</Text>
        </LineItem>
        <LineItem>
          <Text {...Lato14Regular}>Timestamp</Text>
          <Text {...Lato14Regular}>{snapshotTimestamp}</Text>
        </LineItem>
        <LineItem>
          <Text {...Lato14Regular}>Seat type</Text>
          <Badge sentiment="neutral" size="sm">
            {seatType}
          </Badge>
        </LineItem>
        {customer && (
          <LineItem>
            <Text {...Lato14Regular}>Customer alias</Text>
            <Text {...Lato14Regular}>{customer.value()?.legalName}</Text>
          </LineItem>
        )}
        <LineItem>
          <Text {...Lato14Regular}>Seat changes</Text>
          <Flex gap={2} my={-1}>
            <SeatChangeBadge type="added" count={added} />
            <SeatChangeBadge type="removed" count={removed} />
          </Flex>
        </LineItem>
        <LineItem>
          <Text {...Lato14Regular}>Total (new balance)</Text>
          <Text {...Lato14Regular}>{total}</Text>
        </LineItem>
      </List>

      {customer && (
        <Grid gap={2} mt={4}>
          <Text
            as={Link}
            {...Lato14Bold}
            href={`/billing-schedules/?customerId=${customerAlias}`}
            color={IndigoIndigo50}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <ArrowRightIcon height="16px" width="16px" className="inline" />
            View billing schedules for {customer.value()?.legalName}
          </Text>
          <Text
            as={Link}
            {...Lato14Bold}
            href={`/customers/${customerAlias}`}
            color={IndigoIndigo50}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <ArrowRightIcon height="16px" width="16px" className="inline" />
            View customer profile
          </Text>
        </Grid>
      )}
    </>
  )
}

const LineItem = ({ children }: PropsWithChildren) => {
  return (
    <ListItem
      minH={5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {children}
    </ListItem>
  )
}

export default SnapshotInformation
