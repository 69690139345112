import {
  Box,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  Text
} from '@chakra-ui/react'
import Spinner from 'components/Loading/Spinner'
import invariant from 'tiny-invariant'
import { Lato16Bold, GreyGrey30 } from '@sequencehq/design-tokens'
import { useGetSeatSnapshotsQuery } from 'features/api'
import { useMemo } from 'react'
import SnapshotInformation from 'components/SeatSnapshots/Inspector/SnapshotInformation'
import SnapshotData from 'components/SeatSnapshots/Inspector/SnapshotData'

const SeatSnapshotInspector = ({ snapshotId }: { snapshotId: string }) => {
  invariant(snapshotId, 'snapshotId is required')

  const { isFetching, data, isError } = useGetSeatSnapshotsQuery({
    limit: 50
  })

  const snapshot = useMemo(
    () => data?.value()?.items?.find(s => s.id === snapshotId),
    [data, snapshotId]
  )

  if (isFetching || !snapshot) {
    return (
      <DrawerContent maxW={490}>
        <DrawerCloseButton top={4} right={4} />
        <DrawerBody>
          <Spinner />
        </DrawerBody>
      </DrawerContent>
    )
  }

  if (isError) {
    return (
      <DrawerContent maxW={490}>
        <DrawerCloseButton top={4} right={4} />
        <DrawerBody>Something went wrong. Please try again later.</DrawerBody>
      </DrawerContent>
    )
  }

  return (
    <DrawerContent maxW={490}>
      <DrawerBody padding={0} marginBottom={6}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={6}
          height="64px"
          borderBottom={`1px solid ${GreyGrey30}`}
        >
          <Text {...Lato16Bold}>Seat event</Text>
          <DrawerCloseButton
            position="static"
            translateX={2}
            transform="auto"
          />
        </Flex>

        <Box p={6}>
          <Text {...Lato16Bold} mb={4}>
            Information
          </Text>
          <SnapshotInformation snapshot={snapshot} />
        </Box>

        <Box px={6} maxH="40vh">
          <Text {...Lato16Bold} mb={4}>
            Data
          </Text>
          <SnapshotData snapshot={snapshot} />
        </Box>
      </DrawerBody>
    </DrawerContent>
  )
}

export default SeatSnapshotInspector
