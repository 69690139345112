import { Grid, GridItem } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey70,
  GreyGrey90,
  Lato13Regular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { Skeleton } from '@sequencehq/core-components'
import { formatTotal } from 'RevenueRecognition/view/utils/formatTotal'

import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { Currency, useQuery } from '@sequencehq/api/utils'
import invariant from 'tiny-invariant'

type UseDeferredRevenueSummary = () =>
  | {
      summary: {
        balances: Array<{
          currency: Currency
          amount: number
        }>
      }
      isLoading: false
    }
  | {
      summary: null
      isLoading: true
    }

export const useDeferredRevenueSummary: UseDeferredRevenueSummary = () => {
  const schedulesQuery = useQuery(
    dashboardv99990101Client.getDeferredRevenueSchedules,
    undefined,
    {
      select: res => {
        if (!res) {
          return null
        }

        return {
          balances: res.balances.map(balance => ({
            currency: balance.currency,
            amount: parseFloat(balance.amount)
          }))
        }
      }
    }
  )

  /**
   * We throw this error to be caught by the appropriate error boundary.
   */
  if (schedulesQuery.error) {
    throw new Error('Deferred revenue summary could not be loaded')
  }

  if (schedulesQuery.isPending) {
    return {
      isLoading: true,
      summary: null
    }
  }

  invariant(schedulesQuery.data, 'Data should be defined, if loaded')

  return {
    isLoading: false,
    summary: schedulesQuery.data
  }
}

export const DeferredRevenueSummary = () => {
  const { isLoading, summary } = useDeferredRevenueSummary()

  if (isLoading) {
    return <Skeleton width="100%" height="96px" />
  }

  return (
    <Grid
      data-testid="revrec.defrev.summary"
      gridTemplateRows="auto auto"
      justifyItems="center"
      padding="24px 16px"
      backgroundColor={GreyGrey10}
      borderRadius="8px"
      rowGap="8px"
    >
      <GridItem {...Lato16Bold} color={GreyGrey90}>
        Deferred Revenue
      </GridItem>
      <GridItem {...Lato13Regular} color={GreyGrey70}>
        Remaining balance{summary.balances.length > 1 ? 's' : ''}:{' '}
        {summary.balances.map((balance, idx) => (
          <>
            <span style={{ fontWeight: 700 }}>
              {formatTotal({
                currency: balance.currency,
                value: balance.amount
              })}
            </span>
            {idx < summary.balances.length - 1 && (
              <>
                <span style={{ margin: '0 3px' }}>·</span>
              </>
            )}
          </>
        ))}
      </GridItem>
    </Grid>
  )
}
