import { useCallback, useEffect, useMemo, useState } from 'react'
import { dashboard20240730Client } from '@sequencehq/api/dashboard/v20240730'
import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { useNotifications } from 'lib/hooks/useNotifications'
import { dequal } from 'dequal'
import { useFlags } from 'launchdarkly-react-client-sdk'
import * as Sentry from '@sentry/react'
import {
  AccountingDatePreference,
  InvoiceSettings
} from 'modules/Settings/domain/settings.types'
import {
  ACCOUNTING_DATE_PREFERENCE_OPTIONS,
  INITIAL_DATA
} from 'modules/Settings/domain/settings.constants'

enum LoadingState {
  UNINITIALIZED = 'UNINITIALIZED',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

type UseInvoicesSettings = () => {
  ready: boolean
  submitting: boolean
  hasChanges: boolean
  onSave: () => void
  fields: {
    accountingDatePreference: {
      value: string
      options: { value: string; label: string }[]
      onChange: (preference: string) => void
    }
    paymentLinkDisplay: {
      hidden: boolean
      value: boolean
      onChange: (showPaymentLink: boolean) => void
    }
    accountPaymentSettings: {
      hidden: boolean
      value: boolean
      onChange: (showFailureNotifications: boolean) => void
    }
  }
}

export const useInvoicesSettings: UseInvoicesSettings = () => {
  const flags = useFlags()
  const notifications = useNotifications()

  const [hasStripeIntegration, setHasStripeIntegration] =
    useState<boolean>(false)
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.UNINITIALIZED
  )
  const [submitting, setSubmitting] = useState<boolean>(false)

  const [initialData, setInitialData] = useState<InvoiceSettings>(INITIAL_DATA)
  const [data, setData] = useState<InvoiceSettings>(INITIAL_DATA)

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoadingState(LoadingState.LOADING)
        const [
          accountDatePreferenceResponse,
          invoicesRenderSettingsResponse,
          accountPaymentSettingsResponse,
          stripeIntegrationResponse
        ] = await Promise.all([
          dashboardv20240509Client.getInvoiceAccountingDateSettings(),
          dashboard20240730Client.getInvoicesRenderSettings(),
          dashboard20240730Client.getAccountPaymentSettings(),
          dashboard20240730Client.getIntegration('Stripe')
        ])

        const hasError = [
          accountDatePreferenceResponse.error,
          invoicesRenderSettingsResponse.error,
          accountPaymentSettingsResponse.error,
          stripeIntegrationResponse.error
        ].some(Boolean)

        if (
          hasError ||
          accountDatePreferenceResponse.data === null ||
          invoicesRenderSettingsResponse.data === null ||
          accountPaymentSettingsResponse.data === null ||
          stripeIntegrationResponse.data === null
        ) {
          throw new Error('Unable to fetch invoice settings')
        }

        setHasStripeIntegration(stripeIntegrationResponse.data.enabled)

        const settingsData = {
          accountingDatePreference: {
            id: accountDatePreferenceResponse.data.id,
            preference: accountDatePreferenceResponse.data.preference
          },
          renderSettings: {
            id: invoicesRenderSettingsResponse.data.id,
            paymentLinkDisplay:
              invoicesRenderSettingsResponse.data.paymentLinkDisplay
          },
          accountPaymentSettings: {
            id: accountPaymentSettingsResponse.data.id,
            failureNotifications: {
              merchantNotifications:
                accountPaymentSettingsResponse.data.failureNotifications
                  .merchantNotifications,
              customerNotifications:
                accountPaymentSettingsResponse.data.failureNotifications
                  .customerNotifications
            }
          }
        }

        setInitialData(settingsData)
        setData(settingsData)
        setLoadingState(LoadingState.READY)
      } catch (e) {
        notifications.displayNotification('Unable to fetch invoice settings', {
          type: 'error'
        })

        setLoadingState(LoadingState.ERROR)
        Sentry.captureException(e)
      }
    }

    void loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeAccountingDatePreference = useCallback((preference: string) => {
    setData(prev => ({
      ...prev,
      accountingDatePreference: {
        ...prev.accountingDatePreference,
        preference: preference as AccountingDatePreference['preference']
      }
    }))
  }, [])

  const onChangePaymentLinkDisplay = useCallback((showPaymentLink: boolean) => {
    const newPaymentLinkDisplay = showPaymentLink
      ? 'SHOW_PAYMENT_LINK'
      : 'HIDE_PAYMENT_LINK'
    setData(prev => ({
      ...prev,
      renderSettings: {
        ...prev.renderSettings,
        paymentLinkDisplay: newPaymentLinkDisplay
      }
    }))
  }, [])

  const onChangeAccountPaymentSettings = useCallback(
    (showFailureNotifications: boolean) => {
      const newFailureNotifications = showFailureNotifications
        ? 'SEND_NOTIFICATIONS'
        : 'DO_NOT_SEND_NOTIFICATIONS'
      setData(prev => ({
        ...prev,
        accountPaymentSettings: {
          ...prev.accountPaymentSettings,
          failureNotifications: {
            merchantNotifications: newFailureNotifications,
            customerNotifications: newFailureNotifications
          }
        }
      }))
    },
    []
  )

  const onSave = useCallback(() => {
    const save = async () => {
      notifications.displayNotification('Saving settings...', {
        type: 'info'
      })

      try {
        setSubmitting(true)

        if (
          !dequal(
            initialData.accountingDatePreference,
            data.accountingDatePreference
          )
        ) {
          const result =
            await dashboardv20240509Client.putInvoiceAccountingDateSettings({
              id: data.accountingDatePreference.id,
              body: {
                preference: data.accountingDatePreference.preference
              }
            })

          if (result.error) {
            throw new Error('Unable to update accounting date preference')
          }
        }

        if (!dequal(initialData.renderSettings, data.renderSettings)) {
          const result =
            await dashboard20240730Client.putInvoicesRenderSettings({
              id: data.renderSettings.id,
              body: {
                paymentLinkDisplay: data.renderSettings.paymentLinkDisplay
              }
            })

          if (result.error) {
            throw new Error('Unable to update render settings')
          }
        }

        if (
          !dequal(
            initialData.accountPaymentSettings,
            data.accountPaymentSettings
          )
        ) {
          const result =
            await dashboard20240730Client.putAccountPaymentSettings({
              id: data.accountPaymentSettings.id,
              body: {
                failureNotifications:
                  data.accountPaymentSettings.failureNotifications
              }
            })

          if (result.error) {
            throw new Error('Unable to update failure notification settings')
          }
        }

        notifications.displayNotification('Settings updated', {
          type: 'success'
        })
      } catch (e) {
        notifications.displayNotification('Unable to update invoice settings', {
          type: 'error'
        })

        Sentry.captureException(e)
      }

      setSubmitting(false)
      setInitialData(data)
    }

    void save()
  }, [
    data,
    initialData.accountingDatePreference,
    initialData.renderSettings,
    initialData.accountPaymentSettings,
    notifications
  ])

  const fields = useMemo(() => {
    return {
      accountingDatePreference: {
        value: data.accountingDatePreference.preference,
        options: ACCOUNTING_DATE_PREFERENCE_OPTIONS,
        onChange: onChangeAccountingDatePreference
      },
      paymentLinkDisplay: {
        value: data.renderSettings.paymentLinkDisplay === 'SHOW_PAYMENT_LINK',
        // If there's no Stripe integration, both this setting and the `accountPaymentSettings` should be hidden
        hidden: !flags.useInvoicePaymentLinkSetting || !hasStripeIntegration,
        onChange: onChangePaymentLinkDisplay
      },
      accountPaymentSettings: {
        value:
          data.accountPaymentSettings.failureNotifications
            .merchantNotifications === 'SEND_NOTIFICATIONS',
        hidden:
          !flags.showFailedPaymentNotificationsSetting || !hasStripeIntegration,
        onChange: onChangeAccountPaymentSettings
      }
    }
  }, [
    data.accountingDatePreference.preference,
    data.renderSettings.paymentLinkDisplay,
    data.accountPaymentSettings.failureNotifications.merchantNotifications,
    hasStripeIntegration,
    flags.useInvoicePaymentLinkSetting,
    flags.showFailedPaymentNotificationsSetting,
    onChangeAccountingDatePreference,
    onChangePaymentLinkDisplay,
    onChangeAccountPaymentSettings
  ])

  const hasChanges = useMemo(() => {
    return loadingState === LoadingState.READY && !dequal(initialData, data)
  }, [data, initialData, loadingState])

  return {
    ready: loadingState === LoadingState.READY,
    submitting,
    hasChanges,
    onSave,
    fields
  }
}
