import { useCallback, useEffect, useState } from 'react'
import { Customer } from 'components/Customers/types'
import { useLoadAllItems } from '@sequencehq/api/src/utils/useLoadAllItems'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'

export const useFetchCustomers = () => {
  const [customers, setCustomers] = useState<
    Omit<Customer, 'contacts'>[] | null
  >(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const loadCustomers = useLoadAllItems(dashboardv99990101Client.getCustomers)

  const fetchCustomers = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const customersData = await loadCustomers()

      if (customersData) {
        setCustomers(customersData)
      }
    } catch (e) {
      setError(e as Error)
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    void fetchCustomers()
  }, [fetchCustomers])

  return { customers, loading, error, refetch: fetchCustomers }
}
