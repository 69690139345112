import { Box, Flex } from '@chakra-ui/react'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { MutationFormProps } from 'components/Form/types'
import {
  UpdateAndSendCreditNoteStatusFormProps,
  UpdateCreditNoteStatusFormMutation
} from 'components/UpdateInvoiceStatus/types'
import { getEnvironment, isSandboxEnv } from 'lib/environment/environment'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

type FormValues = {
  value: string
}

const SendCreditNoteForm: FC<
  MutationFormProps<UpdateCreditNoteStatusFormMutation> &
    UpdateAndSendCreditNoteStatusFormProps
> = ({ id, customerName, customerEmails, submitForm, formId }) => {
  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      onSubmit={async () => {
        const promises: Promise<any>[] = [
          submitForm({
            id
          })
        ]

        await Promise.all(promises)
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            {`Email this credit note to ${customerName} (${customerEmails.join(
              ', '
            )}). After sending you can no longer edit the credit note.`}
            {isSandboxEnv() && (
              <Box pt={5} color={GreyGrey60}>
                Note: No end-customer emails will be sent in {getEnvironment()}.
              </Box>
            )}
          </Flex>
        )
      }}
    />
  )
}

export default SendCreditNoteForm
