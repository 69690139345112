import { Flex } from '@chakra-ui/react'
import Page from 'components/Page'
import { SeatBalancesMagicTable } from 'components/SeatBalances/SeatBalancesMagicTable'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, memo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { SeatBalanceProvider } from 'components/SeatBalances/hooks/useSeatBalanceContext'
import { useSeatBalances } from 'components/SeatBalances/hooks/useSeatBalances'

const emptyContent = {
  title: 'No seat usage yet',
  description: 'Get started by submitting a seat event.',
  route: '/seat-balances/new?view=snapshots',
  buttonCopy: 'New seat event',
  linkCopy: 'Learn more',
  docLink: 'https://docs.sequencehq.com/seats/examples'
}

const SeatBalances: FC = memo(() => {
  const flags = useFlags()
  const navigate = useNavigate()
  const seatBalanceContext = useSeatBalances()

  useEffect(() => {
    if (!flags?.enableSeats) {
      navigate('/home')
    }
  }, [flags?.enableSeats, navigate])

  return (
    <>
      <SeatBalanceProvider value={seatBalanceContext}>
        <Flex flexDirection="column" flex={1}>
          <Flex position="sticky" top={0} height="100vh">
            <Page title="Balances" paddingBottom={0}>
              <SeatBalancesMagicTable emptyContent={emptyContent} />
            </Page>
          </Flex>
        </Flex>
      </SeatBalanceProvider>
    </>
  )
})

export default SeatBalances
