import { usePrevious } from 'lib/usePrevious'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

// Keeps track of a value that can be updated by the client.
// The `currentValue` passed into it is the latest value from the server.
// When updated by the client, the time of the update is recorded.
// When the `currentValue` changes, the client copy and timestamp is reset.
export function useClientCopy<T>(
  currentValue: T
): [T, (updatedValue: T) => void, null | number] {
  const [clientCopy, setClientCopy] = useLiveState(currentValue)
  const [clientUpdatedTime, setClientUpdatedTime] = useState<null | number>(
    null
  )
  const previousValue = usePrevious(currentValue)

  useEffect(() => {
    if (!isEqual(currentValue, previousValue)) {
      setClientUpdatedTime(null)
    }
  }, [currentValue, previousValue])

  const updateClientCopy = useCallback(
    (updatedValue: T) => {
      setClientCopy(updatedValue)
      setClientUpdatedTime(Date.now())
    },
    [setClientCopy]
  )

  return [clientCopy, updateClientCopy, clientUpdatedTime]
}

// Like `useState` but when the `currentValue` changes, the state is updated to match it.
const useLiveState = <T>(currentValue: T) => {
  const hook = useState<T>(currentValue)
  const [, setState] = hook
  const previousValue = usePrevious(currentValue)

  useEffect(() => {
    if (!isEqual(currentValue, previousValue)) {
      setState(currentValue)
    }
  }, [currentValue, previousValue, setState])

  return hook
}
