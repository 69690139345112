import { Flex } from '@chakra-ui/react'
import { FC } from 'react'
import Spinner from 'components/Loading'

const SpinnerContainer: FC = () => {
  return (
    <Flex width="100%" height="vh" justifyContent="center" alignItems="center">
      <Spinner />
    </Flex>
  )
}

export default SpinnerContainer
