import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Spinner
} from '@chakra-ui/react'
import headerBackground from './headerBackground.svg'
import { ResolvedIntegration } from 'modules/Integrations/domain'
import {
  GreyGrey30,
  GreyGrey70,
  GreyGrey90,
  GreyWhite,
  Lato14Regular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import {
  BookOpenIcon,
  ClockIcon,
  Cog6ToothIcon
} from '@heroicons/react/16/solid'
import { useIntegrationDetailHeader } from './useIntegrationDetailHeader'
import { Badge } from '@sequencehq/core-components'

type IntegrationDetailHeaderProps = {
  integration: ResolvedIntegration
}
export const IntegrationDetailHeader = (
  props: IntegrationDetailHeaderProps
) => {
  const hook = useIntegrationDetailHeader(props)

  return (
    <Grid
      width="100%"
      padding="20px"
      minHeight="132px"
      backgroundImage={headerBackground}
      gridTemplateColumns="48px 1fr auto"
      gridTemplateRows="1fr auto"
      gridTemplateAreas={`
        "logo description badges"
        "empty actions actions"
      `}
      gridColumnGap="16px"
      gridRowGap="12px"
      borderBottom={`1px solid ${GreyGrey30}`}
    >
      <GridItem gridArea="badges">
        {hook.comingSoon && (
          <Badge sentiment="neutral">
            <Flex gap="4px">
              <ClockIcon height="16px" width="16px" />
              Coming soon
            </Flex>
          </Badge>
        )}
      </GridItem>
      <GridItem
        gridArea="logo"
        display="flex"
        justifyContent="center"
        paddingTop="6px"
        alignItems="flex-start"
        height="48px"
      >
        <Image
          src={props.integration.logoUrl}
          width="100%"
          height="100%"
          maxHeight="48px"
        />
      </GridItem>
      <GridItem gridArea="description">
        <Box {...Lato16Bold} color={GreyGrey90}>
          {props.integration.title}
        </Box>
        <Box {...Lato14Regular} color={GreyGrey70}>
          {props.integration.description}
        </Box>
      </GridItem>
      <GridItem gridArea="actions" display="flex" gap="8px">
        <Button
          variant="component-library-secondary"
          onClick={hook.features.documentation.onClick}
          isDisabled={hook.features.documentation.disabled}
        >
          <Flex gap="8px">
            <BookOpenIcon height="16px" width="16px" />
            Documentation
          </Flex>
        </Button>
        {hook.features.connect.visible && (
          <Button
            variant="component-library-primary"
            isDisabled={hook.features.connect.disabled}
            onClick={hook.features.connect.onClick}
          >
            <Flex gap="8px" alignItems="center">
              {hook.features.connect.showSpinner && (
                <Spinner height="16px" width="16px" color={GreyWhite} />
              )}{' '}
              {hook.features.connect.label}
            </Flex>
          </Button>
        )}
        {hook.features.manage.visible && (
          <Button
            variant="component-library-secondary"
            onClick={hook.features.manage.onClick}
            onAuxClick={hook.features.manage.onAuxClick}
          >
            <Flex gap="8px">
              <Cog6ToothIcon height="16px" width="16px" />
              {hook.features.manage.label}
            </Flex>
          </Button>
        )}
      </GridItem>
    </Grid>
  )
}
