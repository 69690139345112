import { Box, Flex, Text } from '@chakra-ui/react'
import InformationCircleIcon from '@heroicons/react/16/solid/InformationCircleIcon'
import { InfoPopover, XYChart } from '@sequencehq/core-components'
import {
  GreyGrey40,
  GreyGrey50,
  GreyGrey60,
  GreyGrey90,
  Lato13Bold,
  Lato13Regular,
  Lato20Bold
} from '@sequencehq/design-tokens'
import { useInvoicedRevenueWidget } from 'components/InsightsV2/widgets/InvoicedRevenueWidget/useInvoicedRevenueWidget'
import { FilterSelectInput } from 'components/InsightsV2/common/FilterSelectInput/FilterSelectInput'
import { Currency, currencyToSymbol, toMoney } from '@sequencehq/core-models'
import { WidgetLoading } from 'components/InsightsV2/common/WidgetLoading/WidgetLoading'
import { ChartTooltip } from 'components/InsightsV2/common/ChartTooltip'
import { format } from '@sequencehq/utils/dates'

interface EventTypesWidgetProps {
  dateRange: {
    start: Date
    end: Date
  }
}

export const InvoicedRevenueWidget = ({ dateRange }: EventTypesWidgetProps) => {
  const { isFetching, data, total, tickValues, tooltipFormat, currencyField } =
    useInvoicedRevenueWidget(dateRange)

  if (isFetching || !data) {
    return <WidgetLoading />
  }

  return (
    <Box
      width="390px"
      height="291px"
      boxShadow="0 2px 4px 0 rgba(20, 23, 28, 0.08)"
      border={`1px solid ${GreyGrey40}`}
      borderRadius="lg"
      p={4}
    >
      <Flex gap={2}>
        <Flex flexGrow="1" flexDirection="column" gap={2}>
          <Text as={Flex} {...Lato13Bold} color={GreyGrey60} gap={1}>
            Invoiced Revenue
            <InfoTooltip />
          </Text>
          <Text {...Lato20Bold} color={GreyGrey90}>
            {tooltipFormat(total)}
          </Text>
          <Text {...Lato13Regular} color={GreyGrey60}>
            {format(dateRange.start, 'd MMM yyyy')} -{' '}
            {format(dateRange.end, 'd MMM yyyy')}
          </Text>
        </Flex>
        <Flex width="120px" alignSelf="baseline">
          <FilterSelectInput
            placeholder="Currency"
            value={currencyField.value}
            options={currencyField.options}
            onChange={currencyField.onChange}
          />
        </Flex>
      </Flex>
      <Box mt={4} height="170px" width="100%">
        <XYChart
          variant="bar"
          dateFrom={format(dateRange.start, 'yyyy-MM-dd')}
          dateTo={data[data.length - 1].date}
          data={data}
          unitTickValues={tickValues}
          unit={
            currencyToSymbol[
              currencyField.value as keyof typeof currencyToSymbol
            ]
          }
          renderTooltip={({ date, value }) => (
            <DataTooltip
              date={date}
              value={value}
              currency={currencyField.value as Currency}
            />
          )}
        />
      </Box>
    </Box>
  )
}

const DataTooltip = ({
  date,
  value,
  currency
}: {
  date: string
  value: number
  currency: Currency
}) => {
  return (
    <ChartTooltip>
      <Flex justifyContent="space-between">
        <Text {...Lato13Bold} color="gray.70">
          {format(new Date(date), 'MMMM yyyy')}
        </Text>
        <Text {...Lato13Bold} color="gray.70">
          {toMoney({
            value: value.toString(),
            currency
          })}
        </Text>
      </Flex>
      <>
        <Text {...Lato13Bold} color="gray.70">
          Total
        </Text>
        <Text {...Lato13Bold} color="gray.70">
          {toMoney({
            value: value.toString(),
            currency
          })}
        </Text>
      </>
    </ChartTooltip>
  )
}

const InfoTooltip = () => {
  return (
    <InfoPopover
      variant="info"
      minimal
      padding="0"
      placement="top"
      body={
        <Box width={300}>
          Invoiced revenue is the total gross amount over all your sent invoices
          for the currency selected
        </Box>
      }
      icon={<InformationCircleIcon height={16} width={16} color={GreyGrey50} />}
    />
  )
}
