import { Flex } from '@chakra-ui/react'
import ArrowTrendingUpIcon from '@heroicons/react/24/outline/ArrowTrendingUpIcon'
import { Badge } from '@sequencehq/core-components'
import { SeatSnapshotModel } from '@sequencehq/core-models'
import { RedRed20, RedRed50 } from '@sequencehq/design-tokens'
import {
  MagicTableCell,
  MagicTableCellEmpty,
  MagicTableEmptyStateProps,
  MagicTableV2,
  useMagicTableInfiniteQuery
} from '@sequencehq/tables'
import MagicTableAutoLoader from 'components/AutoLoader/MagicTableAutoLoader'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { SeatBalancesViewSelector } from 'components/SeatBalances/SeatBalancesViewSelector'
import { SeatSnapshotsView } from 'components/SeatBalances/types'
import { useGetCustomersByAliasByAliasQuery } from 'features/api'
import { ExtractQueryParams } from 'lib/types'
import { FC } from 'react'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'

type SeatSnapshotsMagicTableProps = {
  emptyState: (props: MagicTableEmptyStateProps) => React.ReactNode
}

export const SeatSnapshotsMagicTable: FC<SeatSnapshotsMagicTableProps> = ({
  emptyState
}) => {
  const { infiniteQuery, setQueryParams } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getSeatEvents
  )

  return (
    <CurrentUserId>
      {userId => (
        <MagicTableV2<SeatSnapshotsView>
          entityNamePlural="seat events"
          entityIcon={props => <ArrowTrendingUpIcon {...props} />}
          sequenceUserId={userId}
          infiniteQuery={infiniteQuery}
          onQueryParamsChanged={setQueryParams}
          rowPath={(row: SeatSnapshotsView['model']) =>
            `/seat-events?snapshotId=${row.id}`
          }
          columns={[
            {
              id: 'total',
              accessorKey: 'total',
              header: 'Total Seats',
              cell: value => {
                const total = value.getValue<SeatSnapshotModel['total']>()
                return <MagicTableCell text={total} />
              }
            },
            {
              id: 'customer',
              accessorKey: 'customerAlias',
              header: 'Customer',
              cell: value => (
                <MagicTableAutoLoader<
                  ExtractQueryParams<typeof useGetCustomersByAliasByAliasQuery>,
                  { customerId?: string }
                >
                  queryParams={{
                    alias: value.getValue<SeatSnapshotModel['customerAlias']>()
                  }}
                  useQuery={useGetCustomersByAliasByAliasQuery}
                  extract={customer => ({
                    customerId: customer?.id
                  })}
                >
                  {({ customerId }) => {
                    if (!customerId) {
                      return (
                        <MagicTableCell
                          text="Alias not mapped to Customer"
                          borderColor={RedRed20}
                          textColor={RedRed50}
                        />
                      )
                    }

                    return (
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        height="40px"
                        pl="6px"
                      >
                        <CustomerPreviewCardPill
                          customerId={customerId}
                          variant="ghost"
                        />
                      </Flex>
                    )
                  }}
                </MagicTableAutoLoader>
              )
            },
            {
              id: 'seatType',
              accessorKey: 'seatType',
              header: 'Seat type',
              cell: value => (
                <MagicTableCellEmpty>
                  <Flex overflow="hidden">
                    <Badge sentiment="neutral">
                      {value.getValue<SeatSnapshotModel['seatType']>()}
                    </Badge>
                  </Flex>
                </MagicTableCellEmpty>
              )
            },
            {
              id: 'id',
              accessorKey: 'id',
              header: 'Event ID',
              cell: value => (
                <MagicTableCell
                  text={value.getValue<SeatSnapshotModel['id']>()}
                />
              )
            },
            {
              id: 'eventTimestamp',
              accessorKey: 'eventTimestamp',
              header: 'Timestamp (UTC)',
              cell: value => (
                <MagicTableCell
                  text={value.getValue<
                    DashboardApi20240730.GetSeatEvents.SeatEvent['eventTimestamp']
                  >()}
                />
              ),
              enableSorting: true
            }
          ]}
          emptyState={emptyState}
          additionalToolbarComponents={
            <SeatBalancesViewSelector active="snapshots" />
          }
        />
      )}
    </CurrentUserId>
  )
}
