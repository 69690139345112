import { SwitchLineInput } from '@sequencehq/core-components'
import { Box, Flex, Link } from '@chakra-ui/react'
import { FC } from 'react'

import {
  BorderRadius6,
  GreyGrey10,
  GreyGrey70,
  GreyGrey80,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import {
  StripeInspectorItemData,
  useStripePaymentWidget
} from './useStripePaymentWidget'

interface StripePaymentInspectorItemProps {
  fieldData: {
    acceptPaymentsViaStripe: {
      value: boolean
      disabled?: boolean
    }
  }
  onChange: (newData: StripeInspectorItemData) => void
  disabled?: boolean
}

export const StripePaymentWidget: FC<
  StripePaymentInspectorItemProps
> = props => {
  const { fields } = useStripePaymentWidget({
    dataFromInterface: {
      acceptPaymentsViaStripe: props.fieldData.acceptPaymentsViaStripe
    },
    onChange: props.onChange,
    disabled: props.disabled
  })

  return (
    <Flex flexDirection="column" gap="8px">
      <Box {...Lato13Bold} color={GreyGrey80}>
        Stripe payments
      </Box>
      <Box
        borderRadius={BorderRadius6}
        backgroundColor={GreyGrey10}
        padding="8px"
      >
        <SwitchLineInput
          isDisabled={fields.acceptPaymentsViaStripe.isDisabled}
          text="Accept payments via Stripe"
          value={fields.acceptPaymentsViaStripe.value}
          onChange={fields.acceptPaymentsViaStripe.onChange}
          styles={{
            label: {
              ...Lato13Regular,
              color: GreyGrey80
            }
          }}
          data-testid="stripe.inspector.content.acceptPayments"
        />
      </Box>
      <Box {...Lato13Regular} color={GreyGrey70}>
        When enabled, a Stripe payment link is added to invoices, and Sequence
        auto-charges the customer for future invoices once a payment method is
        on file,{' '}
        <Link
          href="https://docs.sequencehq.com/integrations/stripe"
          target="_blank"
          rel="noreferrer"
          color={IndigoIndigo50}
        >
          learn more.
        </Link>
      </Box>
    </Flex>
  )
}
