import { validateDecimals } from '@sequencehq/validation'

export const isDigit = (char: string | undefined) => {
  return typeof char === 'string' && char.length === 1 && /\d/.test(char)
}

type ScrubNumberOptions = {
  allowDecimal: boolean
}

// remove excess formatting from string number
// such as commas or currency symbol
export const scrubNumber = (
  number: string,
  { allowDecimal }: ScrubNumberOptions
) => {
  let dotUsed = !allowDecimal

  return number
    .split('')
    .filter(char => {
      if (isDigit(char)) {
        return true
      }

      if (char === '.') {
        if (!dotUsed) {
          dotUsed = true
          return true
        }
      }

      return false
    })
    .join('')
}

type ParseDecimalArgs = {
  decimalPlaces: '2' | '4' | '10'
  cache?: Record<string, string>
}

export const parseDecimal = ({
  decimalPlaces,
  cache = {}
}: ParseDecimalArgs) => {
  return (value: unknown, name = '') => {
    const defaultValue = ''

    if (typeof value !== 'string') {
      return defaultValue
    }

    const scrubbed = scrubNumber(value, { allowDecimal: true })

    if (validateDecimals(scrubbed, decimalPlaces)) {
      cache[name] = scrubbed

      return scrubbed
    }

    return cache[name]
  }
}

export const parseInteger = (value: unknown) => {
  if (typeof value !== 'string') {
    return ''
  }

  const result = parseInt(scrubNumber(value, { allowDecimal: false }))

  return isNaN(result) ? '' : result.toFixed()
}
