import { FormFields, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { PricingEditorReducerState } from 'common/drawers/PricingEditor/domain'
import { PRICING_MODEL_OPTIONS } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CommonFields,
  useCommonFieldConfig
} from '../common/useCommonFieldConfig'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { useUsageMetricParameterFieldConfig } from '../common/UsageMetrics/useUsageMetricParameterFieldConfig'
import {
  percentageTiersValidator,
  tiersValidator
} from 'common/drawers/PricingEditor/utils/validators/tiers.validators'
import usageCalculationPeriodMapper from 'common/drawers/PricingEditor/utils/usageCalculationPeriodMapper.ts'
import { UsageCalculationPeriodDropdownValue } from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/UsageCalculationPeriodDropdown/UsageCalculationPeriodDropdown.tsx'
import {
  availableUsageCalculationModes,
  availableUsageCalculationPeriodsByBillingFrequency,
  availableUsageTierTypes,
  standardPriceFrequencies
} from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/GraduatedPriceForm.constants.ts'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { commonPriceDetails } from '../common/ReadOnlyDetails/readOnlyDetails.utils'

type GraduatedPriceFields = Omit<
  PricingEditorReducerState['data']['pricingEditorData']['GRADUATED'],
  'usageCalculationPeriod'
> & {
  usageCalculationPeriod: UsageCalculationPeriodDropdownValue
}

type GraduatedPriceFormFieldConfig = Omit<
  FormFields<CommonFields>,
  'pricingModel' | 'currency'
> & {
  pricingModel: FormFields<CommonFields>['pricingModel'] & {
    description: string
  }
  currency: FormFields<CommonFields>['currency'] & {
    hidden: boolean
  }
} & Omit<FormFields<GraduatedPriceFields>, 'usageMetricId'> & {
    usageMetricId: FormFields<GraduatedPriceFields>['usageMetricId'] & {
      onAddNew: () => void
    }
  }

type UseGraduatedPriceForm = () => {
  fieldsConfig: GraduatedPriceFormFieldConfig
  currency: Currency
  priceDetails: Array<{ value: string; label: string }>
}

export const useGraduatedPriceForm: UseGraduatedPriceForm = () => {
  const pricingEditorContext = usePricingEditorDomainContext()
  const navigate = useNavigate()
  const flags = useFlags()

  const commonFields = useCommonFieldConfig<
    GraduatedPriceFields & CommonFields
  >()
  const parameterFields = useUsageMetricParameterFieldConfig<
    GraduatedPriceFields & CommonFields
  >(
    pricingEditorContext.queries.rawData.data.pricingEditorData.GRADUATED
      .usageMetricId,
    () => false
  )

  const availableFrequencies = useMemo(() => {
    return standardPriceFrequencies.filter(frequency => {
      return pricingEditorContext.queries.rawData.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [
    pricingEditorContext.queries.rawData.configuration
      .availableStandardFrequencies
  ])

  const availableUsageMetrics = useMemo(() => {
    return Object.values(
      pricingEditorContext.queries.rawData.data.usageMetrics
    ).map(metric => ({
      label: `${metric.name} (${metric.aggregationType})`,
      value: metric.id
    }))
  }, [pricingEditorContext.queries.rawData.data.usageMetrics])

  const { fields, queries } = useForm({
    value: {
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.common,
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.GRADUATED,
      usageCalculationPeriod:
        usageCalculationPeriodMapper.fromApiPricePropertiesToUsageCalculationPeriodDropdown(
          {
            usageCalculationMode:
              pricingEditorContext.queries.rawData.data.pricingEditorData
                .GRADUATED.usageCalculationMode,
            usageCalculationPeriod:
              pricingEditorContext.queries.rawData.data.pricingEditorData
                .GRADUATED.usageCalculationPeriod
          },
          pricingEditorContext.queries.rawData.data.pricingEditorData.GRADUATED
            .billingFrequency
        )
    },
    fieldConfiguration: [
      ...commonFields.fields,
      {
        property: 'billingFrequency',
        options: availableFrequencies,
        validation: [required]
      },
      {
        property: 'usageMetricId',
        options: availableUsageMetrics,
        validation: [required]
      },
      {
        property: 'tiers',
        disabled: ctx => ctx.formData.usageTierType !== 'FIXED',
        validation: [required, tiersValidator]
      },
      {
        property: 'percentageTiers',
        disabled: ctx => ctx.formData.usageTierType !== 'PERCENTAGE',
        validation: [required, percentageTiersValidator]
      },
      {
        property: 'usageTierType',
        validation: [required],
        options: availableUsageTierTypes
      },
      {
        property: 'usageCalculationMode',
        validation: !flags.useUsageResetCadences ? [required] : [],
        options: availableUsageCalculationModes
      },
      {
        property: 'usageCalculationPeriod',
        validation: flags.useUsageResetCadences ? [required] : [],
        options:
          availableUsageCalculationPeriodsByBillingFrequency[
            pricingEditorContext.queries.rawData.data.pricingEditorData
              .GRADUATED.billingFrequency
          ]
      },
      ...parameterFields.fields
    ],
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    disabled:
      !pricingEditorContext.queries.availableFeatures.common.form.available
        .enabled,
    onValidationStateChange: isValid => {
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      })
    },
    onChange: newData => {
      if (!flags.useUsageResetCadences) {
        pricingEditorContext.mutators.common.updatePricingEditorData({
          common: {
            name: newData.name,
            pricingModel: newData.pricingModel,
            currency: newData.currency,
            listPriceId: newData.listPriceId
          },
          GRADUATED: {
            billingFrequency: newData.billingFrequency,
            usageMetricId: newData.usageMetricId,
            tiers: newData.tiers,
            percentageTiers: newData.percentageTiers,
            usageTierType: newData.usageTierType,
            usageCalculationMode: newData.usageCalculationMode
            // TODO: parameter fields
          }
        })
        return
      }

      const mappedCalculationStructure =
        usageCalculationPeriodMapper.fromUsageCalculationPeriodDropdownToApiPriceProperties(
          newData.usageCalculationPeriod,
          newData.billingFrequency
        )

      pricingEditorContext.mutators.common.updatePricingEditorData({
        common: {
          name: newData.name,
          pricingModel: newData.pricingModel,
          currency: newData.currency,
          listPriceId: newData.listPriceId
        },
        GRADUATED: {
          billingFrequency: newData.billingFrequency,
          usageMetricId: newData.usageMetricId,
          tiers: newData.tiers,
          percentageTiers: newData.percentageTiers,
          usageTierType: newData.usageTierType,
          usageCalculationMode: mappedCalculationStructure.usageCalculationMode,
          usageCalculationPeriod:
            mappedCalculationStructure.usageCalculationPeriod
          // TODO: parameter fields
        }
      })
    }
  })

  const enhancedFields = useMemo(
    () => ({
      ...fields,
      pricingModel: {
        ...fields.pricingModel,
        description:
          PRICING_MODEL_OPTIONS.find(
            option => option.value === queries.formData.pricingModel
          )?.description ?? ''
      },
      currency: {
        ...fields.currency,
        hidden: Boolean(
          pricingEditorContext.queries.rawData.configuration.currency
        )
      },
      usageMetricId: {
        ...fields.usageMetricId,
        onAddNew: () => navigate('./metrics/new')
      }
    }),
    [
      fields,
      navigate,
      pricingEditorContext.queries.rawData.configuration.currency,
      queries.formData.pricingModel
    ]
  )

  const priceDetails = useMemo(
    () => [
      ...commonPriceDetails({
        pricingModel: 'GRADUATED',
        billingFrequency: queries.formData.billingFrequency,
        billingType: queries.formData.billingType
      }),
      {
        label: 'Usage metric',
        value:
          fields.usageMetricId.options.find(
            opt => opt.value === queries.formData.usageMetricId
          )?.label ?? 'Not selected'
      },
      {
        label: 'Type',
        value:
          fields.usageTierType.options.find(
            opt => opt.value === queries.formData.usageTierType
          )?.label ?? 'Not selected'
      },
      {
        label: flags.useUsageResetCadences
          ? 'Usage calculation period'
          : 'Calculation mode',
        value:
          (flags.useUsageResetCadences
            ? fields.usageCalculationMode.options.find(
                opt => opt.value === queries.formData.usageCalculationMode
              )?.label
            : fields.usageCalculationPeriod.options.find(
                opt => opt.value === queries.formData.usageCalculationPeriod
              )?.label) ?? 'Not selected'
      }
    ],
    [
      fields.usageCalculationMode.options,
      fields.usageCalculationPeriod.options,
      fields.usageMetricId.options,
      fields.usageTierType.options,
      flags.useUsageResetCadences,
      queries.formData.billingFrequency,
      queries.formData.billingType,
      queries.formData.usageCalculationMode,
      queries.formData.usageCalculationPeriod,
      queries.formData.usageMetricId,
      queries.formData.usageTierType
    ]
  )

  return {
    fieldsConfig: enhancedFields,
    currency:
      pricingEditorContext.queries.rawData.data.pricingEditorData.common
        .currency,
    priceDetails
  }
}
