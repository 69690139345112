import { Currency } from '@sequencehq/api/utils/commonEnums'
import { toCurrencySymbol } from '@sequencehq/core-models'

/**
 * A simple formatter for displaying the total for the graphs.
 * (Maybe could become a generic util in the lib directory? One to consider
 * when extracting useful bits of out of this module at the end.)
 * @param param0
 * @returns
 */
export const formatTotal = ({
  currency,
  value
}: {
  currency: Currency
  value: number
}): string =>
  `${toCurrencySymbol(currency)}${Intl.NumberFormat('en-GB', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(value)}`
