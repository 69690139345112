import { SeatChangeModel } from '@sequencehq/core-models'

export const getSeatAddedRemoved = (seatChanges: SeatChangeModel[]) => {
  const { added, removed } = seatChanges.reduce(
    (acc, seatChange) => {
      if (seatChange.changeType === 'SEAT_ADDED') {
        acc.added++
      } else if (seatChange.changeType === 'SEAT_REMOVED') {
        acc.removed++
      }
      return acc
    },
    { added: 0, removed: 0 }
  )

  return { added, removed }
}
