import { Flex, Grid, IconButton, Text } from '@chakra-ui/react'
import InformationCircleIcon from '@heroicons/react/16/solid/InformationCircleIcon'
import PencilSquareIcon from '@heroicons/react/16/solid/PencilSquareIcon'
import { DateDurationField, Popover } from '@sequencehq/core-components'
import {
  GreyGrey30,
  GreyGrey50,
  GreyWhite,
  IndigoIndigo50,
  Lato14ExtendedBold,
  Lato14Regular,
  LineHeights20
} from '@sequencehq/design-tokens'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { SummaryInputModal } from 'InvoiceEditor/components/Summary/SummaryInputModal'
import { useInvoiceEditorSummary } from 'InvoiceEditor/components/Summary/useInvoiceEditorSummary'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { InvoiceEditorSummaryInvoiceDate } from './InvoiceDate'
import { InvoiceEditorSummaryBillingPeriod } from './BillingPeriod'
import { format } from '@sequencehq/utils/dates'

export const InvoiceEditorSummary = () => {
  const { data: contextData, functions: functionsInvoiceEditor } =
    useInvoiceEditorContext()
  const { data, editDueDate, editPurchaseOrderNumber, editReference } =
    useInvoiceEditorSummary()

  const [isInvoiceDetailsHovered, setIsInvoiceDetailsHovered] = useState(false)
  const [openPOInputModal, setOpenPOInputModal] = useState(false)
  const [openReferenceInputModal, setOpenReferenceInputModal] = useState(false)

  return (
    <Flex
      gap={8}
      padding="24px"
      border={`${GreyGrey30} 1px solid`}
      boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 4px 0px rgba(0, 0, 0, 0.02)"
      borderRadius="8px"
      backgroundColor={GreyWhite}
    >
      <Grid
        templateColumns="minmax(120px, 180px) minmax(193px, 1fr)"
        rowGap={2}
        width="full"
        placeContent="start"
      >
        <Text {...Lato14Regular} lineHeight={LineHeights20} color="gray.70">
          Billed to
        </Text>
        <Flex alignItems="center">
          <CustomerPreviewCardPill customerId={data.customerId} />
        </Flex>
        <Text {...Lato14Regular} lineHeight={LineHeights20} color="gray.70">
          Billing details
        </Text>
        <Flex flexDirection="column">
          {data.customerEmails.map((email, index) => (
            <Text
              key={index}
              {...Lato14Regular}
              lineHeight={LineHeights20}
              color="gray.90"
              wordBreak="break-all"
            >
              {email}
            </Text>
          ))}

          {data.customerAddress.map((addressField, index) => (
            <Text
              key={index}
              {...Lato14Regular}
              lineHeight={LineHeights20}
              color="gray.90"
              wordBreak="break-all"
            >
              {addressField}
            </Text>
          ))}
        </Flex>
      </Grid>
      <Grid
        templateColumns="minmax(120px, 180px) minmax(193px, 1fr)"
        rowGap={2}
        width="full"
        placeContent="start"
        onMouseEnter={() => setIsInvoiceDetailsHovered(true)}
        onMouseLeave={() => setIsInvoiceDetailsHovered(false)}
        role="group"
      >
        <Text {...Lato14Regular} lineHeight={LineHeights20} color="gray.70">
          Invoice number
        </Text>
        <Flex>
          <Text
            {...Lato14Regular}
            lineHeight={LineHeights20}
            color={data.invoiceNumber ? 'gray.90' : 'gray.60'}
          >
            {data.invoiceNumber || 'Pending'}
          </Text>
        </Flex>
        <Flex gap={1} alignItems="center">
          <Text {...Lato14Regular} lineHeight={LineHeights20} color="gray.70">
            Due date
          </Text>
          <Popover
            position="left"
            content={
              <Text {...Lato14Regular} lineHeight={LineHeights20}>
                The invoice due date is based on the default payment terms set
                in{' '}
                <Text
                  as="span"
                  {...Lato14ExtendedBold}
                  lineHeight={LineHeights20}
                  color={IndigoIndigo50}
                >
                  <Link to={`/settings/merchant`}>merchant settings.</Link>
                </Text>
              </Text>
            }
          >
            <InformationCircleIcon height={18} width={18} color={GreyGrey50} />
          </Popover>
        </Flex>
        <Flex display="flex">
          {editDueDate.available ? (
            <DateDurationField
              mode="absolute"
              validationErrors={[]}
              durations={[
                /**
                 * By default we want to include 30 days, and the default duration
                 * configured for the merchant. These are the values we show by default
                 * when nothing specific has been set. If someone picks something
                 * specific, we'll instead show the date.
                 */
                ...(contextData.merchant.defaultDueDateDays === 30
                  ? []
                  : [
                      {
                        diff: { days: 30 },
                        label: '30 Days',
                        value: '30-days'
                      }
                    ]),
                {
                  diff: { days: contextData.merchant.defaultDueDateDays },
                  label: `${contextData.merchant.defaultDueDateDays} Days`,
                  value: `${contextData.merchant.defaultDueDateDays}-days`
                },
                {
                  diff: 'CUSTOM',
                  label: 'Custom',
                  value: 'CUSTOM'
                }
              ]}
              width="100%"
              value={
                contextData.invoice.dueDate
                  ? new Date(contextData.invoice.dueDate)
                  : undefined
              }
              onChange={newDueDate => {
                const formattedDueDate = newDueDate
                  ? format(newDueDate, 'yyyy-MM-dd')
                  : undefined
                functionsInvoiceEditor.updateData({
                  ...contextData,
                  invoice: {
                    ...contextData.invoice,
                    dueDate: formattedDueDate
                  }
                })

                editDueDate.onChange(formattedDueDate)
              }}
              styles={{
                wrapper: {
                  marginBottom: 0
                }
              }}
              showAbsoluteDate={true}
              variant="pill"
            />
          ) : (
            <Text {...Lato14Regular} lineHeight={LineHeights20} color="gray.90">
              {contextData.invoice.dueDate || '—'}
            </Text>
          )}
        </Flex>
        <InvoiceEditorSummaryBillingPeriod />

        {data.billingScheduleId && (
          <>
            <Text {...Lato14Regular} lineHeight={LineHeights20} color="gray.70">
              Billing schedule
            </Text>
            <Flex alignItems="center">
              <Text
                {...Lato14ExtendedBold}
                lineHeight={LineHeights20}
                color={IndigoIndigo50}
              >
                <Link to={`/billing-schedules/${data.billingScheduleId}`}>
                  View schedule
                </Link>
              </Text>
            </Flex>
          </>
        )}
        <InvoiceEditorSummaryInvoiceDate />
        <Text {...Lato14Regular} lineHeight={LineHeights20} color="gray.70">
          Currency
        </Text>
        <Text
          {...Lato14Regular}
          lineHeight={LineHeights20}
          color={data.currency ? 'gray.90' : 'gray.60'}
        >
          {data.currency || '—'}
        </Text>
        <Text {...Lato14Regular} lineHeight={LineHeights20} color="gray.70">
          Tax calculation
        </Text>
        <Text
          {...Lato14Regular}
          lineHeight={LineHeights20}
          color={data.taxStatus ? 'gray.90' : 'gray.60'}
        >
          {data.taxStatus || '—'}
        </Text>
        <Text {...Lato14Regular} lineHeight={LineHeights20} color="gray.70">
          PO Number
        </Text>
        <Flex display="flex" alignItems="center" gap={2}>
          <Text {...Lato14Regular} lineHeight={LineHeights20} color="gray.90">
            {data.purchaseOrderNumber || '—'}
          </Text>
          {isInvoiceDetailsHovered && editPurchaseOrderNumber.available && (
            <IconButton
              aria-label="Edit customer"
              minWidth="16px"
              height="16px"
              width="16px"
              icon={<PencilSquareIcon height="16px" width="16px" />}
              onClick={() => setOpenPOInputModal(true)}
              variant="ghost2"
              marginLeft={2}
            />
          )}
        </Flex>
        <Text {...Lato14Regular} lineHeight={LineHeights20} color="gray.70">
          Reference
        </Text>
        <Flex display="flex" alignItems="center" gap={2}>
          <Text {...Lato14Regular} lineHeight={LineHeights20} color="gray.90">
            {data.reference || '—'}
          </Text>
          {isInvoiceDetailsHovered && editReference.available && (
            <IconButton
              aria-label="Edit customer"
              minWidth="16px"
              height="16px"
              width="16px"
              icon={<PencilSquareIcon height="16px" width="16px" />}
              onClick={() => setOpenReferenceInputModal(true)}
              variant="ghost2"
              marginLeft={2}
            />
          )}
        </Flex>
      </Grid>
      <SummaryInputModal
        open={openPOInputModal}
        value={data.purchaseOrderNumber || ''}
        onSave={purchaseOrderNumber => {
          editPurchaseOrderNumber.onChange(purchaseOrderNumber)
          setOpenPOInputModal(false)
        }}
        modalTitle="Edit PO Number"
        inputTitle="Po Number"
        onClose={() => setOpenPOInputModal(false)}
      />
      <SummaryInputModal
        open={openReferenceInputModal}
        value={data.reference || ''}
        onSave={reference => {
          editReference.onChange(reference)
          setOpenReferenceInputModal(false)
        }}
        modalTitle="Edit Reference"
        inputTitle="Reference"
        onClose={() => setOpenReferenceInputModal(false)}
      />
    </Flex>
  )
}
