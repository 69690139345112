import { Box, Grid } from '@chakra-ui/react'
import {
  GreyGrey60,
  GreyGrey80,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { ReactNode } from 'react'
import { Skeleton } from '@sequencehq/core-components'
import { capitalize, compose, replace } from 'lodash/fp'
import { Link } from 'react-router-dom'
import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/utils'
import invariant from 'tiny-invariant'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'

const RowItem = (props: { label: string; content: ReactNode }) => {
  return (
    <Grid
      gridTemplateColumns={'81px 1fr'}
      width="100%"
      gridColumnGap="10px"
      alignItems="center"
      height="32px"
      {...Lato13Regular}
    >
      <Box color={GreyGrey60}>{props.label}</Box>
      <Box>{props.content}</Box>
    </Grid>
  )
}

type UseDeferredRevenueSchedules = (props: { scheduleId: string }) =>
  | {
      summary: {
        id: string
        customer: {
          id: string
          name: string
        }
        product: {
          id: string
          name: string
        }
        invoice?: {
          id: string
          name: string
        }
        method: Dashboardv99990101Api.GetDeferredRevenueSchedules.DeferredRecognitionMethod
      }
      isLoading: false
    }
  | {
      summary: null
      isLoading: true
    }

/**
 * Data acquisition for the summary
 * @param props
 * @returns
 */
export const useDefRevScheduleSummary: UseDeferredRevenueSchedules = (props: {
  scheduleId: string
}) => {
  const scheduleQuery = useQuery(
    dashboardv99990101Client.getDeferredRevenueSchedule,
    { id: props.scheduleId },
    {
      select: res => {
        if (!res) {
          return null
        }

        return {
          id: res.id,
          customer: res.customer,
          product: res.product,
          invoice: res.invoice,
          method: res.recognitionMethod
        }
      }
    }
  )

  /**
   * We throw this error to be caught by the appropriate error boundary.
   */
  if (scheduleQuery.error) {
    throw new Error('Schedule summary could not be loaded')
  }

  if (scheduleQuery.isPending) {
    return {
      isLoading: true,
      summary: null
    }
  }

  invariant(scheduleQuery.data, 'Summary should be defined, if loaded')

  return {
    isLoading: false,
    summary: scheduleQuery.data
  }
}

export const DefRevScheduleSummary = (props: { defRevScheduleId: string }) => {
  const { isLoading, summary } = useDefRevScheduleSummary({
    scheduleId: props.defRevScheduleId
  })

  return (
    <Box>
      <Box {...Lato13Bold} color={GreyGrey80} marginBottom="8px">
        Summary
      </Box>
      {isLoading ? (
        <Skeleton height="160px" width="100%" />
      ) : (
        <>
          <RowItem
            label="Customer"
            content={
              <CustomerPreviewCardPill
                customerId={summary.customer.id}
                variant="ghost"
              />
            }
          />
          <RowItem
            label="Product"
            content={
              <Link
                to={`/products/${summary.product.id}`}
                style={{
                  color: IndigoIndigo50,
                  ...Lato13Bold
                }}
              >
                {summary.product.name}
              </Link>
            }
          />
          {summary.invoice && (
            <RowItem
              label="Invoice"
              content={
                <Link
                  to={`/invoices/${summary.invoice.id}`}
                  style={{
                    color: IndigoIndigo50,
                    ...Lato13Bold
                  }}
                >
                  {summary.invoice.name}
                </Link>
              }
            />
          )}
          <RowItem
            label="Recognition"
            content={compose(capitalize, replace('_', ' '))(summary.method)}
          />
        </>
      )}
    </Box>
  )
}
