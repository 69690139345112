import { useStandardPriceForm } from './useStandardPriceForm'
import {
  TextField,
  SelectField,
  HorizontalSelectorField,
  PriceField
} from '@sequencehq/core-components'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { PricingInformation } from '../common/PricingInformation'
import { Box, Text } from '@chakra-ui/react'
import {
  AvailableStandardFrequency,
  BillingType
} from 'common/drawers/PricingEditor/domain'
import { GreyGrey90, Lato14Bold } from '@sequencehq/design-tokens'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'

export const StandardPriceListPriceForm = () => {
  const { fieldsConfig } = useStandardPriceForm()

  return (
    <>
      <Text
        color={GreyGrey90}
        fontWeight="bold"
        fontSize="l"
        textColor="##1D2939"
        mb="6"
      >
        Pricing Information
      </Text>
      <PricingInformation
        value={fieldsConfig.pricingModel.value}
        isDisabled={fieldsConfig.pricingModel.disabled}
        options={fieldsConfig.pricingModel.options}
        validationErrors={fieldsConfig.pricingModel.validationErrors}
        onChange={fieldsConfig.pricingModel.onChange}
        description={fieldsConfig.pricingModel.description}
      />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="standard-field-name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      <SelectField
        label="Currency"
        data-testid="standard-field-currency"
        value={fieldsConfig.currency.value}
        onChange={newCurrency =>
          fieldsConfig.currency.onChange(newCurrency as Currency)
        }
        options={fieldsConfig.currency.options}
        validationErrors={fieldsConfig.currency.validationErrors}
        isDisabled={fieldsConfig.currency.disabled}
        placeholder="Select currency"
      />

      <Box paddingBottom="8px">
        <HorizontalSelectorField
          label="Frequency"
          data-testid="standard.billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />

        <HorizontalSelectorField
          label="Type"
          data-testid="standard.billingType"
          tooltip={{
            title: 'Billing type',
            content: (
              <Box paddingTop="8px">
                <Box {...Lato14Bold} marginBottom="8px">
                  In arrears
                </Box>
                <Box marginBottom="16px">
                  Charges will be applied at the end of the billing period
                </Box>
                <Box {...Lato14Bold} marginBottom="8px">
                  In advance
                </Box>
                <Box>
                  Charges will be applied at the start of the billing period
                </Box>
              </Box>
            )
          }}
          value={fieldsConfig.billingType.value}
          onChange={newValue =>
            fieldsConfig.billingType.onChange(newValue as BillingType)
          }
          options={fieldsConfig.billingType.options}
          validationErrors={fieldsConfig.billingType.validationErrors}
          isDisabled={fieldsConfig.billingType.disabled}
        />

        <PriceField
          label="Price"
          data-testid="listPrice.standard.price"
          value={fieldsConfig.price.value}
          onChange={newValue => fieldsConfig.price.onChange(newValue)}
          validationErrors={fieldsConfig.price.validationErrors}
          currency={fieldsConfig.currency.value}
          isDisabled={fieldsConfig.price.disabled}
        />
      </Box>

      <ExternalLedgerForm />
    </>
  )
}
