import { Flex, Text } from '@chakra-ui/react'
import { GreyGrey80, Lato14Bold } from '@sequencehq/design-tokens'
import { Button } from '@sequencehq/core-components'
import { PlusIcon } from '@heroicons/react/16/solid'
import AddTaxCategoryForAvalaraModal from 'Settings/view/taxRatesSettings/avalara/modals/AddTaxCategoryForAvalaraModal'
import AvalaraTaxCodeMappingTable from 'Settings/view/taxRatesSettings/avalara/common/AvalaraTaxCodeMappingTable'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useRemoveAvalaraMapping } from 'Settings/view/taxRatesSettings/avalara/hooks/useRemoveAvalaraMapping'
import { useCreateAvalaraMapping } from 'Settings/view/taxRatesSettings/avalara/hooks/useCreateAvalaraMapping'
import { useCreateTaxCategoryWithMapping } from 'Settings/view/taxRatesSettings/avalara/hooks/useCreateTaxCategoryWithMapping'
import type { AvalaraTaxCategoryMapping } from 'Settings/domain/taxRates.types'
import { useMarkTaxCategoryAsDefault } from 'Settings/view/taxRatesSettings/hooks/useMarkTaxCategoryAsDefault'

const AvalaraTaxCategoriesMappingSection = () => {
  const taxCategoriesResult = useQuery(
    dashboardv99990101Client.getTaxCategories
  )

  const mappingsResult = useQuery(
    dashboardv99990101Client.getAllTaxCategoriesWithAvalaraMappings
  )

  const allExistingTaxCategories = taxCategoriesResult.data?.items || []

  const taxCategoriesWithAvalaraMappings: AvalaraTaxCategoryMapping[] =
    allExistingTaxCategories.map(taxCategory => {
      const mapping = mappingsResult.data?.items?.find(
        ({ taxCategoryId }) => taxCategoryId === taxCategory.id
      )

      return {
        taxCategoryId: taxCategory.id,
        taxCategoryName: taxCategory.name,
        isDefault: taxCategory.isDefault,
        taxName: mapping?.taxName,
        avalaraTaxCode: mapping?.avalaraTaxCode
      }
    })

  const createTaxCategoryWithMapping = useCreateTaxCategoryWithMapping()

  const createAvalaraMapping = useCreateAvalaraMapping()

  const removeAvalaraMapping = useRemoveAvalaraMapping()

  const markTaxCategoryAsDefault = useMarkTaxCategoryAsDefault()

  return (
    <Flex flexDirection="column" width="100%" gap={2}>
      <Flex justifyContent="space-between" align="center">
        <Text {...Lato14Bold} color={GreyGrey80}>
          Categories
        </Text>
        <AddTaxCategoryForAvalaraModal
          onCreate={createTaxCategoryWithMapping}
          trigger={
            <Button
              data-testid="avalaraTaxCategoriesMapping.newCategory"
              variant="ghost"
              leadingIcon={
                <PlusIcon width="16px" height="16px" color="inherit" />
              }
            >
              New category
            </Button>
          }
        />
      </Flex>

      <AvalaraTaxCodeMappingTable
        taxCategoriesWithAvalaraMappings={taxCategoriesWithAvalaraMappings}
        onAddAvalaraTaxCode={createAvalaraMapping}
        onRemoveAvalaraTaxCode={removeAvalaraMapping}
        onMarkTaxCategoryAsDefault={markTaxCategoryAsDefault}
      />
    </Flex>
  )
}

export default AvalaraTaxCategoriesMappingSection
