import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { StripeInspectorItemData } from 'Integrations/index'
import { useMemo } from 'react'

export const useBillingScheduleInspector = () => {
  const ctx = useCubeContext()
  const { updateData } = ctx.mutators

  const stripe = useMemo(() => {
    return {
      available:
        ctx.queries.availableFeatures.schedule.stripePayment.available.enabled,
      data: {
        acceptPaymentsViaStripe: {
          value: ctx.queries.rawData.data.schedule.stripePayment,
          disabled:
            !ctx.queries.availableFeatures.schedule.stripePayment.available
              .enabled
        }
      },
      onChange: (newData: StripeInspectorItemData) => {
        if (
          newData.acceptPaymentsViaStripe.value !==
          ctx.queries.rawData.data.schedule.stripePayment
        ) {
          updateData({
            schedule: {
              stripePayment: newData.acceptPaymentsViaStripe.value
            }
          })
        }
      }
    }
  }, [
    ctx.queries.availableFeatures.schedule.stripePayment.available,
    ctx.queries.rawData.data.schedule.stripePayment,
    updateData
  ])

  return {
    stripe,
    customerId: ctx.queries.rawData.data.common.customerId
  }
}
