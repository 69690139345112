import { Button, Drawer, DrawerOverlay, Flex } from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/16/solid'
import EmptyState from 'components/Loading/EmptyState'
import Page from 'components/Page'
import { SeatBalanceOutletProvider } from 'components/SeatBalances/SeatBalanceOutlet'
import SeatSnapshotInspector from 'components/SeatSnapshots/Inspector/SeatSnapshotInspector'
import { SeatSnapshotsMagicTable } from 'components/SeatSnapshots/SeatSnapshotsMagicTable'
import { SeatTypeProvider } from 'components/SeatType'
import { useNavigate, useSearchParams } from 'react-router-dom'

const emptyContent = {
  title: 'No seat events to show',
  description: 'Get started by submitting a seat event.',
  route: '/seat-balances/new?view=snapshots',
  buttonCopy: 'New seat event',
  linkCopy: 'Learn more',
  docLink: 'https://docs.sequencehq.com/seats/examples'
}

const ActionButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      variant="component-library-primary"
      leftIcon={<PlusIcon width="16px" height="16px" color="inherit" />}
      iconSpacing="4px"
      onClick={onClick}
    >
      New seat event
    </Button>
  )
}

export const SeatSnapshots = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const snapshotId = searchParams.get('snapshotId')
  const queryParamsString = searchParams.toString()

  return (
    <>
      <Flex flexDirection="column" flex={1}>
        <Flex position="sticky" top={0} height="100vh">
          <Page
            title="Events"
            paddingBottom={0}
            actionElement={
              <ActionButton
                onClick={() =>
                  navigate(
                    `/seat-events/new${
                      queryParamsString ? `?${queryParamsString}` : ''
                    }`
                  )
                }
              />
            }
          >
            <SeatSnapshotsMagicTable
              emptyState={props => (
                <EmptyState emptyContent={emptyContent} {...props} />
              )}
            />
            {snapshotId && (
              <Drawer
                isOpen
                placement="right"
                onClose={() => navigate(`/seat-events`)}
                variant="sidebar"
                size="lg"
              >
                <DrawerOverlay />
                <SeatSnapshotInspector snapshotId={snapshotId} />
              </Drawer>
            )}
          </Page>
        </Flex>
      </Flex>

      <SeatTypeProvider>
        <SeatBalanceOutletProvider />
      </SeatTypeProvider>
    </>
  )
}

export default SeatSnapshots
