import { Box, Grid, GridItem } from '@chakra-ui/react'
import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/16/solid'
import { Currency } from '@sequencehq/api/utils/commonEnums'
import { Button, InputSelectControl } from '@sequencehq/core-components'
import { GreyGrey80, Lato14Bold } from '@sequencehq/design-tokens'
import { useExpanded } from './selectorHooks'
import { useAvailableCurrencies } from 'RevenueRecognition/view/utils/useAvailableCurrencies'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'

/**
 * The actions for managing the detail, including collapsing/expanding and changing
 * the currency.
 *
 * This is not bundled in with the table component because the UI colocation is not guaranteed!
 * @returns
 */
export const JournalReportActions = () => {
  const { expanded, toggleExpanded } = useExpanded()
  const { availableCurrencies } = useAvailableCurrencies()
  const { currency, setCurrency } = useSelectedCurrency()

  return (
    <Grid gridTemplateColumns="1fr auto" alignItems="center">
      <GridItem {...Lato14Bold} color={GreyGrey80} userSelect="none">
        Overview
      </GridItem>
      <GridItem display="flex" gap="8px">
        <Button
          variant="secondary"
          data-testid="revrec.detail.toggleTable"
          leadingIcon={
            expanded ? (
              <BarsArrowUpIcon width="16px" />
            ) : (
              <BarsArrowDownIcon width="16px" />
            )
          }
          onClick={toggleExpanded}
        >
          {expanded ? 'Collapse table' : 'Expand table'}
        </Button>
        <Box width="84px">
          {!!currency && !!availableCurrencies && (
            <InputSelectControl
              data-testid="revrec.detail.currencySelect"
              options={
                availableCurrencies?.map(ac => ({
                  label: ac,
                  value: ac
                })) ?? []
              }
              initialValue={currency}
              onChange={newCurrency => setCurrency(newCurrency as Currency)}
            />
          )}
        </Box>
      </GridItem>
    </Grid>
  )
}
