import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback } from 'react'
import lodash from 'lodash'
import { useAddTaxRegistration } from 'components/Customers/hooks/useAddTaxRegistration.ts'
import { useUpdateTaxRegistration } from 'components/Customers/hooks/useUpdateTaxRegistration.ts'
import { CountriesAlpha2, State } from '@sequencehq/api/commonEnums'
import { useMutation } from '@sequencehq/api/utils'
import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'

type Customer = Dashboardv99990101Api.PutCustomer.UpdateCustomerBody & {
  showTaxRegistration: boolean
  taxCountry: CountriesAlpha2
  taxState?: State
  taxIdentifier?: string
  taxRegistrationId?: string
}

export const useCustomerRoot = ({
  refetchCustomer,
  refetchCustomerAliases,
  refetchTaxRegistration
}: {
  refetchCustomer: () => Promise<void>
  refetchCustomerAliases: () => Promise<void>
  refetchTaxRegistration: () => Promise<void>
}) => {
  const notifications = useNotifications()
  const updateCustomer = useMutation(dashboardv99990101Client.putCustomer)
  const { addTaxRegistration } = useAddTaxRegistration()
  const { updateTaxRegistration } = useUpdateTaxRegistration()

  const editCustomer = useCallback(
    async (id: string, customer: Customer) => {
      const filterEmptyValues = (obj: object): object => {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([, value]) => value !== '' && value !== undefined)
            .map(([key, value]) =>
              value && typeof value === 'object' && !Array.isArray(value)
                ? [key, filterEmptyValues(value as object)]
                : [key, value]
            )
        )
      }

      notifications.displayNotification('Updating customer...', {
        duration: 30000
      })

      const customerBody = {
        ...lodash.omit(filterEmptyValues(customer), [
          'taxIdentifier',
          'taxCountry',
          'taxState',
          'showTaxRegistration'
        ])
      } as Dashboardv99990101Api.PutCustomer.UpdateCustomerBody

      try {
        const saveResult = await updateCustomer.mutateAsync({
          id,
          body: customerBody
        })

        if (customer.showTaxRegistration && !!customer.taxIdentifier) {
          const taxRegistrationBody = {
            taxIdentifier: customer.taxIdentifier,
            country: customer.taxCountry,
            state: ['US', 'CA'].includes(customer.taxCountry)
              ? customer.taxState
              : undefined,
            customerId: id
          }

          const taxRegistrationResult = customer.taxRegistrationId
            ? await updateTaxRegistration(
                customer.taxRegistrationId,
                taxRegistrationBody
              )
            : await addTaxRegistration(taxRegistrationBody)

          if (!taxRegistrationResult) {
            notifications.displayNotification(
              customer.taxRegistrationId
                ? 'Failed to update tax registration for customer'
                : 'Failed to create tax registration for customer',
              {
                type: 'error'
              }
            )
            return saveResult
          }
        }

        notifications.displayNotification('Customer updated', {
          type: 'success'
        })
        await refetchCustomer()
        await refetchCustomerAliases()
        await refetchTaxRegistration()
        return saveResult
      } catch (e) {
        const error = e as Error
        notifications.displayNotification('Failed to update customer', {
          type: 'error',
          description: error.message
        })
      }
    },
    [
      notifications,
      updateCustomer,
      refetchCustomer,
      refetchCustomerAliases,
      refetchTaxRegistration
    ]
  )

  return {
    functions: {
      editCustomer
    }
  }
}
