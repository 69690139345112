import { Box } from '@chakra-ui/react'
import { Button, PageTitle, StandardLayout } from '@sequencehq/core-components'
import { ArrowDownTrayIcon } from '@heroicons/react/16/solid'

export const DeferredRevenueHeader = () => {
  return (
    <Box>
      <StandardLayout.Header
        title={
          <>
            <PageTitle title={['Deferred Revenue']} />
            <StandardLayout.Title>Deferred Revenue</StandardLayout.Title>
          </>
        }
        actions={
          <Button
            variant="secondary"
            leadingIcon={<ArrowDownTrayIcon width="16px" />}
            data-testid="revrec.deferred.exportCSV"
            onClick={() => alert('TODO')}
          >
            Export to CSV
          </Button>
        }
      />
    </Box>
  )
}
