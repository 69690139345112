import { Box, Button, Flex, Grid, GridItem, Icon } from '@chakra-ui/react'
import CalendarIcon from '@heroicons/react/20/solid/CalendarIcon'
import EllipsisVerticalIcon from '@heroicons/react/24/outline/EllipsisVerticalIcon'
import ReceiptPercentIcon from '@heroicons/react/20/solid/ReceiptPercentIcon'
import {
  GreyGrey30,
  GreyGrey60,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { WidgetSection } from 'modules/Cube/view/common/atomic/WidgetSection'
import { useTimelineOverviewWidget } from 'modules/Cube/view/layouts/billingSchedule/widgets/TimelineOverview/useTimelineOverviewWidget'
import Spinner from 'components/Loading/Spinner'
import { ReactNode } from 'react'

const TimelineRow = ({
  description,
  date,
  isLastRow,
  icon
}: {
  description: string
  date: string
  icon?: ReactNode
  isLastRow?: boolean
}) => {
  return (
    <Grid
      gridTemplateAreas={`
  "icon description"
  "connecting-line date"
`}
      gridTemplateColumns={'auto 1fr'}
      gridColumnGap="12px"
      gridRowGap="4px"
      maxWidth="360px"
      width="360px"
    >
      <GridItem gridArea="icon">
        {icon ?? (
          <Icon
            as={CalendarIcon}
            height="20px"
            width="20px"
            color={GreyGrey60}
          />
        )}
      </GridItem>
      <Box gridArea="description" {...Lato13Bold}>
        {description}
      </Box>
      <Flex
        gridArea="connecting-line"
        alignItems="center"
        justifyContent="center"
        paddingBottom="4px"
      >
        <Box
          width="2px"
          height="24px"
          backgroundColor={!isLastRow ? GreyGrey30 : 'transparent'}
        ></Box>
      </Flex>

      <Box gridArea="date" {...Lato13Regular} color={GreyGrey60}>
        {date}
      </Box>
    </Grid>
  )
}
export const TimelineOverviewWidget = () => {
  const { loading, data, showAllInvoices, updateShowAllInvoices } =
    useTimelineOverviewWidget()

  if (loading) {
    return (
      <WidgetSection title="Overview">
        <Flex
          padding="0 24px 24px"
          alignItems="center"
          justifyContent="center"
          height="300px"
        >
          <Spinner />
        </Flex>
      </WidgetSection>
    )
  }

  if (!data?.billingStartDate || !data?.billingEndDate) {
    return (
      <WidgetSection title="Overview">
        <Box padding="0 24px 24px" {...Lato13Regular} color={GreyGrey60}>
          Select a start date and an end date to see the billing timeline
          preview
        </Box>
      </WidgetSection>
    )
  }

  return (
    <WidgetSection title="Overview">
      <Box padding="8px 24px" {...Lato13Regular}>
        <TimelineRow
          description="Billing starts"
          date={data?.billingStartDate}
        />
        {showAllInvoices
          ? data.invoiceDates.slice(0, -1).map(invoiceDate => {
              return (
                <TimelineRow
                  key={invoiceDate}
                  description="Invoice date"
                  date={invoiceDate}
                  icon={
                    <Icon
                      as={ReceiptPercentIcon}
                      height="20px"
                      width="20px"
                      color={GreyGrey60}
                    />
                  }
                />
              )
            })
          : data.invoiceDates.slice(0, 3).map(invoiceDate => {
              return (
                <TimelineRow
                  key={invoiceDate}
                  description="Invoice date"
                  date={invoiceDate}
                  icon={
                    <Icon
                      as={ReceiptPercentIcon}
                      height="20px"
                      width="20px"
                      color={GreyGrey60}
                    />
                  }
                />
              )
            })}
        {!showAllInvoices && (
          <Grid
            gridTemplateAreas={`
  "icon button"
  "connecting-line empty"
`}
            gridTemplateColumns={'auto 1fr'}
            gridColumnGap="12px"
            gridRowGap="4px"
          >
            <Flex
              gridArea="icon"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <EllipsisVerticalIcon height="20px" color={GreyGrey60} />
            </Flex>
            <GridItem gridArea="button">
              <Button
                variant="secondary2"
                size="xs"
                onClick={() => updateShowAllInvoices(true)}
              >
                View all
              </Button>
            </GridItem>
            <Flex
              gridArea="connecting-line"
              alignItems="center"
              justifyContent="center"
              paddingBottom="4px"
            >
              <Box width="2px" height="8px" backgroundColor={GreyGrey30}></Box>
            </Flex>
          </Grid>
        )}
        {data.finalInvoiceAfterEndDate && (
          <TimelineRow description="Billing ends" date={data?.billingEndDate} />
        )}
        {Boolean(data.invoiceDates.length) && (
          <TimelineRow
            isLastRow={data.finalInvoiceAfterEndDate}
            description="Final invoice"
            date={data.invoiceDates[data.invoiceDates.length - 1]}
            icon={
              <Icon
                as={ReceiptPercentIcon}
                height="20px"
                width="20px"
                color={GreyGrey60}
              />
            }
          />
        )}
        {!data.finalInvoiceAfterEndDate && (
          <TimelineRow
            description="Billing ends"
            date={data?.billingEndDate}
            isLastRow
          />
        )}
      </Box>
    </WidgetSection>
  )
}
