import { Avatar, Box, Flex, Text, Spinner } from '@chakra-ui/react'
import {
  CreditNoteModel,
  Currency,
  CustomerModel
} from '@sequencehq/core-models'
import { Label } from '@sequencehq/forms'
import { required } from '@sequencehq/validation'
import { CurrencyInputWrapper } from 'components/CurrencyInputWrapper/CurrencyInputWrapper'
import { CustomerComboInputComplete } from 'components/FormComponents/CustomerComboInputComplete'
import { ModalForm, useHandleMutationResult } from 'components/Modal'
import { usePostCreditNotesMutation } from 'features/api'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { handleFormResponse } from 'lib/formValidation'
import { ExtractMutationParams } from 'lib/types'
import { FC, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { useDefaultCurrency } from 'components/CurrencySettings/useCurrencies'

type MutationParams = ExtractMutationParams<typeof usePostCreditNotesMutation>

type CreditNoteFormValues = {
  currency: Currency
  customerId: string
}

interface CreateCreditNoteFormProps {
  customer: CustomerModel | undefined
  variant?: 'v2'
}

const CreateCreditNoteForm: FC<CreateCreditNoteFormProps> = ({
  customer,
  variant
}) => {
  const [submitForm, result] = usePostCreditNotesMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const defaultCurrency = useDefaultCurrency()

  const successMessage = useCallback(
    ({ creditNoteNumber = '' }: CreditNoteModel) => {
      return `New draft credit note ${creditNoteNumber} added`
    },
    []
  )

  const onSuccess = useCallback(({ id = '' }: CreditNoteModel) => {
    navigate(`/credit-notes/${id}`)
    dispatch(closeOverlay())
  }, [])

  useHandleMutationResult<MutationParams>({
    result,
    successMessage,
    errorMessage: 'Error creating credit note',
    onSuccess
  })

  if (!defaultCurrency) {
    return <Spinner />
  }

  return (
    <Form<CreditNoteFormValues>
      keepDirtyOnReinitialize
      initialValues={{
        currency: defaultCurrency,
        customerId: customer?.id
      }}
      onSubmit={async (values, form) => {
        const res = await submitForm({
          createCreditNoteEndpointCreateCreditNoteRequestModel: {
            currency: values.currency,
            customerId: values.customerId,
            customerBillingAddress: undefined,
            customerEmails: undefined,
            customerLegalCompanyName: undefined,
            customerShippingAddress: undefined,
            memo: undefined,
            purchaseOrderNumber: undefined
          }
        })

        return handleFormResponse(res, form.getRegisteredFields())
      }}
      render={formProps => (
        <ModalForm
          {...formProps}
          title="Create draft credit note"
          submitLabel="Save draft credit note"
          variant={variant}
        >
          <CurrencyInputWrapper width="100%" />
          <Box height={5} />
          {customer ? (
            <>
              <Label>Customer</Label>
              <Box height={2} />
              <Flex flexDirection="row" alignItems="center">
                <Avatar name={customer.legalName} mr={2} size="sm" />
                <Text>{customer.legalName}</Text>
              </Flex>
            </>
          ) : (
            <CustomerComboInputComplete
              fieldName="customerId"
              addNewAction={() => navigate('/invoices/customers/new')}
              validate={required}
            />
          )}
        </ModalForm>
      )}
    />
  )
}

export default CreateCreditNoteForm
