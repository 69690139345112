import { Avatar, Box, Button, Flex, Text } from '@chakra-ui/react'
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon'
import { GreyGrey30 } from '@sequencehq/design-tokens'
import { Switch, TooltipLegacy } from '@sequencehq/core-components'
import { WidgetSection } from 'modules/Cube/view/common/atomic/WidgetSection'
import { usePaymentWidget } from 'modules/Cube/view/layouts/billingSchedule/widgets/Payment/usePaymentWidget'
import { FC } from 'react'
import { IntegrationsBrowserModal } from 'Integrations/index'

export const PaymentWidget: FC = () => {
  const { fieldsConfig } = usePaymentWidget()

  return (
    <WidgetSection title="Payment">
      <Box padding="4px 24px 20px">
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Flex
            alignItems="center"
            cursor={
              !fieldsConfig.stripePayment.disabled ? 'pointer' : 'default'
            }
            onClick={() => {
              !fieldsConfig.stripePayment.disabled &&
                fieldsConfig.stripePayment.onChange(
                  !fieldsConfig.stripePayment.value
                )
            }}
            userSelect="none"
          >
            <Avatar
              size="xs"
              name="Stripe"
              src="https://logo.clearbit.com/stripe.com"
              mr={2}
              borderRadius="4px"
            />
            <Text marginRight="4px">Accept payments via Stripe</Text>
            <TooltipLegacy
              title="Include Stripe Payment link"
              content="Add a Stripe payment link to invoices. If your customer pays via credit card or Direct Debit, Sequence will auto-charge the customer for subsequent invoices."
            >
              <InformationCircleIcon height="20px" />
            </TooltipLegacy>
          </Flex>
          <TooltipLegacy
            title="Stripe integration"
            disabled={!fieldsConfig.stripePayment.disabledReason}
            content={fieldsConfig.stripePayment.disabledReason}
          >
            <Box mr="8px">
              <Switch
                id="stripePayment"
                checked={fieldsConfig.stripePayment.value}
                disabled={fieldsConfig.stripePayment.disabled}
                onChange={checked =>
                  fieldsConfig.stripePayment.onChange(checked)
                }
              />
            </Box>
          </TooltipLegacy>
        </Flex>
      </Box>
      <Box borderTop={`1px solid ${GreyGrey30}`} padding="8px 12px">
        <IntegrationsBrowserModal
          trigger={
            <Button variant="secondary2" width="100%">
              Manage integrations
            </Button>
          }
        />
      </Box>
    </WidgetSection>
  )
}
