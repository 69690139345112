import { CreateSeatTypeForm } from 'components/SeatType'
import Sidebar from 'components/Sidebar'
import { useCreateSeatTypeOutletConnector } from 'modules/Products/drawers/ListPriceEditor/components/CreatSeatTypeDrawer/useCreateSeatTypeOutletConnector'

/**
 * Route components are expected to be rendered as part of the routing definitions,
 * such as routes.tsx.
 *
 * @returns
 */
export const ListPriceSeatTypeRouteComponent = () => {
  const { onSuccess, onCancel } = useCreateSeatTypeOutletConnector()

  return (
    <Sidebar>
      <CreateSeatTypeForm onSuccess={onSuccess} handleCancel={onCancel} />
    </Sidebar>
  )
}
