import { FormFields, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import {
  AvailableStandardFrequency,
  PricingEditorReducerState,
  UsageTierType
} from 'common/drawers/PricingEditor/domain'
import {
  defaultAvailableFrequenciesOptions,
  PRICING_MODEL_OPTIONS
} from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useMemo } from 'react'
import { useCommonFieldConfig } from '../common/useCommonFieldConfig'
import { useNavigate } from 'react-router-dom'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { useUsageMetricParameterFieldConfig } from '../common/UsageMetrics/useUsageMetricParameterFieldConfig'
import {
  percentageTiersValidator,
  tiersValidator
} from 'common/drawers/PricingEditor/utils/validators/tiers.validators'
import { commonPriceDetails } from '../common/ReadOnlyDetails/readOnlyDetails.utils'

type CommonFields =
  PricingEditorReducerState['data']['pricingEditorData']['common']
type VolumePriceFields =
  PricingEditorReducerState['data']['pricingEditorData']['VOLUME']

type VolumePriceFormFieldConfig = Omit<
  FormFields<CommonFields>,
  'pricingModel'
> & {
  pricingModel: FormFields<CommonFields>['pricingModel'] & {
    description: string
  }
} & Omit<FormFields<VolumePriceFields>, 'usageMetricId'> & {
    usageMetricId: FormFields<VolumePriceFields>['usageMetricId'] & {
      onAddNew: () => void
    }
  }

type UseVolumePriceForm = () => {
  fieldsConfig: VolumePriceFormFieldConfig
  currency: Currency
  priceDetails: { label: string; value: string }[]
}

const standardPriceFrequencies: {
  value: AvailableStandardFrequency
  label: string
}[] = [
  {
    value: 'ONE_TIME',
    label: 'Once'
  },
  ...defaultAvailableFrequenciesOptions
]

const availableUsageTierTypes: { label: string; value: UsageTierType }[] = [
  {
    label: 'Fixed',
    value: 'FIXED'
  },
  {
    label: 'Percentage',
    value: 'PERCENTAGE'
  }
]

export const useVolumePriceForm: UseVolumePriceForm = () => {
  const pricingEditorContext = usePricingEditorDomainContext()
  const navigate = useNavigate()

  const availableFrequencies = useMemo(() => {
    return standardPriceFrequencies.filter(frequency => {
      return pricingEditorContext.queries.rawData.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [
    pricingEditorContext.queries.rawData.configuration
      .availableStandardFrequencies
  ])

  const availableUsageMetrics = useMemo(() => {
    return Object.values(
      pricingEditorContext.queries.rawData.data.usageMetrics
    ).map(metric => ({
      label: `${metric.name} (${metric.aggregationType})`,
      value: metric.id
    }))
  }, [pricingEditorContext.queries.rawData.data.usageMetrics])

  const commonFields = useCommonFieldConfig<VolumePriceFields & CommonFields>()
  const parameterFields = useUsageMetricParameterFieldConfig<
    VolumePriceFields & CommonFields
  >(
    pricingEditorContext.queries.rawData.data.pricingEditorData.VOLUME
      .usageMetricId,
    () => false
  )

  const { fields, queries } = useForm({
    value: {
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.common,
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.VOLUME
    },
    fieldConfiguration: [
      ...commonFields.fields,
      {
        property: 'billingFrequency',
        options: availableFrequencies,
        validation: [required]
      },
      {
        property: 'usageMetricId',
        options: availableUsageMetrics,
        validation: [required]
      },
      {
        property: 'tiers',
        disabled: ctx => ctx.formData.usageTierType !== 'FIXED',
        validation: [required, tiersValidator]
      },
      {
        property: 'percentageTiers',
        disabled: ctx => ctx.formData.usageTierType !== 'PERCENTAGE',
        validation: [required, percentageTiersValidator]
      },
      {
        property: 'usageTierType',
        validation: [required],
        options: availableUsageTierTypes
      },
      {
        property: 'includePercentageLimits'
      },
      ...parameterFields.fields
    ],
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    disabled:
      !pricingEditorContext.queries.availableFeatures.common.form.available
        .enabled,
    onValidationStateChange: isValid => {
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      })
    },
    onChange: newData => {
      const { name, pricingModel, currency, ...rest } = newData

      pricingEditorContext.mutators.common.updatePricingEditorData({
        common: {
          name,
          pricingModel,
          currency
        },
        VOLUME: rest
      })
    }
  })

  const enhancedFields = useMemo(() => {
    return {
      ...fields,
      pricingModel: {
        ...fields.pricingModel,
        description:
          PRICING_MODEL_OPTIONS.find(
            option => option.value === queries.formData.pricingModel
          )?.description ?? ''
      },
      usageMetricId: {
        ...fields.usageMetricId,
        onAddNew: () => navigate('./metrics/new')
      }
    }
  }, [fields, navigate, queries.formData.pricingModel])

  const priceDetails = useMemo(() => {
    return [
      ...commonPriceDetails({
        pricingModel: 'VOLUME',
        billingFrequency: queries.formData.billingFrequency,
        billingType: queries.formData.billingType
      }),
      {
        label: 'Usage metric',
        value:
          fields.usageMetricId.options.find(
            opt => opt.value === queries.formData.usageMetricId
          )?.label ?? 'Not selected'
      },
      {
        label: 'Type',
        value:
          fields.usageTierType.options.find(
            opt => opt.value === queries.formData.usageTierType
          )?.label ?? 'Not selected'
      }
    ]
  }, [
    fields.usageMetricId.options,
    fields.usageTierType.options,
    queries.formData.billingFrequency,
    queries.formData.billingType,
    queries.formData.usageMetricId,
    queries.formData.usageTierType
  ])

  return {
    fieldsConfig: enhancedFields,
    currency: queries.formData.currency,
    priceDetails
  }
}
