import * as Sentry from '@sentry/react'
import { PriceModel } from '@sequencehq/core-models'
import { arrayToIdKeyedObject } from '@sequencehq/utils'
import { INITIAL_CUBE_STATE } from 'modules/Cube/domain/cube.constants'
import {
  Contact,
  ContactPostBody,
  CorePortErrors,
  CubeDomainInput,
  CubePorts,
  CubeReducerState,
  Discount,
  Minimum,
  Phase,
  RecursivePartial,
  SchedulePreviewArguments,
  SchedulePreviewResponse
} from 'modules/Cube/domain/cube.domain.types'
import { billingScheduleReducer } from 'modules/Cube/domain/cube.reducer'
import { useCallback, useMemo, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'

/**
 * The functions and data can be as complex or as simple as we need them to be.
 * However, the general rule of thumb is that any complicated or expensive queries
 * should be done as a post action state and made accessible via the 'data' object
 * (which, in this super early draft is just a composition of the 'data' and 'derived'
 * state to keep things super simple).
 */
export type CubeDomainInterface = {
  queries: CubeReducerState['queries'] & {
    rawData: Pick<CubeReducerState, 'data' | 'configuration' | 'editor'>
    /**
     * This is used for some niche queries related to the initial state of
     * the editor, such as whether or not the version initial had a price on
     * it. It is only to be accessed, exclusively, by queries, and set by the
     * load action.
     */
    initialData: CubeReducerState['data']
  } & {
    /**
     * Initial queries are a snapshot taken when first loading into the domain. Useful
     * for understanding if something has changed in the editor relative to the version
     * on the service.
     */
    initialQueries: CubeReducerState['initialQueries']
  }
  mutators: {
    external: {
      in: Omit<CubePorts['in'], 'core'> & {
        core: () => Promise<{
          data: CubeDomainInput | null
          error: CorePortErrors | null
        }>
        schedule: {
          preview: (
            args: SchedulePreviewArguments
          ) => Promise<SchedulePreviewResponse>
        }
        contacts?: {
          reloadContacts: (customerId: string) => Promise<void>
        }
      }
      out: {
        save: (args?: {
          reload?: boolean
          showValidationErrors?: boolean
        }) => Promise<void>
        archive: () => Promise<void>
        schedule?: {
          activate: () => Promise<void>
        }
        quote?: {
          publish: (data: {
            sendRecipientsEmail: boolean
            emailMessage: string | undefined
          }) => Promise<void>
          accept: () => Promise<void>
          execute: () => Promise<void>
          deleteLatestDraft: () => Promise<void>
          duplicate: () => Promise<void>
        }
        contacts?: {
          createContact: ({
            customerId,
            body
          }: {
            customerId: string
            body: ContactPostBody
          }) => Promise<Contact | undefined>
        }
      }
    }
    updateData: (
      newBillingScheduleData: RecursivePartial<CubeReducerState['data']>
    ) => void
    updateEditor: (newEditorData: Partial<CubeReducerState['editor']>) => void
    deleteData: (dataToDelete: {
      type: keyof CubeReducerState['data']
      ids: string[]
    }) => void
    updateConfiguration: (
      newConfigurationData: RecursivePartial<CubeReducerState['configuration']>
    ) => void
    updateFieldForVersion: (
      billingScheduleVersionId: Phase['id']
    ) => <T extends keyof Phase>(fieldName: T) => (newValue: Phase[T]) => void
    deleteDiscount: (
      discountIdToDelete: Discount['id']
    ) => (priceId?: PriceModel['id']) => void
    deletePrice: (
      billingScheduleVersionId: Phase['id']
    ) => (priceId: PriceModel['id']) => void
    deleteMinimum: (
      billingScheduleVersionId: Phase['id']
    ) => (minimumId: Minimum['id']) => void
    alignPhaseDuration: (phaseId: Phase['id']) => void
  }
}

type UseCubeDomain = (props: { ports: CubePorts }) => CubeDomainInterface

export const useCubeDomain: UseCubeDomain = props => {
  const navigate = useNavigate()

  const [state, dispatch] = useReducer(
    billingScheduleReducer,
    INITIAL_CUBE_STATE
  )

  /**
   * Create the query interface
   */
  const queries = useMemo(() => {
    return {
      ...state.queries,
      initialData: state.initialData,
      rawData: {
        data: state.data,
        configuration: state.configuration,
        editor: state.editor
      }
    }
  }, [state])

  /**
   * Base functionality to update configuration and 'physical' data.
   */
  const updateData = useCallback(
    (newData: RecursivePartial<CubeReducerState['data']>) => {
      dispatch({
        type: 'updateData',
        payload: newData
      })
    },
    []
  )

  const deleteData = useCallback(
    (dataToDelete: { type: keyof CubeReducerState['data']; ids: string[] }) => {
      dispatch({
        type: 'deleteData',
        payload: dataToDelete
      })
    },
    []
  )

  const updateConfiguration = useCallback(
    (
      newConfigurationData: RecursivePartial<CubeReducerState['configuration']>
    ) => {
      dispatch({
        type: 'updateConfiguration',
        payload: newConfigurationData
      })
    },
    []
  )

  const updateEditor = useCallback(
    (newEditorData: Partial<CubeReducerState['editor']>) => {
      dispatch({
        type: 'updateEditor',
        payload: newEditorData
      })
    },
    []
  )

  const loadData = props.ports.in.core
  const load = useCallback(async () => {
    const loadedData = await loadData()

    if (loadedData.error || !loadedData.data) {
      return {
        data: null,
        error: loadedData.error || CorePortErrors.Other
      }
    }

    dispatch({
      type: 'load',
      payload: loadedData.data
    })

    return {
      data: loadedData.data,
      error: null
    }
  }, [loadData])

  /**
   * More specific functionality
   */
  const updateFieldForVersion = useCallback(
    (phaseId: Phase['id']) =>
      <T extends keyof Phase>(fieldName: T) =>
      (newValue: Phase[T]) => {
        dispatch({
          type: 'updateData',
          payload: {
            phases: {
              [phaseId]: {
                [fieldName]: newValue
              }
            }
          }
        })
      },
    [dispatch]
  )

  const deleteDiscount = useCallback(
    (discountIdToDelete: Discount['id']) => (forPrice?: PriceModel['id']) => {
      const discount = queries.rawData.data.discounts[discountIdToDelete]
      const removeDiscountEntirely = !forPrice || discount.priceIds.length === 1

      dispatch({
        type: 'updateData',
        payload: {
          phases: removeDiscountEntirely
            ? Object.values(queries.rawData.data.phases).reduce(
                (acc, phase) => {
                  return {
                    ...acc,
                    [phase.id]: {
                      ...phase,
                      discountIds: phase.discountIds.filter(
                        discountId => discountId !== discountIdToDelete
                      )
                    }
                  }
                },
                {}
              )
            : queries.rawData.data.phases,
          discounts: {
            ...queries.rawData.data.discounts,
            [discountIdToDelete]: {
              ...discount,
              priceIds: discount.priceIds.filter(
                discountPriceId => discountPriceId !== forPrice
              )
            }
          }
        }
      })
    },
    [queries.rawData]
  )

  const deletePrice = useCallback(
    (versionId: Phase['id']) => (priceId: PriceModel['id']) => {
      dispatch({
        type: 'updateData',
        payload: {
          phases: {
            [versionId]: {
              priceIds: queries.rawData.data.phases[versionId].priceIds.filter(
                id => id !== priceId
              ),
              discountIds: queries.rawData.data.phases[
                versionId
              ].discountIds.filter(discountId => {
                return !queries.rawData.data.discounts[
                  discountId
                ].priceIds.filter(
                  discountPriceId => discountPriceId === priceId
                ).length
              })
            }
          }
        }
      })
    },
    [queries.rawData.data]
  )

  const deleteMinimum = useCallback(
    (versionId: Phase['id']) => (minimumId: Minimum['id']) => {
      dispatch({
        type: 'updateData',
        payload: {
          phases: {
            [versionId]: {
              minimumIds: queries.rawData.data.phases[
                versionId
              ].minimumIds.filter(id => id !== minimumId)
            }
          }
        }
      })
    },
    [queries.rawData.data.phases]
  )
  /**
   * We return a curated state (which will mostly track the billing schedule reducer state
   * by default, but does not have to!) and functions to perform certain actions - we do
   * not expose dispatch in the interface in order for us to abstract the use of a reducer
   * from the wider consumers of the context.
   */
  const save = useCallback(
    async ({
      reload = false,
      showValidationErrors = true
    }: { reload?: boolean; showValidationErrors?: boolean } = {}) => {
      dispatch({
        type: 'updateEditor',
        payload: {
          activeValidationSet:
            showValidationErrors &&
            queries.validation.validationErrorsPresent.save
              ? 'save'
              : queries.rawData.editor.activeValidationSet,
          savingDraft: !queries.validation.validationErrorsPresent.save
        }
      })

      if (queries.validation.validationErrorsPresent.save) {
        return
      }

      try {
        await props.ports.out.save({
          ...queries,
          initialQueries: state.initialQueries
        })
        if (reload) {
          void load()
        }
      } catch (e) {
        Sentry.captureException(e)
      } finally {
        dispatch({
          type: 'updateEditor',
          payload: {
            savingDraft: false
          }
        })
      }
    },
    [props, queries, load, state]
  )

  const archive = useCallback(async () => {
    await props.ports.out.archive()
    void load()
  }, [load, props])

  const duplicate = useCallback(async () => {
    if (!('quote' in props.ports.out)) {
      return
    }

    try {
      const duplicatedQuote = await props.ports.out.quote.duplicate()

      if (!duplicatedQuote) {
        throw new Error('Missing duplicated quote ID')
      }

      dispatch({ type: 'reset', payload: undefined })

      navigate(`/quotes/${duplicatedQuote.newQuoteId}`)
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [navigate, props.ports.out])

  const publishQuote = useCallback(
    async (data: {
      sendRecipientsEmail: boolean
      emailMessage: string | undefined
    }) => {
      if (!('quote' in props.ports.out)) {
        return
      }
      dispatch({
        type: 'updateEditor',
        payload: {
          activeValidationSet: queries.validation.validationErrorsPresent
            .publish
            ? 'publish'
            : undefined,
          savingSchedule: !queries.validation.validationErrorsPresent.publish
        }
      })

      if (queries.validation.validationErrorsPresent.publish) {
        return
      }

      try {
        await save()
        await props.ports.out.quote.publish(data)
        void load()
      } catch (e) {
        Sentry.captureException(e)
      } finally {
        dispatch({
          type: 'updateEditor',
          payload: {
            savingSchedule: false
          }
        })
      }
    },
    [props, queries, load]
  )

  const activateSchedule = useCallback(async () => {
    if (!('schedule' in props.ports.out)) {
      return
    }
    if (!props.ports.out.schedule?.activate) {
      return
    }
    dispatch({
      type: 'updateEditor',
      payload: {
        activeValidationSet: queries.validation.validationErrorsPresent.publish
          ? 'publish'
          : undefined,
        savingSchedule: !queries.validation.validationErrorsPresent.publish
      }
    })

    if (queries.validation.validationErrorsPresent.publish) {
      return
    }

    try {
      await props.ports.out.schedule.activate({
        ...queries,
        initialQueries: state.initialQueries
      })
      void load()
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      dispatch({
        type: 'updateEditor',
        payload: {
          savingSchedule: false
        }
      })
    }
  }, [props, queries, load, state])

  const acceptQuote = useCallback(async () => {
    if (!('quote' in props.ports.out)) {
      return
    }

    dispatch({
      type: 'updateEditor',
      payload: {
        activeValidationSet: queries.validation.validationErrorsPresent.accept
          ? 'accept'
          : undefined,
        savingSchedule: !queries.validation.validationErrorsPresent.accept
      }
    })

    if (queries.validation.validationErrorsPresent.accept) {
      return
    }

    try {
      await props.ports.out.quote.accept()
      void load()
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      dispatch({
        type: 'updateEditor',
        payload: {
          savingSchedule: false
        }
      })
    }
  }, [props, queries, load])

  const deleteLatestQuoteDraft = useCallback(async () => {
    if (!('quote' in props.ports.out)) {
      return
    }

    dispatch({
      type: 'updateEditor',
      payload: {
        activeValidationSet: queries.validation.validationErrorsPresent
          .deleteLatestDraft
          ? 'deleteLatestDraft'
          : undefined,
        savingSchedule:
          !queries.validation.validationErrorsPresent.deleteLatestDraft
      }
    })

    if (queries.validation.validationErrorsPresent.deleteLatestDraft) {
      return
    }

    try {
      await props.ports.out.quote.deleteLatestDraft()
      void load()
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      dispatch({
        type: 'updateEditor',
        payload: {
          savingSchedule: false
        }
      })
    }
  }, [props, queries, load])

  const executeQuote = useCallback(async () => {
    if (!('quote' in props.ports.out)) {
      return
    }

    dispatch({
      type: 'updateEditor',
      payload: {
        activeValidationSet: queries.validation.validationErrorsPresent.execute
          ? 'execute'
          : undefined,
        savingSchedule: !queries.validation.validationErrorsPresent.execute
      }
    })

    if (queries.validation.validationErrorsPresent.execute) {
      return
    }

    try {
      await props.ports.out.quote.execute()
      void load()
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      dispatch({
        type: 'updateEditor',
        payload: {
          savingSchedule: false
        }
      })
    }
  }, [props, queries, load])

  const alignPhaseDuration = useCallback((phaseId: Phase['id']) => {
    dispatch({
      type: 'alignPhaseDuration',
      payload: {
        phaseId
      }
    })
  }, [])

  const schedulePreview = useCallback(
    async (args: SchedulePreviewArguments) => {
      if (!('schedule' in props.ports.in)) {
        return Promise.resolve([])
      }

      if (props.ports.in.schedule?.preview) {
        return props.ports.in.schedule.preview(args)
      }

      Sentry.captureException(
        new Error('No schedule preview function provided')
      )
      return Promise.resolve([])
    },
    [props.ports.in]
  )

  const reloadContacts = useCallback(
    async (customerId: string) => {
      if (!('contacts' in props.ports.in)) {
        return
      }

      try {
        const newContacts = await props.ports.in.contacts.reloadContacts({
          customerId
        })

        dispatch({
          type: 'updateData',
          payload: {
            contacts: arrayToIdKeyedObject(newContacts)
          }
        })
      } catch (e) {
        Sentry.captureException(e)
      }
    },
    [props.ports.in]
  )

  const createContact = useCallback(
    async ({
      customerId,
      body
    }: {
      customerId: string
      body: ContactPostBody
    }) => {
      if (!('contacts' in props.ports.out)) {
        return
      }

      try {
        const newContact = await props.ports.out.contacts.createContact({
          customerId,
          body
        })

        if (!newContact) {
          return
        }

        dispatch({
          type: 'updateData',
          payload: {
            contacts: arrayToIdKeyedObject([newContact])
          }
        })

        return newContact
      } catch (e) {
        Sentry.captureException(e)
      }
    },
    [props.ports.out]
  )

  return {
    queries: {
      ...queries,
      initialQueries: state.initialQueries
    },
    mutators: {
      external: {
        in: {
          core: load,
          customer: props.ports.in.customer,
          schedule: {
            preview: schedulePreview
          },
          contacts: {
            reloadContacts
          }
        },
        out: {
          save: save,
          archive: archive,
          schedule: {
            activate: activateSchedule
          },
          quote: {
            publish: publishQuote,
            accept: acceptQuote,
            execute: executeQuote,
            deleteLatestDraft: deleteLatestQuoteDraft,
            duplicate
          },
          contacts: {
            createContact
          }
        }
      },
      updateConfiguration,
      updateData,
      updateEditor,
      deleteData,
      updateFieldForVersion,
      deleteDiscount,
      deletePrice,
      deleteMinimum,
      alignPhaseDuration
    }
  }
}
