import { IntegrationConfig } from 'modules/Integrations/domain'
import xeroLogo from './xeroLogo.svg'
import { xeroConfigurationLoader } from 'modules/Integrations/integrationsConfig/xero/xero.loader'
import { xeroConfigurationMutator } from 'modules/Integrations/integrationsConfig/xero/xero.mutator'
import { XeroConfigurationManagement } from './XeroConfigurationManagement/XeroConfigurationManagement'
import { XeroIntegrationDetail } from './XeroIntegrationDetail/XeroIntegrationDetail'
import { XeroIntegrationManagementInspector } from './XeroIntegrationManagementInspector/XeroIntegrationManagementInspector'

export const xeroIntegrationConfig: IntegrationConfig = {
  logoUrl: xeroLogo,
  smallLogoUrl: xeroLogo,
  documentationUrl: 'https://docs.sequencehq.com/integrations/xero',
  title: 'Xero',
  description:
    'Sync Sequence invoices in real-time and streamline your accounting process.',
  features: {
    canDeleteLinks: true,
    canUninstall: true,
    isDocumentationOnly: false
  },
  components: {
    detail: XeroIntegrationDetail,
    management: XeroConfigurationManagement,
    inspector: XeroIntegrationManagementInspector
  },
  communication: {
    configuration: {
      loader: xeroConfigurationLoader,
      mutator: xeroConfigurationMutator
    }
  }
}

export type XeroIntegrationConfig = {
  defaultLedgerAccount: string
  defaultCreditLedgerAccount: string
  defaultMinimumLedgerAccount: string
  untaxedCustomerTaxCode: string
  /**
   * This is a value which does not exist on the API; it's effectively how we control
   * if an integration is 'paused' or not for now. It's expected that a way to more
   * directly configure this value will be exposed at some point!
   */
  importCustomerWorkflow: boolean
}
