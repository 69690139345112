import { Box, Icon, Text } from '@chakra-ui/react'
import {
  HorizontalSelectorField,
  NumberField,
  PriceField,
  SelectField,
  SelectFieldWithActions,
  TextField
} from '@sequencehq/core-components'
import { UsageMetricParameterFields } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/utils/UsageMetricParameterFields'
import { AvailableStandardFrequency } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { usePackagedPriceForm } from './usePackagedPriceForm'
import { ReadOnlyDetails } from '../common/ReadOnlyDetails'
import { useState } from 'react'
import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon'
import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon'
import { PricingInformation } from '../common/PricingInformation'
import { Lato14Bold } from '@sequencehq/design-tokens'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'

export const PackagedPriceVariantPriceForm = () => {
  const { fieldsConfig, currency, priceDetails } = usePackagedPriceForm()
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)

  return (
    <>
      <Box marginBottom="16px">
        <ReadOnlyDetails>
          {priceDetails.map(({ label, value }) => (
            <ReadOnlyDetails.Item key={label} label={label} value={value} />
          ))}
        </ReadOnlyDetails>
      </Box>

      <SelectField
        label="List price"
        data-testid="common-field-listPriceId"
        value={fieldsConfig.listPriceId.value ?? ''}
        onChange={fieldsConfig.listPriceId.onChange}
        options={fieldsConfig.listPriceId.options}
        validationErrors={fieldsConfig.listPriceId.validationErrors}
        isDisabled={fieldsConfig.listPriceId.disabled}
        placeholder="Select list price"
      />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="standard-field-name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      {!fieldsConfig.currency.hidden && (
        <SelectField
          label="Currency"
          data-testid="standard-field-currency"
          value={fieldsConfig.currency.value}
          onChange={newCurrency =>
            fieldsConfig.currency.onChange(newCurrency as Currency)
          }
          options={fieldsConfig.currency.options}
          validationErrors={fieldsConfig.currency.validationErrors}
          isDisabled={fieldsConfig.currency.disabled}
          placeholder="Select currency"
        />
      )}

      <Box paddingBottom="8px">
        <PriceField
          label="Price per package"
          validationErrors={fieldsConfig.pricePerPackage.validationErrors}
          value={fieldsConfig.pricePerPackage.value}
          onChange={fieldsConfig.pricePerPackage.onChange}
          isDisabled={fieldsConfig.pricePerPackage.disabled}
          currency={currency}
        />
        <NumberField
          label="Package size"
          validationErrors={fieldsConfig.packageSize.validationErrors}
          precision={0}
          value={fieldsConfig.packageSize.value}
          onChange={fieldsConfig.packageSize.onChange}
          isDisabled={fieldsConfig.packageSize.disabled}
        />
      </Box>

      <ExternalLedgerForm />

      <Box
        display="flex"
        gap="4px"
        my="16px"
        padding="8px 0px"
        cursor="pointer"
        onClick={() => setShowAdditionalOptions(!showAdditionalOptions)}
      >
        <Text {...Lato14Bold} color="#5F5CFF">
          Show additional fields
        </Text>
        {showAdditionalOptions ? (
          <Icon as={ChevronDownIcon} color="#5F5CFF" w="20px" h="20px" />
        ) : (
          <Icon as={ChevronRightIcon} color="#5F5CFF" w="20px" h="20px" />
        )}
      </Box>
      <Box
        width="100%"
        data-testid="common-fields"
        marginBottom="16px"
        visibility={showAdditionalOptions ? 'visible' : 'hidden'}
      >
        <PricingInformation
          value={fieldsConfig.pricingModel.value}
          isDisabled={fieldsConfig.pricingModel.disabled}
          options={fieldsConfig.pricingModel.options}
          validationErrors={fieldsConfig.pricingModel.validationErrors}
          onChange={fieldsConfig.pricingModel.onChange}
          description={fieldsConfig.pricingModel.description}
        />

        <HorizontalSelectorField
          label="Frequency"
          data-testid="common-field-billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />

        <SelectFieldWithActions
          label="Usage Metric"
          options={fieldsConfig.usageMetricId.options}
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          value={fieldsConfig.usageMetricId.value}
          onChange={fieldsConfig.usageMetricId.onChange}
          isDisabled={fieldsConfig.usageMetricId.disabled}
          placeholder="Select usage metric"
          onAddNew={fieldsConfig.usageMetricId.onAddNew}
        />

        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>
      </Box>
    </>
  )
}
