import { useInvoiceEditorRoot } from 'InvoiceEditor/components/InvoiceEditorRoot/useInvoiceEditorRoot.ts'
import SpinnerContainer from 'components/Loading/SpinnerContainer.tsx'
import { InvoiceEditorProvider } from 'InvoiceEditor/hooks/useInvoiceEditorContext.ts'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { Header } from 'InvoiceEditor/components/Header/Header'
import { ConfirmSendInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmSendInvoiceModal.tsx'
import { ConfirmFinaliseInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmFinaliseInvoiceModal.tsx'
import { ConfirmRecalculateInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmRecalculateInvoiceModal.tsx'
import { ConfirmVoidInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmVoidInvoiceModal.tsx'
import { ConfirmSendAndFinaliseInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmSendAndFinaliseInvoiceModal.tsx'
import { ConfirmSendPaymentReminderModal } from 'InvoiceEditor/components/modals/ConfirmSendPaymentReminderModal.tsx'
import { Totals } from 'InvoiceEditor/components/Summary/Totals'
import { Integrations } from 'InvoiceEditor/components/Integrations/Integrations'
import { LineItems } from 'InvoiceEditor/components/LineItems/LineItems'
import { InvoiceEditorOutletProvider } from 'InvoiceEditor/InvoiceEditorOutletProvider'
import { PaymentDetails } from 'InvoiceEditor/components/drawers/PaymentDetails'
import { ConfirmCreateCreditNoteModal } from 'InvoiceEditor/components/modals/ConfirmCreateCreditNoteModal'
import { ConfirmSendTestInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmSendTestInvoiceModal/ConfirmSendTestInvoiceModal'
import { InvoiceEditorSummary } from 'InvoiceEditor/components/Summary/Summary'
import { CustomerIntegrationLinkModal } from 'InvoiceEditor/components/modals/CustomerIntegrationLinkModal/CustomerIntegrationLinkModal'
import { InvoicePdfPreview } from 'InvoiceEditor/components/drawers/InvoicePdfPreview/InvoicePdfPreview'
import NotFound from 'components/ErrorPages/NotFound'
import PageError from 'components/ErrorPages/PageError'
import { CreditNoteLineItemsModal } from 'InvoiceEditor/components/modals/CreditNoteLineItemsModal'
import { CustomerContactsRoot } from 'CustomerContacts/index'
import { CustomerContextProvider } from 'Customer/hooks/useCustomerContext'
import { EditCustomerForm } from 'Customer/drawers/EditCustomer/EditCustomerForm'
import { StandardLayout } from '@sequencehq/core-components'
import { InvoiceInspector } from '../Inspector/InvoiceInspector'
import { InvoiceIntegrationWarningBanners } from '../InvoiceIntegrationWarningBanners'

export const InvoiceEditorRoot = () => {
  const {
    context,
    loading,
    errors,
    confirmCreateCreditNoteModal,
    confirmSendInvoiceModal,
    confirmFinaliseInvoiceModal,
    confirmRecalculateInvoiceModal,
    confirmVoidInvoiceModal,
    confirmSendAndFinaliseInvoiceModal,
    confirmSendPaymentReminderModal,
    confirmSendTestInvoiceModal,
    creditNoteLineItemsModal,
    paymentDetailsDrawerState,
    editCustomerDrawerState,
    customerIntegrationLinkModal,
    invoicePdfPreviewDrawerState,
    reloadInvoice,
    showInspector
  } = useInvoiceEditorRoot()

  if (errors.invoice) {
    if ('status' in errors.invoice && errors.invoice.status === 404) {
      return <NotFound />
    } else {
      return <PageError />
    }
  }

  if (loading) {
    return <SpinnerContainer />
  }

  return (
    <InvoiceEditorProvider value={context}>
      <StandardLayout
        header={<Header />}
        content={
          <>
            <Grid
              gridTemplate={`"warnings warnings" auto
              "summary detail" / 350px 1fr`}
              padding="24px"
              width="100%"
              maxWidth="1537px"
              gridColumnGap="24px"
              mx="auto"
            >
              <GridItem gridArea="warnings">
                {showInspector && <InvoiceIntegrationWarningBanners />}
              </GridItem>
              <Flex
                flexDirection="column"
                gap="20px"
                width="100%"
                flexShrink="0"
                gridArea="summary"
              >
                <Totals />
                {!showInspector && <Integrations />}
              </Flex>
              <Flex
                gridArea="detail"
                width="100%"
                flexDirection="column"
                gap="24px"
              >
                <InvoiceEditorSummary />
                <LineItems />
              </Flex>
            </Grid>
            <InvoiceEditorOutletProvider />
            {confirmSendInvoiceModal.active && (
              <ConfirmSendInvoiceModal
                onClose={confirmSendInvoiceModal.onClose}
                onConfirm={confirmSendInvoiceModal.onConfirm}
              />
            )}
            {confirmFinaliseInvoiceModal.active && (
              <ConfirmFinaliseInvoiceModal
                onClose={confirmFinaliseInvoiceModal.onClose}
                onConfirm={confirmFinaliseInvoiceModal.onConfirm}
              />
            )}
            {confirmRecalculateInvoiceModal.active && (
              <ConfirmRecalculateInvoiceModal
                onClose={confirmRecalculateInvoiceModal.onClose}
                onConfirm={confirmRecalculateInvoiceModal.onConfirm}
              />
            )}
            {confirmVoidInvoiceModal.active && (
              <ConfirmVoidInvoiceModal
                onClose={confirmVoidInvoiceModal.onClose}
                onConfirm={confirmVoidInvoiceModal.onConfirm}
              />
            )}
            {confirmSendAndFinaliseInvoiceModal.active && (
              <ConfirmSendAndFinaliseInvoiceModal
                onClose={confirmSendAndFinaliseInvoiceModal.onClose}
                onConfirm={confirmSendAndFinaliseInvoiceModal.onConfirm}
              />
            )}
            {confirmSendPaymentReminderModal.active && (
              <ConfirmSendPaymentReminderModal
                onClose={confirmSendPaymentReminderModal.onClose}
                onConfirm={confirmSendPaymentReminderModal.onConfirm}
              />
            )}

            {confirmCreateCreditNoteModal.active && (
              <ConfirmCreateCreditNoteModal
                onClose={confirmCreateCreditNoteModal.onClose}
                onConfirm={confirmCreateCreditNoteModal.onConfirm}
              />
            )}

            {creditNoteLineItemsModal.active && (
              <CreditNoteLineItemsModal
                onClose={creditNoteLineItemsModal.onClose}
                onConfirm={creditNoteLineItemsModal.onConfirm}
              />
            )}

            {confirmSendTestInvoiceModal.active && (
              <ConfirmSendTestInvoiceModal
                onClose={confirmSendTestInvoiceModal.onClose}
                onConfirm={confirmSendTestInvoiceModal.onConfirm}
              />
            )}

            <PaymentDetails
              isOpen={paymentDetailsDrawerState.active}
              onClose={paymentDetailsDrawerState.onClose}
            />

            <InvoicePdfPreview
              isOpen={invoicePdfPreviewDrawerState.active}
              onClose={invoicePdfPreviewDrawerState.onClose}
            />

            {editCustomerDrawerState.active && (
              <CustomerContextProvider
                customerId={context.data.recipient.customerId}
              >
                <CustomerContactsRoot
                  customerId={context.data.recipient.customerId}
                >
                  <EditCustomerForm
                    onClose={editCustomerDrawerState.onClose}
                    onSuccess={() => void reloadInvoice()}
                  />
                </CustomerContactsRoot>
              </CustomerContextProvider>
            )}

            {customerIntegrationLinkModal.active &&
              customerIntegrationLinkModal.integrationService && (
                <CustomerIntegrationLinkModal
                  integrationService={
                    customerIntegrationLinkModal.integrationService
                  }
                  onClose={customerIntegrationLinkModal.onClose}
                  onConfirm={customerIntegrationLinkModal.onConfirm}
                />
              )}
          </>
        }
        inspector={showInspector ? <InvoiceInspector /> : null}
      />
    </InvoiceEditorProvider>
  )
}
