import { CubeProvider } from 'modules/Cube/communication/internal/cube.domain.context'
import { BillingScheduleLayout } from 'modules/Cube/view/layouts/billingSchedule/BillingScheduleLayout'
import SpinnerContainer from 'components/Loading/SpinnerContainer'
import { useCubeRoot } from 'modules/Cube/view/useCube.root'
import { Outlet } from 'react-router-dom'
import { QuoteEditorLayout } from 'modules/Cube/view/layouts/quote/QuoteEditorLayout'
import PageError from 'components/ErrorPages/PageError'
import { match } from 'ts-pattern'
import { CorePortErrors } from 'modules/Cube/domain/cube.domain.types'
import NotFound from 'components/ErrorPages/NotFound'

/**
 * The entry point for Cube!
 * ~A whoolleee newww wooorrrlllddd agaaaaiiinnn~
 * @returns
 */
export const CubeRoot = (props: { variant: 'schedule' | 'quote' }) => {
  const { editorContext, ready, error } = useCubeRoot({
    variant: props.variant
  })

  if (error) {
    return match(error)
      .with(CorePortErrors.NotFound, () => <NotFound />)
      .with(CorePortErrors.Other, () => <PageError />)
      .exhaustive()
  }

  if (!ready) {
    return <SpinnerContainer />
  }

  return (
    <CubeProvider value={editorContext}>
      {props.variant === 'schedule' && <BillingScheduleLayout />}
      {props.variant === 'quote' && <QuoteEditorLayout />}
      <Outlet context={editorContext} />
    </CubeProvider>
  )
}
