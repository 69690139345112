import {
  CorePortErrors,
  CubeReducerState
} from 'modules/Cube/domain/cube.domain.types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  CubeDomainInterface,
  useCubeDomain
} from 'modules/Cube/domain/cube.domain'
import { STANDARD_AVAILABLE_FREQUENCIES } from 'modules/Cube/domain/cube.constants'
import { useBillingSchedulePortImplementation } from 'modules/Cube/communication/external/billingSchedule.api.v1/usePorts.billingSchedule.api.v1'
import { match } from 'ts-pattern'
import { useQuotePorts } from 'modules/Cube/communication/external/quotes.api.v1/usePorts.quote.api.v1'

type UseCubeRoot = (props: { variant: 'schedule' | 'quote' }) => {
  editorContext: CubeDomainInterface
  ready: boolean
  error: CorePortErrors | null
}

enum LoadingStatus {
  UNINITIALIZED = 'UNINITIALIZED',
  RELOADING = 'RELOADING',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

/**
 * The top level hook used to initialize and 'stitch together' mechanisms like
 * loading and the editor itself.
 * @returns
 */
export const useCubeRoot: UseCubeRoot = props => {
  const { id } = useParams<{ id: string }>()
  const flags = useFlags()
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.UNINITIALIZED
  )
  const [corePortError, setCorePortError] = useState<CorePortErrors | null>(
    null
  )
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const defaultCustomerId =
    searchParams.get('customerId') ?? searchParams.get('sequenceCustomerId')
  const defaultSalesforceOpportunityId = searchParams.get(
    'salesforceOpportunityId'
  )

  const billingScheduleApiV1PortImplementation =
    useBillingSchedulePortImplementation({
      scheduleId: id === 'new' ? undefined : id
    })

  const quoteV1PortImplementation = useQuotePorts({
    quoteId: id === 'new' ? undefined : id
  })

  const {
    showBillingScheduleLabelField,
    showBillingScheduleReferenceField,
    showNewTaxManagement,
    enableAutoIssueInvoices,
    useInspector,
    useUnifiedPriceEditor
  } = flags

  /**
   * This is the base configuration - some aspects of the configuration may be
   * changed as part of the loading process. Notably features which are controlled
   * by whether a give customer has access a feature. To understand the final configration
   * state for a given port implementation, check the onLoad of that port.
   */
  const baseConfiguration: CubeReducerState['configuration'] = useMemo(() => {
    return {
      permissions: {},
      features: {
        showNewTaxManagement: showNewTaxManagement ?? false,
        stripeIntegrationAvailable: false,
        showReferenceField: showBillingScheduleReferenceField ?? false,
        showLabelField: showBillingScheduleLabelField ?? false,
        showAutoIssueInvoicesField: enableAutoIssueInvoices ?? false,
        useNewPricingEditor: useUnifiedPriceEditor ?? false,
        useInspector: useInspector ?? false
      },
      currency: {
        default: 'GBP',
        enabled: ['GBP']
      },
      availableFrequencies: STANDARD_AVAILABLE_FREQUENCIES
    }
  }, [
    showNewTaxManagement,
    showBillingScheduleReferenceField,
    showBillingScheduleLabelField,
    enableAutoIssueInvoices,
    useUnifiedPriceEditor,
    useInspector
  ])

  const portImplementation = useMemo(() => {
    return match(props.variant)
      .with('schedule', () =>
        billingScheduleApiV1PortImplementation({
          configuration: baseConfiguration,
          defaultValues: {
            customerId: defaultCustomerId ?? undefined
          }
        })
      )
      .with('quote', () =>
        quoteV1PortImplementation({
          configuration: baseConfiguration,
          defaultValues: {
            customerId: defaultCustomerId ?? undefined,
            salesforceOpportunityId: defaultSalesforceOpportunityId ?? undefined
          }
        })
      )
      .exhaustive()
  }, [
    baseConfiguration,
    billingScheduleApiV1PortImplementation,
    props.variant,
    quoteV1PortImplementation,
    defaultCustomerId,
    defaultSalesforceOpportunityId
  ])

  const cubeDomain = useCubeDomain({
    ports: portImplementation
  })

  const reloadDomain = cubeDomain.mutators.external.in.core

  /**
   * Control when to load/reload.
   */
  useEffect(() => {
    if (
      loadingStatus === LoadingStatus.LOADING ||
      loadingStatus === LoadingStatus.ERROR
    ) {
      return
    }

    if (
      (id === 'new' || !id) &&
      cubeDomain.queries.rawData.data.common.id !== 'uninitialized'
    ) {
      return
    }

    if (id === cubeDomain.queries.rawData.data.common.id) {
      return
    }

    if (
      id !== cubeDomain.queries.rawData.data.common.id &&
      cubeDomain.queries.rawData.data.common.id !== 'uninitialized' &&
      props.variant === 'quote'
    ) {
      navigate(`/quotes/${cubeDomain.queries.rawData.data.common.id}`)
      return
    }

    setLoadingStatus(
      loadingStatus === LoadingStatus.READY
        ? LoadingStatus.RELOADING
        : LoadingStatus.LOADING
    )

    void reloadDomain().then(({ data, error }) => {
      if (error || !data) {
        setCorePortError(error || CorePortErrors.Other)
        setLoadingStatus(LoadingStatus.ERROR)
        return
      }

      setLoadingStatus(LoadingStatus.READY)
    })
  }, [
    id,
    props.variant,
    searchParams,
    reloadDomain,
    cubeDomain.queries.rawData.data.common.id,
    navigate,
    loadingStatus
  ])

  useEffect(() => {
    /** Force a reload when the query params 'reset' is set to true */
    if (searchParams.get('reset') === 'true') {
      setLoadingStatus(LoadingStatus.LOADING)
      /**
       * Ensures that, if a reset has been set, that it is removed.
       */
      setSearchParams(
        defaultCustomerId ? { customerId: defaultCustomerId } : {}
      )

      void reloadDomain().then(({ data, error }) => {
        if (error || !data) {
          setCorePortError(error || CorePortErrors.Other)
          setLoadingStatus(LoadingStatus.ERROR)
          return
        }

        setLoadingStatus(LoadingStatus.READY)
      })
      return
    }
  }, [defaultCustomerId, setSearchParams, reloadDomain, searchParams])

  return {
    editorContext: cubeDomain,
    error:
      loadingStatus === LoadingStatus.ERROR
        ? corePortError || CorePortErrors.Other
        : null,
    ready:
      (loadingStatus === LoadingStatus.READY ||
        loadingStatus === LoadingStatus.RELOADING) &&
      cubeDomain.queries.rawData.editor.loaded
  }
}
