import { Box, Link } from '@chakra-ui/react'
import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dashboard/v99990101'
import { Currency } from '@sequencehq/api/utils'
import {
  InputSelectControlField,
  SimpleAsyncModal
} from '@sequencehq/core-components'
import {
  GreyGrey70,
  IndigoIndigo50,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { format } from '@sequencehq/utils/dates'
import { downloadBlob } from 'lib/downloadBlob'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useState } from 'react'

type TargetFormats = Dashboardv99990101Api.GetJournalReportDownload.CSVTarget

const CSV_FORMAT_OPTIONS: Array<{ label: string; value: TargetFormats }> = [
  {
    label: 'Sequence',
    value: 'SEQUENCE'
  },
  {
    label: 'Xero',
    value: 'XERO'
  },
  {
    label: 'QuickBooks',
    value: 'QUICKBOOKS'
  },
  {
    label: 'Netsuite',
    value: 'NETSUITE'
  }
]

const INITIAL_STATE = 'SEQUENCE'

export const ExportCSVModal = (props: {
  trigger: React.ReactNode
  from: Date
  to: Date
  currency: Currency
}) => {
  const [csvFormat, setCsvFormat] = useState<TargetFormats>(INITIAL_STATE)
  const { displayNotification } = useNotifications()

  return (
    <SimpleAsyncModal
      contentProps={{
        minHeight: '160px'
      }}
      title="Download CSV"
      trigger={props.trigger}
      closeOnSubmit
      cancelButtonText="Cancel"
      submitButtonText="Download CSV"
      submittingButtonText="Downloading..."
      data-testid="revrec.modal.exportCSV"
      onSubmit={async () => {
        try {
          const response =
            await dashboardv99990101Client.getJournalReportDownload({
              from: format(props.from, 'yyyy-MM-dd'),
              to: format(props.to, 'yyyy-MM-dd'),
              currency: props.currency,
              target: csvFormat
            })

          if (!response.data) {
            throw new Error('No data returned')
          }

          downloadBlob(
            `sequence_export_${csvFormat}_${props.currency}_${format(props.from, 'yyyy-MM-dd')}_${format(props.to, 'yyyy-MM-dd')}.csv`
          )(response.data)
          return true
        } catch (e) {
          displayNotification('Failed to download CSV', {
            type: 'error'
          })
          return false
        }
      }}
    >
      <InputSelectControlField
        data-testid="select.csvFormat"
        description={
          <Box {...Lato13Regular} color={GreyGrey70}>
            Choose which format you want to download.{' '}
            <Link
              href="https://docs.sequencehq.com/getting-started/overview"
              target="_blank"
              color={IndigoIndigo50}
            >
              Learn more
            </Link>
          </Box>
        }
        label="CSV format"
        initialValue={csvFormat}
        onChange={newValue => setCsvFormat(newValue as TargetFormats)}
        options={CSV_FORMAT_OPTIONS}
      />
    </SimpleAsyncModal>
  )
}
