import { useEffect, useState } from 'react'
import { isBlobOrObjectUrl } from 'lib/utils'

export const PdfPreview = ({ url }: { url: string }) => {
  const [objectURL, setObjectURL] = useState<string | null>(null)

  useEffect(() => {
    const fetchUrl = async () => {
      const res = await fetch(url)
      const blob = await res.blob()
      const pdfBlob = blob.slice(0, blob.size, 'application/pdf')
      setObjectURL(URL.createObjectURL(pdfBlob))
    }

    if (isBlobOrObjectUrl(url)) {
      setObjectURL(url)
    } else {
      void fetchUrl()
    }

    return () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL)
      }
    }
  }, [url])

  if (!objectURL) {
    return null
  }

  return (
    <iframe src={objectURL} style={{ width: '100%', height: '100%' }}></iframe>
  )
}
