import { CreateSeatSnapshotForm } from 'components/SeatSnapshots/CreateSnapshot/CreateSeatSnapshotForm'
import Sidebar from 'components/Sidebar'
import { useCallback } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { useSeatBalanceOutletContext } from 'components/SeatBalances/SeatBalanceOutlet'

export const CreateSeatSnapshotFormDrawer = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { functions } = useSeatBalanceOutletContext()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSuccess = useCallback(() => {
    setTimeout(() => {
      functions.updateResetKey()
    }, 2000)

    navigate(`..${search}`)
  }, [navigate, search, functions])

  const handleCancel = useCallback(() => {
    navigate('..')
  }, [navigate])

  return (
    <>
      <Sidebar>
        <CreateSeatSnapshotForm
          onSuccess={onSuccess}
          handleCancel={handleCancel}
        />
      </Sidebar>
      <Outlet />
    </>
  )
}
