import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { ComponentProps, useEffect, useState } from 'react'
import { useCustomer } from '../../../hooks/useCustomer'
import { TaxRatesModalUI } from 'Settings/view/taxRatesSettings/modals/TaxRatesModal'
import { CustomerContactsContent } from './CustomerContactsContent'
import { match } from 'ts-pattern'
import { useCreateTaxRate } from 'Settings/view/taxRatesSettings/hooks/useCreateTaxRate'
import { ValidationItem } from 'common/components/SteppedModal/useSteppedModal'
import { countriesAlpha2 } from '@sequencehq/api/dist/utils/commonEnums'
import { useQuery } from '@sequencehq/api/dist/utils'

const baseContactValidation = {
  stepName: 'Primary billing contact',
  stepKey: 'CONTACTS',
  component: CustomerContactsContent
}

const baseTaxRatesValidation = {
  stepKey: 'TAX_RATES',
  stepName: 'Tax rates',
  component: TaxRatesModalUI
}

const baseValidation = {
  contacts: baseContactValidation,
  taxRates: baseTaxRatesValidation
}

const getNextStep = (key: string, allKeys: string[]) =>
  match(key)
    .with('CONTACTS', () => 'CONFIRM')
    .with('TAX_RATES', () =>
      allKeys.includes('contacts') ? 'CONTACTS' : 'CONFIRM'
    )
    .otherwise(() => 'CONFIRM')

export const useSteppedConfirmStartModal = (props: { onClose: () => void }) => {
  const { onClose } = props
  const [validation, setValidation] = useState<ValidationItem[] | null>(null)
  const { address, contacts, id: customerId, taxStatus } = useCustomer()
  const { data: taxRatesData, isLoading: taxRatesLoading } = useQuery(
    dashboardv99990101Client.getTaxRates
  )
  const createTaxRate = useCreateTaxRate({ invalidateQueries: false })

  const taxRates = taxRatesData?.items ?? []

  const validData = {
    taxRates:
      taxStatus !== 'TAXED' ||
      (taxStatus === 'TAXED' &&
        taxRates.some(taxRate => taxRate.country === address.country)),
    contacts: Boolean(contacts?.length)
  }

  const validationItems: ValidationItem[] = Object.entries(validData).reduce(
    (acc, [key, value]) => {
      if (value === true) {
        return acc
      }

      const componentProps = match(key)
        .with(
          'contacts',
          (): ComponentProps<typeof CustomerContactsContent> => ({
            customerId,
            onClose: onClose,
            onSubmit: () => Promise.resolve()
          })
        )
        .with(
          'taxRates',
          (): ComponentProps<typeof TaxRatesModalUI> => ({
            taxRatesForCountry: taxRates.filter(
              ({ country }) => country === address.country
            ),
            preselectedCountry: address.country,
            onSubmit: async values => {
              await createTaxRate(values)
            },
            options: {
              closeOnCancel: false,
              closeOnSubmit: false,
              submitText: validData.contacts
                ? 'Save tax rates'
                : 'Save and continue',
              onCancel: onClose
            }
          })
        )
        .otherwise(() => ({}))

      const validationMessage = match(key as keyof typeof validData)
        .with('contacts', () => 'Primary billing contact')
        .with(
          'taxRates',
          () => `Tax rates for ${countriesAlpha2[address.country]}`
        )
        .exhaustive()

      return [
        ...acc,
        {
          ...baseValidation[key as keyof typeof baseValidation],
          message: validationMessage,
          nextStep: getNextStep(key, Object.keys(validData)),
          componentProps
        } as ValidationItem
      ]
    },
    [] as ValidationItem[]
  )

  useEffect(() => {
    if (!taxRatesLoading && validation === null) {
      setValidation(validationItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxRatesLoading, validationItems])

  return { loading: taxRatesLoading, validation }
}
