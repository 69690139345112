import { Box, Flex, Table, Td, Text, Tr } from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  GreyGrey90,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { currencyToSymbol } from '@sequencehq/core-models'
import { calculateYAxisLabels } from 'RevenueRecognition/view/utils/graphUtils'
import { formatTotal } from 'RevenueRecognition/view/utils/formatTotal'
import { Currency, useQuery } from '@sequencehq/api/dist/utils'
import { ProgressBar } from 'RevenueRecognition/view/common/ProgressBar'
import { Skeleton, XYChart } from '@sequencehq/core-components'
import { ChartTooltip } from 'RevenueRecognition/view/common/ChartTooltip'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import invariant from 'tiny-invariant'
import { format } from '@sequencehq/utils/dates'

/**
 * The tooltip displayed by the XYChart component when hovering over
 * different points in the chart.
 * @param date - the formatted date of the item
 * @param balances - the balances on the date
 * @param currency - the currency of the balances
 * @returns
 */
const GraphDataTooltip = ({
  date,
  balances,
  currency
}: {
  date: Date
  currency: Currency
  balances: {
    remaining: {
      absolute: number
      percentage: number
    }
    recognized: {
      absolute: number
      percentage: number
    }
  }
}) => {
  return (
    <ChartTooltip width="320px">
      <ChartTooltip.Header>
        <Text {...Lato13Bold} color={GreyGrey70}>
          {format(date, 'd MMM yyyy')}
        </Text>
      </ChartTooltip.Header>
      <ChartTooltip.Body padding="0 12px 4px">
        <Box width="100%">
          <Table variant="v2" width="100%" {...Lato13Regular}>
            <Tr borderBottom={`1px solid ${GreyGrey30}`}>
              <Td width="109px" color={GreyGrey80} padding={'8px 0'}>
                Remaining balance
              </Td>
              <Td
                color={GreyGrey60}
                width="106px"
                padding={'8px 0'}
                textAlign="right"
              >
                <Flex gap="8px" alignItems="center" justifyContent="flex-end">
                  {balances.remaining.percentage}%
                  <ProgressBar
                    width="56px"
                    percentage={balances.remaining.percentage}
                  />
                </Flex>
              </Td>
              <Td
                {...Lato13Bold}
                width="100%"
                color={GreyGrey90}
                padding={'8px 0'}
                textAlign="right"
              >
                {formatTotal({ value: balances.remaining.absolute, currency })}
              </Td>
            </Tr>
            <Tr>
              <Td color={GreyGrey80} padding={'8px 0'}>
                Recognized
              </Td>
              <Td color={GreyGrey60} padding={'8px 0'} textAlign="right">
                <Flex gap="8px" alignItems="center" justifyContent="flex-end">
                  {balances.recognized.percentage}%
                  <ProgressBar
                    width="56px"
                    percentage={balances.recognized.percentage}
                  />
                </Flex>
              </Td>
              <Td
                {...Lato13Bold}
                color={GreyGrey90}
                padding={'8px 0'}
                textAlign="right"
              >
                {formatTotal({ value: balances.recognized.absolute, currency })}
              </Td>
            </Tr>
          </Table>
        </Box>
      </ChartTooltip.Body>
    </ChartTooltip>
  )
}

interface ChartData {
  currency: Currency
  dateFrom: Date
  dateTo: Date
  dataSeries: Array<{
    date: string
    value: number
    balances: {
      recognized: {
        percentage: number
        absolute: number
      }
      remaining: {
        percentage: number
        absolute: number
      }
    }
  }>
}

type UseDefRevScheduleProgressGraph = (props: { scheduleId: string }) =>
  | {
      chartData: ChartData
      isLoading: false
    }
  | {
      chartData: null
      isLoading: true
    }

/**
 * Data acquisition for the graph
 * @param scheduleId - the schedule id
 * @returns
 */
export const useDefRevScheduleProgressGraph: UseDefRevScheduleProgressGraph =
  props => {
    const scheduleQuery = useQuery(
      dashboardv99990101Client.getDeferredRevenueSchedule,
      { id: props.scheduleId },
      {
        select: res => {
          if (!res) {
            return null
          }

          const dataSeries = res.progress.details.map(detail => ({
            date: detail.date,
            value: parseFloat(detail.balance),
            balances: {
              recognized: {
                percentage:
                  100 -
                  Math.round(
                    (parseFloat(detail.balance) /
                      parseFloat(res.progress.original)) *
                      100
                  ),
                absolute:
                  parseFloat(res.progress.original) - parseFloat(detail.balance)
              },
              remaining: {
                percentage: Math.round(
                  (parseFloat(detail.balance) /
                    parseFloat(res.progress.original)) *
                    100
                ),
                absolute: parseFloat(detail.balance)
              }
            }
          }))

          return {
            currency: res.progress.currency,
            dataSeries,
            dateFrom: new Date(dataSeries[0].date),
            dateTo: new Date(dataSeries[dataSeries.length - 1].date)
          }
        }
      }
    )

    /**
     * We throw this error to be caught by the appropriate error boundary.
     */
    if (scheduleQuery.error) {
      throw new Error('Deferred revenue chart data could not be loaded')
    }

    if (scheduleQuery.isPending) {
      return {
        isLoading: true,
        chartData: null
      }
    }

    invariant(scheduleQuery.data, 'Chart data should be defined, if loaded')

    return {
      isLoading: false,
      chartData: scheduleQuery.data
    }
  }

export const DefRevScheduleProgressGraph = (props: {
  defRevScheduleId: string
}) => {
  const { isLoading, chartData } = useDefRevScheduleProgressGraph({
    scheduleId: props.defRevScheduleId
  })

  const yAxisLabels = isLoading
    ? []
    : calculateYAxisLabels(chartData.dataSeries.map(d => d.value))

  return (
    <Box position="relative">
      <Box {...Lato13Bold} color={GreyGrey80} marginBottom="8px">
        Recognition Flow
      </Box>
      {isLoading ? (
        <Skeleton width="100%" height="292px" />
      ) : (
        <Box width="100%" height="292px">
          <XYChart<{
            date: string
            value: number
            balances: {
              remaining: {
                absolute: number
                percentage: number
              }
              recognized: {
                absolute: number
                percentage: number
              }
            }
          }>
            variant="line"
            dateFrom={format(chartData.dateFrom, 'yyyy-MM-dd')}
            dateTo={format(chartData.dateTo, 'yyyy-MM-dd')}
            data={chartData.dataSeries}
            dateFormat="d MMM yyyy"
            unit={currencyToSymbol[chartData?.currency]}
            animate={false}
            unitTickValues={yAxisLabels}
            renderTooltip={item => (
              <GraphDataTooltip
                {...item}
                currency={chartData?.currency}
                date={new Date(item.date)}
              />
            )}
          />
        </Box>
      )}
    </Box>
  )
}
