import { FC, memo } from 'react'
import EmptyState from 'components/Loading/EmptyState'
import { Flex } from '@chakra-ui/react'
import Page from 'components/Page'
import { Outlet } from 'react-router-dom'
import { UsageMetricProvider } from 'components/CreateUsageMetric'
import { UsageMetricsView } from 'components/UsageMetrics/types'
import {
  MagicTable,
  MagicTableCell,
  MagicTableCellAggregationType,
  MagicTableCellEmpty
} from '@sequencehq/tables'
import { TagTypes, useLazyGetUsageMetricsQuery } from 'features/api'
import { useSelector } from 'features/store'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { Badge } from '@sequencehq/core-components'
import { UsageMetricModel } from '@sequencehq/core-models'

const UsageMetrics: FC = memo(() => {
  const resetKey = useSelector(state => state.apiCaching.tags['UsageMetrics'])

  const emptyContent = {
    title: 'Create metrics to track usage',
    description:
      'Create metrics to track usage events in real time and unlock usage-based billing.',
    linkCopy: 'View example metrics.',
    docLink: `https://docs.sequencehq.com/metrics-events/examples`,
    route: '/usage-metrics/new',
    buttonCopy: 'Create metric'
  }

  return (
    <>
      <Flex flexDirection="column" flex={1}>
        <Flex position="sticky" top={0} height="100vh">
          <Page
            title="Metrics"
            addNewConfig={{
              buttonText: 'New usage metric',
              href: '/usage-metrics/new'
            }}
            paddingBottom={0}
          >
            <CurrentUserId>
              {userId => (
                <MagicTable<UsageMetricsView, TagTypes>
                  entityNamePlural="usage metrics"
                  resetKey={resetKey}
                  useLazyQuery={useLazyGetUsageMetricsQuery}
                  sequenceUserId={userId}
                  rowPath={row => `/usage-metrics/${row.id}`}
                  columns={[
                    {
                      id: 'id',
                      header: 'Metric ID',
                      accessorKey: 'id',
                      cell: row => (
                        <MagicTableCell
                          text={row.getValue<UsageMetricModel['id']>()}
                          showCopyButton={true}
                        />
                      )
                    },
                    {
                      id: 'name',
                      header: 'Name',
                      accessorKey: 'name',
                      cell: row => (
                        <MagicTableCell
                          text={row.getValue<UsageMetricModel['name']>()}
                        />
                      )
                    },
                    {
                      id: 'eventType',
                      header: 'Event type',
                      accessorKey: 'eventType',
                      cell: value => (
                        <MagicTableCellEmpty>
                          <Badge sentiment="monospaced" textTransform="none">
                            {value.getValue<UsageMetricModel['eventType']>()}
                          </Badge>
                        </MagicTableCellEmpty>
                      )
                    },
                    {
                      id: 'aggregationType',
                      header: 'Aggregation type',
                      accessorKey: 'aggregationType',
                      cell: row => (
                        <MagicTableCellAggregationType
                          aggregationType={row.getValue<
                            UsageMetricModel['aggregationType']
                          >()}
                        />
                      )
                    },
                    {
                      id: 'createdAt',
                      header: 'Created',
                      accessorKey: 'createdAt',
                      cell: row => (
                        <MagicTableCell
                          text={dateTimeWithFormat(
                            row.getValue<UsageMetricModel['createdAt']>(),
                            'd MMM yyyy'
                          )}
                        />
                      )
                    }
                  ]}
                  emptyState={props => (
                    <EmptyState emptyContent={emptyContent} {...props} />
                  )}
                />
              )}
            </CurrentUserId>
          </Page>
        </Flex>
      </Flex>
      <UsageMetricProvider>
        <Outlet />
      </UsageMetricProvider>
    </>
  )
})

export default UsageMetrics
