import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { useProductContext } from 'modules/Products/hooks/useProductContext'
import { useProductRoot } from 'modules/Products/hooks/useProductRoot'
import { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useQuery } from '@sequencehq/api/utils'
import { get } from 'lodash'
import { RevenueRecognitionMethod } from 'Products/types'

type Product = {
  name: string
  label?: string
  taxCategoryId?: string
  revenueRecognitionMethod?: RevenueRecognitionMethod
}

export const useEditProduct = () => {
  const flags = useFlags()

  const {
    functions: { editProduct }
  } = useProductRoot()
  const {
    data: { product: productDetails }
  } = useProductContext()
  const { data: taxCategoriesData } = useQuery(
    dashboard20240730Client.getTaxCategories
  )

  const initialValues = {
    label: productDetails.label,
    name: productDetails.name,
    taxCategoryId: productDetails.taxCategoryId,
    revenueRecognitionMethod: productDetails.revenueRecognitionMethod
  }

  const [product, setProduct] = useState<Product>()
  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const taxCategories = taxCategoriesData?.items ?? []

  const { fields, queries } = useForm<Product>({
    value: initialValues,
    showValidationErrors,
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required]
      },
      {
        property: 'label',
        validation: []
      },
      {
        property: 'taxCategoryId',
        disabled: () => taxCategories.length === 0,
        validation: flags.showNewTaxRatesSettings ? [required] : []
      },
      {
        property: 'revenueRecognitionMethod',
        disabled: () => !flags?.useRevRec,
        options: [
          {
            label: 'Milestone',
            value: 'MILESTONE'
          },
          {
            label: 'Straight line',
            value: 'STRAIGHT_LINE'
          },
          {
            label: 'Usage',
            value: 'USAGE'
          }
        ]
      }
    ],
    onChange: (newData: Product) => {
      setShowValidationErrors(false)
      setProduct(newData)
    }
  })

  const canUpdate = product !== undefined && queries.isValid

  const onEditProduct = () => {
    if (!queries.isValid) {
      setShowValidationErrors(true)
      return
    }

    if (!product) {
      return
    }

    return editProduct(productDetails.id, product)
  }

  const enhancedFields = {
    ...fields,
    taxCategoryId: {
      ...fields.taxCategoryId,
      hidden: !flags.showNewTaxRatesSettings,
      options: taxCategories.map(category => ({
        value: category.id,
        label: category.name
      }))
    }
  }

  /**
   * This is a bit 'more' than we need just to check if the tax category has changed,
   * but I wanted to write out a changeset util and toy with the idea of adding it to `useForm`.
   *
   * Given the form hook takes in the original values and spits out the field configuration (which tracks changes to those values),
   * I feel like it could make sense as something to expose on its `queries` object similarly to validation results.
   */
  const changeset:
    | Record<
        keyof Product,
        {
          oldValue: unknown
          newValue: unknown
        }
      >
    | Record<string, never> = Object.keys(queries.formData).reduce(
    (acc, fieldKey) => {
      const newValue = get(queries.formData, fieldKey)
      const oldValue = get(productDetails, fieldKey)
      if (oldValue !== newValue) {
        return {
          ...acc,
          [fieldKey]: {
            newValue,
            oldValue
          }
        }
      }

      return acc
    },
    {}
  )

  return {
    canUpdate,
    changeset,
    fieldsConfig: enhancedFields,
    functions: {
      editProduct: onEditProduct
    }
  }
}
