import { Dashboardv20240509Api } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { IntegrationServices } from '@sequencehq/api/dist/utils/commonEnums'
import {
  Currency,
  IntegrationLedgerAccount,
  PriceModel,
  PricingTier,
  ProductModel,
  ProrationStrategy,
  UsageMetricModel
} from '@sequencehq/core-models'
import { v1ApiCreateVariantPriceResponse } from 'common/drawers/PricingEditor/communication/external/v1/ports/variant.api.v1/entitySaving'
import { EditorMode as NewPriceEditorMode } from 'common/drawers/PricingEditor/domain'
import { ListPrice } from 'modules/Cube/domain/cube.domain.types'

export type PricingEditorInterfacePrice = Omit<
  Dashboardv20240509Api.GetPrice.Price,
  'createdAt'
>
export type PricingEditorListPriceProp = ListPrice
export type ApiPricingTier = PricingTier

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
    ? RecursivePartial<T[P]>
    : T[P]
}

export type ValidationError = {
  fieldName: string
  message: string
  metadata?: Record<string, unknown>
  value: unknown
}

export type UsageCalculationMode = 'BILLING_PERIOD' | 'CUMULATIVE' | 'PERIODIC'

export type UsageCalculationFrequency = 'MONTHLY' | 'QUARTERLY' | 'YEARLY'

export enum PricingEditorMode {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
  ADD_PRODUCT = 'addProduct'
}

export type AvailableStandardFrequency =
  | 'ONE_TIME'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'

export const availableBillingTypeOptions: {
  value: BillingType
  label: string
}[] = [
  { label: 'In arrears', value: 'IN_ARREARS' },
  { label: 'In advance', value: 'IN_ADVANCE' }
]

export type UsageTierType = 'FIXED' | 'PERCENTAGE'

export type LinearPriceType = 'FIXED' | 'PERCENTAGE'

export type PricingModel =
  | 'STANDARD'
  | 'LINEAR'
  | 'VOLUME'
  | 'GRADUATED'
  | 'PACKAGED'
  | 'SEAT_BASED_LINEAR'
  | 'SEAT_BASED_GRADUATED'

export type BillingType = 'IN_ADVANCE' | 'IN_ARREARS'

type BasePrice<PM extends PricingModel, S> = {
  id: PriceModel['id']
  common: {
    pricingModel: PM
    currency: PriceModel['currency']
    name: PriceModel['name']
    listPriceId?: string
    externalIds: Record<
      PricingEditorLedgerIntegration['id'],
      IntegrationLedgerAccount['code']
    >
  }
  modelSpecific: S
}

export type StandardPrice = BasePrice<
  'STANDARD',
  {
    price: string
    billingType: BillingType
    billingFrequency: AvailableStandardFrequency | 'ONE_TIME'
  }
>
export type LinearPrice = BasePrice<
  'LINEAR',
  {
    price: string
    percentage: string
    usageMetricId: UsageMetricModel['id']
    linearPriceType: LinearPriceType
    billingFrequency: AvailableStandardFrequency
    billingType: 'IN_ARREARS'
    minPrice: string
    maxPrice: string
    parameters: Record<string, string>
  }
>
export type GraduatedPrice = BasePrice<
  'GRADUATED',
  {
    usageMetricId: UsageMetricModel['id']
    tiers: PricingEditorTier[]
    percentageTiers: PricingEditorPercentageTier[]
    usageTierType: UsageTierType
    usageCalculationMode: UsageCalculationMode
    usageCalculationPeriod?: {
      frequency: UsageCalculationFrequency
      interval: number
    }
    billingFrequency: AvailableStandardFrequency
    billingType: 'IN_ARREARS'
    parameters: Record<string, string>
  }
>
export type VolumePrice = BasePrice<
  'VOLUME',
  {
    usageMetricId: UsageMetricModel['id']
    tiers: PricingEditorTier[]
    percentageTiers: PricingEditorPercentageTier[]
    usageTierType: UsageTierType
    billingFrequency: AvailableStandardFrequency
    billingType: 'IN_ARREARS'
    includePercentageLimits: boolean
    parameters: Record<string, string>
  }
>
export type PackagedPrice = BasePrice<
  'PACKAGED',
  {
    usageMetricId: UsageMetricModel['id']
    pricePerPackage: string
    packageSize: string
    billingFrequency: AvailableStandardFrequency
    billingType: 'IN_ARREARS'
    parameters: Record<string, string>
  }
>
export type SeatOveragesBillingFrequency = 'NEVER' | AvailableStandardFrequency

export type SeatBasedProrationStrategy = ProrationStrategy
export type SeatBasedLinearPrice = BasePrice<
  'SEAT_BASED_LINEAR',
  {
    seatTypeId: string
    pricePerSeat: string
    minimumSeats: string
    prorationStrategy: SeatBasedProrationStrategy
    overagesBillingFrequency?: SeatOveragesBillingFrequency
    billingFrequency: AvailableStandardFrequency
    billingType: BillingType
  }
>
export type SeatBasedGraduatedPrice = BasePrice<
  'SEAT_BASED_GRADUATED',
  {
    seatTypeId: string
    pricePerSeat: string
    minimumSeats: string
    prorationStrategy: SeatBasedProrationStrategy
    overagesBillingFrequency?: SeatOveragesBillingFrequency
    billingFrequency: AvailableStandardFrequency
    billingType: BillingType
    tiers: PricingEditorTier[]
  }
>
export type PricingEditorPrice =
  | StandardPrice
  | GraduatedPrice
  | VolumePrice
  | PackagedPrice
  | SeatBasedLinearPrice
  | SeatBasedGraduatedPrice
  | LinearPrice

export type PricingEditorCustomer = {
  id: string
  integrationIds: Array<{
    service: IntegrationServices
    id: string
  }>
}

export type PricingEditorInterfaceListPrice = ListPrice

export type PricingEditorInterfaceCustomer = PricingEditorCustomer
export type PricingEditorInterfaceProduct = PricingEditorProduct

export type NewPricingEditorProps = {
  mode: NewPriceEditorMode
  scheduleCurrency?: Currency
  existingData?: {
    price?: PricingEditorInterfacePrice
  }
  onSave: (newData: { price: v1ApiCreateVariantPriceResponse }) => void
}

export type PricingEditorProps = {
  onClose: () => void
  onSave: (newData: {
    price: PricingEditorInterfacePrice
    product: PricingEditorProduct
  }) => void
  customer?: PricingEditorCustomer
  mode: PricingEditorMode
  productId?: string
  existingData?: {
    price?: PricingEditorInterfacePrice
    product?: PricingEditorInterfaceProduct
    productPrices?: PricingEditorInterfacePrice[]
    listPrices?: PricingEditorInterfaceListPrice[]
  }
  scheduleCurrency?: Currency
  availableFrequencies?: AvailableStandardFrequency[]
  availableFields?: AvailablePricingEditorFields[]
  disabledFields?: AvailablePricingEditorFields[]
  pricingModelForNewPrice: PricingModel
  listPriceId?: string
  canUseListPrices: boolean
}

export type PricingEditorTier = {
  id: string
  firstUnit: string
  lastUnit: string
  unitPrice: string
  flatFee: string
}

export type PricingEditorPercentageTier = {
  id: string
  firstUnit: string
  lastUnit: string
  maxPrice: string
  minPrice: string
  unitPercentage: string
  flatFee: string
}

export type PricingEditorFormData = {
  common: {
    name: string
    pricingModel: PricingModel
    currency: Currency
    listPriceId: string
    externalIds: Record<
      PricingEditorLedgerIntegration['id'],
      IntegrationLedgerAccount['code']
    >
  }
  STANDARD: StandardPrice['modelSpecific']
  LINEAR: LinearPrice['modelSpecific']
  VOLUME: VolumePrice['modelSpecific']
  GRADUATED: GraduatedPrice['modelSpecific']
  PACKAGED: PackagedPrice['modelSpecific']
  SEAT_BASED_LINEAR: SeatBasedLinearPrice['modelSpecific']
  SEAT_BASED_GRADUATED: SeatBasedGraduatedPrice['modelSpecific']
}

/**
 * For now, we only allow for name to be manually enabled in
 * edit mode, but we can extend it later!
 */
export type AvailablePricingEditorFields =
  | 'common.name'
  | 'common.pricingModel'
  | 'LINEAR.billingFrequency'
  | 'LINEAR.billingType'
  | 'LINEAR.linearPriceType'
  | 'LINEAR.usageMetricId'
  | 'GRADUATED.billingFrequency'
  | 'GRADUATED.billingType'
  | 'GRADUATED.usageTierType'
  | 'GRADUATED.usageCalculationMode'
  | 'GRADUATED.usageMetricId'
  | 'VOLUME.billingFrequency'
  | 'VOLUME.billingType'
  | 'VOLUME.usageTierType'
  | 'VOLUME.usageMetricId'
  | 'PACKAGED.billingFrequency'
  | 'PACKAGED.usageMetricId'
  | 'STANDARD.billingFrequency'
  | 'STANDARD.billingType'
  | 'STANDARD.price'
  | 'LINEAR.price'
  | 'LINEAR.percentage'
  | 'GRADUATED.percentageTiers'
  | 'VOLUME.percentageTiers'
  | 'GRADUATED.tiers'
  | 'VOLUME.tiers'
  | 'PACKAGED.pricePerPackage'
  | 'PACKAGED.packageSize'
  | 'SEAT_BASED_LINEAR.billingType'
  | 'SEAT_BASED_LINEAR.billingFrequency'
  | 'SEAT_BASED_LINEAR.overagesBillingFrequency'
  | 'SEAT_BASED_GRADUATED.billingType'
  | 'SEAT_BASED_GRADUATED.billingFrequency'
  | 'SEAT_BASED_GRADUATED.overagesBillingFrequency'
  | 'SEAT_BASED_GRADUATED.tiers'

export type PricingEditorLedgerIntegration = {
  id: string
  defaultLedgerAccount?: string
  options: {
    id: string
    name: string
    code: IntegrationLedgerAccount['code']
  }[]
}
export type PricingEditorProduct = Pick<ProductModel, 'id' | 'name' | 'label'>

export type PricingEditorReducerState = {
  data: {
    prices: Record<PricingEditorPrice['id'], PricingEditorPrice>
    listPrices: Record<PricingEditorPrice['id'], PricingEditorPrice>
    metrics: Record<UsageMetricModel['id'], UsageMetricModel>
    product: PricingEditorProduct
    formData: PricingEditorFormData
    ledgerIntegrations: Record<
      PricingEditorLedgerIntegration['id'],
      PricingEditorLedgerIntegration
    >
    defaultPricingType: PricingModel
  }
  derived: {
    queries: {
      selectedPrice: PricingEditorPrice | null
      canAddPrice: boolean
      apiFormatPrices: PricingEditorInterfacePrice[]
      existingPrices: Record<PricingEditorPrice['id'], PricingEditorPrice>
      newPrices: Record<PricingEditorPrice['id'], PricingEditorPrice>
      uniquePrices: Record<PricingEditorPrice['id'], PricingEditorPrice>
      availableFeatures: {
        changeListPrice: boolean
        save: boolean
        showAllPriceFields: boolean
        showPriceList: boolean
        showProductFields: boolean
      }
      priceHasBeenUpdated: boolean
      productHasBeenUpdated: boolean
      canSave: boolean
    }
  }
  configuration: {
    currency?: Currency
    enableListPrices: boolean
    availableCurrencies: Currency[]
    availableStandardFrequencies: AvailableStandardFrequency[]
    mode: PricingEditorMode
    availableFields?: string[]
    disabledFields?: string[]
    listPriceId?: string
  }
  editor: {
    formsValid: Partial<
      Record<
        keyof PricingEditorFormData | 'product' | 'externalLedger',
        boolean
      >
    >
    loaded: boolean
    selectedPrice: PricingEditorPrice['id'] | null
    showValidationErrors: boolean
  }
  initialData: {
    price: PricingEditorPrice | null
    product: PricingEditorProduct
  }
}

export type Action<T extends string, P> = {
  type: T
  payload: P
}

export type LoadPricingEditorAction = Action<
  'loadPricingEditor',
  Pick<PricingEditorReducerState, 'data' | 'configuration' | 'editor'>
>

export type UpdateEditor = Action<
  'updateEditor',
  RecursivePartial<PricingEditorReducerState['editor']>
>

export type UpdateData = Action<
  'updateData',
  RecursivePartial<PricingEditorReducerState['data']>
>

export type AddPrice = Action<
  'addPrice',
  RecursivePartial<Omit<PricingEditorPrice, 'id'>> | undefined
>

export type RemovePrice = Action<'removePrice', PricingEditorPrice['id']>

export type PricingEditorActions =
  | LoadPricingEditorAction
  | UpdateEditor
  | UpdateData
  | AddPrice
  | RemovePrice

export type ActionHandler<A extends PricingEditorActions> = (
  prevState: PricingEditorReducerState
) => (action: A) => PricingEditorReducerState

export type PostActionContext = {
  preActionState: PricingEditorReducerState
  action: PricingEditorActions
}
export type PostActionStage = (
  ctx: PostActionContext
) => (prevState: PricingEditorReducerState) => PricingEditorReducerState
