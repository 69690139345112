import { IntegrationConfig } from 'modules/Integrations/domain'
import logo from './goCardlessLogo.svg'
import { NoConfiguration } from 'modules/Integrations/integrationsConfig/common/NoConfiguration'
import { GoCardlessIntegrationDetail } from './GoCardlessIntegrationDetail'

export const goCardlessIntegrationConfig: IntegrationConfig = {
  logoUrl: logo,
  smallLogoUrl: logo,
  documentationUrl: '',
  title: 'GoCardless',
  features: {
    canDeleteLinks: false,
    canUninstall: false,
    isDocumentationOnly: false
  },
  description:
    'Set up direct debits, collect payments from customers and reconcile Sequence invoices automatically.',
  components: {
    detail: GoCardlessIntegrationDetail,
    management: NoConfiguration
  }
}
