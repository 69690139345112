import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/dist/utils'
import invariant from 'tiny-invariant'
import {
  AggregatedCreditNote,
  AggregatedLineItemGroup,
  LineItem,
  LineItemGroup,
  ServerLineItem
} from 'CreditNotes/types'
import { mergeLineItemsIntoGroups } from 'CreditNotes/utils/lineItemGroup'

export const useAggregatedCreditNote = (
  creditNoteId: string
): { isLoading: boolean; creditNote: AggregatedCreditNote | null } => {
  invariant(creditNoteId, 'Credit note ID is required')

  const creditNoteQuery = useQuery(dashboardv99990101Client.getCreditNote, {
    id: creditNoteId
  })

  const lineItemsQuery = useQuery(
    dashboardv99990101Client.getCreditNoteLineItems,
    {
      creditNoteId: creditNoteId
    }
  )

  const groupsQuery = useQuery(
    dashboardv99990101Client.getCreditNoteLineItemGroups,
    {
      creditNoteId: creditNoteId
    }
  )

  if (!creditNoteId) {
    return {
      creditNote: null,
      isLoading: false
    }
  }

  if (
    creditNoteQuery.isLoading ||
    lineItemsQuery.isLoading ||
    groupsQuery.isLoading
  ) {
    return {
      creditNote: null,
      isLoading: true
    }
  }

  if (!creditNoteQuery.data || !lineItemsQuery.data || !groupsQuery.data) {
    return {
      creditNote: null,
      isLoading: false
    }
  }

  return {
    creditNote: {
      ...creditNoteQuery.data,
      lineItemGroups: mergeLineItemsIntoGroups<
        LineItemGroup,
        LineItem & { groupId: string },
        AggregatedLineItemGroup
      >(
        filterInvalidLineItems(lineItemsQuery.data.items.map(toClientLineItem)),
        groupsQuery.data.items
      )
    },
    isLoading: creditNoteQuery.isLoading || lineItemsQuery.isLoading
  }
}

const filterInvalidLineItems = (lineItems: LineItem[]) => {
  const filtered = lineItems.filter(
    lineItem => lineItem.groupId !== null
  ) as (LineItem & { groupId: string })[]
  if (filtered.length !== lineItems.length) {
    console.error('filterInvalidLineItems: Invalid line items found')
  }
  return filtered
}

function toClientLineItem(lineItem: ServerLineItem): LineItem {
  return { ...lineItem, selected: true }
}
