import { useState, useEffect, useCallback, useRef } from 'react'
import { dequal } from 'dequal'

export type LoadingState = 'LOADING' | 'RELOADING' | 'READY'

/**
 * @deprecated use `useQuery` from `@sequencehq/api/utils` instead
 */
export function useQuery<TData, TError, TPayload>(
  queryFn: (payload?: TPayload, signal?: AbortSignal) => Promise<TData>,
  {
    payload,
    options
  }: {
    payload?: TPayload
    options?: {
      onSuccess?: (data: TData) => void
      onError?: (error: TError) => void
      enabled?: boolean
    }
  } = {}
) {
  const [data, setData] = useState<TData | null>(null)
  const [loadingState, setLoadingState] = useState<LoadingState>('READY')
  const [error, setError] = useState<TError | null>(null)

  const optionsRef = useRef(options)
  const payloadRef = useRef(payload)
  const previousPayloadRef = useRef<TPayload | undefined>()

  useEffect(() => {
    optionsRef.current = options
    payloadRef.current = payload
  }, [options, payload])

  const fetchData = useCallback(
    async (signal: AbortSignal, forceRefetch = false) => {
      if (signal.aborted) {
        return
      }

      if (
        !forceRefetch &&
        payloadRef.current !== undefined &&
        dequal(payloadRef.current, previousPayloadRef.current)
      ) {
        return
      }

      if (forceRefetch || data !== null || error !== null) {
        setLoadingState('RELOADING')
      } else {
        setLoadingState('LOADING')
      }

      setError(null)

      try {
        const result = await queryFn(payloadRef.current, signal)
        if (!signal.aborted) {
          setData(result)
          optionsRef.current?.onSuccess?.(result)
        }
      } catch (err) {
        if (!signal.aborted) {
          setError(err as TError)
          optionsRef.current?.onError?.(err as TError)
        }
      } finally {
        if (!signal.aborted) {
          setLoadingState('READY')
          previousPayloadRef.current = payloadRef.current
        }
      }
    },
    [queryFn]
  )

  useEffect(() => {
    if (optionsRef.current?.enabled === false) {
      return
    }

    const controller = new AbortController()
    void fetchData(controller.signal)

    return () => {
      setLoadingState('READY')
      controller.abort()
    }
  }, [fetchData, payload])

  const refetch = useCallback(() => {
    const controller = new AbortController()
    return fetchData(controller.signal, true)
  }, [fetchData])

  return {
    data,
    loading: loadingState !== 'READY',
    loadingState,
    error,
    refetch
  }
}
