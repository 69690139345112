import {
  Button,
  Card,
  TextInputField,
  Banner
} from '@sequencehq/core-components'
import { Box, Flex } from '@chakra-ui/react'
import { GreyWhite, Lato14Bold } from '@sequencehq/design-tokens'
import { Label } from '@sequencehq/forms'
import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import Spinner from 'components/Loading/Spinner'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useMutation } from '@sequencehq/api/utils'
import { AvalaraCompanies } from 'Integrations/domain'
import { useEffect } from 'react'

type FormValues = {
  accountId: string | undefined
  licenseKey: string | undefined
}

interface Props {
  existingCredentials: FormValues
  onConnectionSuccess: (
    {
      accountId,
      licenseKey
    }: {
      accountId: string
      licenseKey: string
    },
    avalaraCompanies: AvalaraCompanies
  ) => void
  onCredentialsChange: (credentials: FormValues) => void
}

const AvalaraAuthenticationCard = ({
  existingCredentials,
  onConnectionSuccess,
  onCredentialsChange
}: Props) => {
  const isFirstTimeSetup =
    !existingCredentials.accountId && !existingCredentials.licenseKey

  const { fields, queries } = useForm({
    value: existingCredentials,
    fieldConfiguration: [
      {
        property: 'accountId',
        validation: [required]
      },
      {
        property: 'licenseKey',
        validation: [required]
      }
    ],
    onChange: onCredentialsChange
  })

  const wereCredentialsChanged = queries.isDirty

  const {
    isError,
    isSuccess,
    isPending: isTestingConnection,
    reset: resetTestConnection,
    mutate: testConnection
  } = useMutation(dashboardv99990101Client.postTestAvalaraCredentials, {
    onSuccess: result => {
      const avalaraCompanies = result?.items ?? []
      if (fields.accountId.value && fields.licenseKey.value) {
        // We want to pass the values to the parent component when the connection test is successful
        onConnectionSuccess(
          {
            accountId: fields.accountId.value,
            licenseKey: fields.licenseKey.value
          },
          avalaraCompanies
        )
      }
    }
  })

  useEffect(() => {
    if (!isFirstTimeSetup) {
      resetTestConnection()
    }
  }, [isFirstTimeSetup, resetTestConnection])

  return (
    <Card>
      <Flex flexDirection="column" gap={4}>
        <Box {...Lato14Bold}>Avalara authentication</Box>
        <Flex gap={2}>
          <Box>
            <Label>Account ID</Label>
            <TextInputField
              data-testid="avalaraAuth.accountId"
              value={fields.accountId.value}
              onChange={event => {
                if (isSuccess || isError) {
                  resetTestConnection()
                }
                fields.accountId.onChange(event?.target?.value)
              }}
              validationErrors={fields.accountId.validationErrors}
              placeholder="bob@example.com"
              styles={{
                wrapper: {
                  marginBottom: 0
                }
              }}
              width="256px"
            />
          </Box>
          <Box>
            <Label>License key</Label>
            <Flex gap={2}>
              <TextInputField
                data-testid="avalaraAuth.licenseKey"
                value={fields.licenseKey.value}
                onChange={event => {
                  if (isSuccess || isError) {
                    resetTestConnection()
                  }
                  fields.licenseKey.onChange(event?.target?.value)
                }}
                validationErrors={fields.licenseKey.validationErrors}
                placeholder="XXXXXX-XXXXXX-XXXXXX-XXXXXX"
                styles={{
                  wrapper: {
                    marginBottom: 0
                  }
                }}
                width="278px"
              />
              {!isSuccess && wereCredentialsChanged ? (
                <Button
                  data-testid="avalaraAuth.testConnection"
                  onClick={() => {
                    if (fields.accountId.value && fields.licenseKey.value) {
                      void testConnection({
                        accountId: fields.accountId.value,
                        licenseKey: fields.licenseKey.value
                      })
                    }
                  }}
                  disabled={isTestingConnection || !queries.isValid}
                  leadingIcon={
                    isTestingConnection ? (
                      <Spinner color={GreyWhite} height="16px" width="16px" />
                    ) : undefined
                  }
                >
                  {isTestingConnection ? 'Checking...' : 'Test connection'}
                </Button>
              ) : null}
            </Flex>
          </Box>
        </Flex>
        {isSuccess && wereCredentialsChanged && (
          <Banner data-testid="avalaraAuth.banner.success" variant="success">
            Connection successful
          </Banner>
        )}

        {isError && (
          <Banner data-testid="avalaraAuth.banner.error" variant="error">
            Connection failed
          </Banner>
        )}
      </Flex>
    </Card>
  )
}

export default AvalaraAuthenticationCard
