import { Flex } from '@chakra-ui/react'
import { ReactQuoteRenderer } from '@sequencehq/quote-content'

import { QuoteEditorPreviewProps } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/QuoteEditorPreview.types'
import { useQuoteEditorPreviewRoot } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/useQuoteEditorPreviewRoot'
import { Header } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/widgets/Header'

export const QuoteEditorPreviewRoot = (props: QuoteEditorPreviewProps) => {
  const rootHook = useQuoteEditorPreviewRoot(props)

  return (
    <Flex
      position="absolute"
      top="0"
      left="191px"
      background="white"
      width="calc(100vw - 191px)"
      height="calc(100vh)"
      zIndex="110"
      flexDirection="column"
    >
      <Header hook={rootHook} />
      <Flex overflowY="scroll" flex="1">
        <Flex width="100%" height="fit-content">
          <ReactQuoteRenderer data={rootHook.reactRendererData} />
        </Flex>
      </Flex>
    </Flex>
  )
}
