import type {
  Currency,
  InvoiceMerchantDetailsModel,
  InvoiceModel,
  InvoiceTotalsModel,
  MerchantModel,
  UsageDataResponseModel,
  AddressModel,
  State,
  Country,
  TaxStatus,
  InvoicePaymentOption,
  KeyValuePairModel,
  InvoiceUsageItemGroup
} from '@sequencehq/core-models'
import {
  ApiCreateLineItem,
  ApiCreateLineItemGroup,
  ApiLineItem,
  ApiLineItemGroup,
  ApiUpdateLineItem,
  ApiUpdateLineItemGroup,
  InvoiceEditorLineItem,
  InvoiceEditorLineItemGroup,
  InvoiceEditorReducerState,
  InvoiceEditorSubAccountUsageBreakdown
} from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  formatAddressForInvoice,
  getPaymentAccountDetails
} from '@sequencehq/invoice-content'
import { arrayToIdKeyedObject, toMoney } from '@sequencehq/utils'
import type { Price } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/hooks/useLoadPrices'
import { Customer } from 'InvoiceEditor/hooks/useLoadCustomer'
import { Address } from '@sequencehq/api/utils/commonModels'

type ApiData = {
  invoice: InvoiceModel
  invoiceMerchantDetails?: InvoiceMerchantDetailsModel
  lineItemsAndGroups?: {
    lineItems: ApiLineItem[]
    lineItemGroups: ApiLineItemGroup[]
    lineItemGroupUsage: (UsageDataResponseModel & { lineItemGroupId: string })[]
  }
  merchant?: MerchantModel
  customer?: Customer
  subAccountUsageBreakdown: InvoiceUsageItemGroup[]
  prices?: Price[]
}

type InvoiceEditorApiResponseToFields = ({
  invoice,
  invoiceMerchantDetails,
  lineItems,
  lineItemGroups,
  lineItemGroupUsage,
  merchant,
  customer,
  subAccountUsageBreakdown,
  prices
}: {
  invoice: InvoiceModel
  invoiceMerchantDetails?: InvoiceMerchantDetailsModel
  lineItems: ApiLineItem[]
  lineItemGroups: ApiLineItemGroup[]
  lineItemGroupUsage: (UsageDataResponseModel & { lineItemGroupId: string })[]
  merchant?: MerchantModel
  customer?: Customer
  subAccountUsageBreakdown: InvoiceUsageItemGroup[]
  prices: Price[]
}) => {
  invoice: InvoiceEditorReducerState['data']['invoice']
  merchant: InvoiceEditorReducerState['data']['merchant']
  recipient: InvoiceEditorReducerState['data']['recipient']
  lineItems: InvoiceEditorReducerState['data']['lineItems']
  lineItemGroups: InvoiceEditorReducerState['data']['lineItemGroups']
  lineItemGroupUsage: InvoiceEditorReducerState['data']['lineItemGroupUsage']
  totals: InvoiceEditorReducerState['data']['totals']
  customer: InvoiceEditorReducerState['data']['customer']
  subAccountUsageBreakdown: InvoiceEditorReducerState['data']['subAccountUsageBreakdown']
  prices: InvoiceEditorReducerState['data']['prices']
}

export const formatTotal = (
  currency: Currency,
  total: string,
  maximumFractionDigits: number | undefined = undefined
) => toMoney({ value: total, currency }, '', maximumFractionDigits)

export const createSubAccountUsageBreakdownFromApiResponse = (
  apiSubAccountUsageBreakdown: InvoiceUsageItemGroup[]
): InvoiceEditorSubAccountUsageBreakdown[] => {
  const subAccountUsageBreakdown = apiSubAccountUsageBreakdown.reduce(
    (acc, apiUsageDataForMetric) => {
      return apiUsageDataForMetric.items.reduce(
        (innerAcc, usageForSubAccount) => {
          /**
           * If the metric is zero, do not include it.
           */
          if (usageForSubAccount.value === '0' || !usageForSubAccount.value) {
            return innerAcc
          }

          return {
            ...innerAcc,
            [usageForSubAccount.title]: {
              subAccountLabel: usageForSubAccount.title,
              usageData: [
                ...(innerAcc[usageForSubAccount.title]?.usageData ?? []),
                {
                  lineItemGroupId: usageForSubAccount.lineItemGroupId,
                  label: apiUsageDataForMetric.title,
                  quantity: usageForSubAccount.value
                }
              ]
            }
          }
        },
        acc as Record<string, InvoiceEditorSubAccountUsageBreakdown>
      )
    },
    {}
  )

  return Object.values(subAccountUsageBreakdown)
}

const invoiceDataApiResponseToFields: InvoiceEditorApiResponseToFields = ({
  invoice,
  invoiceMerchantDetails,
  lineItemGroups,
  lineItems,
  lineItemGroupUsage,
  merchant,
  customer,
  subAccountUsageBreakdown,
  prices
}) => {
  return {
    invoice: {
      id: invoice.id,
      memo: invoice.memo,
      invoiceNumber: invoice.invoiceNumber,
      status: invoice.status,
      paymentStatus: invoice.paymentStatus,
      dueDate: invoice.dueDate,
      billingScheduleId: invoice.billingScheduleId,
      currency: invoice.currency,
      billingPeriod: invoice.billingPeriod
        ? `${dateTimeWithFormat(
            invoice.billingPeriod.start,
            'd MMM'
          )} to ${dateTimeWithFormat(
            invoice.billingPeriod.endInclusive,
            'd MMM yyyy'
          )}`
        : null,
      billingPeriodStart: invoice.billingPeriod?.start,
      billingPeriodEndInclusive: invoice.billingPeriod?.endInclusive,
      reference: invoice.reference,
      purchaseOrderNumber: invoice.purchaseOrderNumber,
      linkedServices: invoice.linkedServices,
      customerId: invoice.customerId,
      isReady: true,
      accountingDate: invoice.accountingDate,
      customerTaxStatus: invoice.customerTaxStatus,
      paymentOptions: invoice.paymentOptions ?? [],
      metadata: invoice.metadata ?? [],
      creditBalances: invoice.creditBalances,
      calculatedAt: invoice.calculatedAt,
      renderSettings: invoice.renderSettings
    },
    merchant: {
      merchantName: invoiceMerchantDetails?.legalCompanyName,
      primaryBrandColor: invoiceMerchantDetails?.primaryColour,
      defaultDueDateDays: merchant?.defaultDueDateDays,
      accountDetails: invoiceMerchantDetails
        ? getPaymentAccountDetails(invoiceMerchantDetails)
        : []
    },
    recipient: {
      customerLegalName: invoice.customerLegalCompanyName,
      customerEmails: invoice.customerEmails,
      customerId: invoice.customerId,
      customerAddressFields: formatAddressForInvoice(
        invoice.customerBillingAddress
      ),
      isArchived: !!customer?.archivedAt
    },
    totals: {
      netTotal: formatTotal(invoice.currency, invoice.netTotal),
      totalTax: formatTotal(invoice.currency, invoice.totalTax),
      grossTotal: formatTotal(invoice.currency, invoice.grossTotal),
      taxLabel: 'Tax'
    },
    lineItems: arrayToIdKeyedObject(lineItems),
    lineItemGroups: arrayToIdKeyedObject(lineItemGroups),
    lineItemGroupUsage,
    customer: {
      companyName: customer?.legalName,
      contacts: customer?.contacts ?? [],
      label: customer?.label,
      address: customer?.address,
      taxStatus: customer?.taxStatus,
      aliases: [],
      integrationIds:
        customer?.integrationIds.map(({ service, id, url }) => ({
          service,
          id,
          url
        })) ?? []
    },
    subAccountUsageBreakdown: createSubAccountUsageBreakdownFromApiResponse(
      subAccountUsageBreakdown
    ),
    prices: arrayToIdKeyedObject(prices)
  }
}

type InvoiceApiResponseToInvoiceFields = (
  invoice: InvoiceModel
) => InvoiceEditorReducerState['data']['invoice']

const invoiceApiResponseToInvoiceFields: InvoiceApiResponseToInvoiceFields =
  invoice => {
    return {
      id: invoice.id,
      memo: invoice.memo,
      invoiceNumber: invoice.invoiceNumber,
      total: formatTotal(invoice.currency, invoice.grossTotal),
      status: invoice.status,
      paymentStatus: invoice.paymentStatus,
      dueDate: invoice.dueDate,
      billingScheduleId: invoice.billingScheduleId,
      currency: invoice.currency,
      billingPeriod: invoice.billingPeriod
        ? `${dateTimeWithFormat(
            invoice.billingPeriod.start,
            'd MMM'
          )} to ${dateTimeWithFormat(
            invoice.billingPeriod.endInclusive,
            'd MMM yyyy'
          )}`
        : null,
      billingPeriodStart: invoice.billingPeriod?.start,
      billingPeriodEndInclusive: invoice.billingPeriod?.endInclusive,
      purchaseOrderNumber: invoice.purchaseOrderNumber,
      reference: invoice.reference,
      linkedServices: invoice.linkedServices,
      customerId: invoice.customerId,
      isReady: true,
      accountingDate: invoice.accountingDate,
      customerTaxStatus: invoice.customerTaxStatus,
      paymentOptions: invoice.paymentOptions ?? [],
      metadata: invoice.metadata ?? [],
      creditBalances: invoice.creditBalances,
      calculatedAt: invoice.calculatedAt,
      renderSettings: invoice.renderSettings
    }
  }

const createAdapterDataFromApiResponse = (invoiceData: ApiData) =>
  invoiceDataApiResponseToFields({
    invoice: invoiceData.invoice,
    invoiceMerchantDetails: invoiceData.invoiceMerchantDetails,
    lineItems: invoiceData.lineItemsAndGroups?.lineItems ?? [],
    lineItemGroups: invoiceData.lineItemsAndGroups?.lineItemGroups ?? [],
    lineItemGroupUsage:
      invoiceData.lineItemsAndGroups?.lineItemGroupUsage ?? [],
    merchant: invoiceData.merchant,
    customer: invoiceData.customer,
    subAccountUsageBreakdown: invoiceData.subAccountUsageBreakdown,
    prices: invoiceData.prices ?? []
  })

const createAdapterInvoiceDataFromApiResponse = (invoice: ApiData['invoice']) =>
  invoiceApiResponseToInvoiceFields(invoice)

type TotalsApiResponseToTotalsFields = (
  totals: InvoiceTotalsModel['total'],
  currency: Currency
) => InvoiceEditorReducerState['data']['totals']

const createAdapterTotalsDataFromApiResponse: TotalsApiResponseToTotalsFields =
  (totals, currency) => ({
    netTotal: formatTotal(currency, totals.netTotal),
    totalTax: formatTotal(currency, totals.totalTax),
    grossTotal: formatTotal(currency, totals.grossTotal),
    taxLabel: 'Tax'
  })

type AddressApiResponseToAddressFields = (
  address: Address
) => InvoiceEditorReducerState['data']['recipient']['customerAddressFields']

const createAdapterAddressDataFromApiResponse: AddressApiResponseToAddressFields =
  address => formatAddressForInvoice(address)

const createNewLineItemSaveData = (
  lineItem: Omit<InvoiceEditorLineItem, 'total'>
): ApiCreateLineItem => {
  return {
    title: lineItem.title,
    description: lineItem.description,
    quantity: lineItem.quantity,
    rate: lineItem.rate,
    taxRate: lineItem.taxRate ?? 0,
    rateDisplay: lineItem.rateDisplay as 'ABSOLUTE' | 'PERCENTAGE',
    externalIds: lineItem.externalIds,
    taxCategoryId: lineItem.taxCategoryId
  }
}

const createUpdatedLineItemSaveData = (
  lineItem: Omit<InvoiceEditorLineItem, 'total'>
): ApiUpdateLineItem & { id: string } => {
  return {
    id: lineItem.id,
    title: lineItem.title,
    description: lineItem.description,
    quantity: lineItem.quantity,
    rate: lineItem.rate,
    taxRate: lineItem.taxRate,
    rateDisplay: lineItem.rateDisplay,
    externalIds: lineItem.externalIds,
    taxCategoryId: lineItem.taxCategoryId
  }
}

const createNewLineItemGroupSaveData = (
  lineItemGroup: InvoiceEditorLineItemGroup
): ApiCreateLineItemGroup => {
  return {
    title: lineItemGroup.title,
    taxCategoryId: lineItemGroup.taxCategoryId
  }
}

const createUpdatedLineItemGroupSaveData = (
  lineItemGroup: InvoiceEditorLineItemGroup
): ApiUpdateLineItemGroup => {
  return {
    title: lineItemGroup.title,
    taxCategoryId: lineItemGroup.taxCategoryId
  }
}

type AdapterCustomerModel = {
  id: string
  companyName: string
  contacts: Array<any>
  address: {
    line1: string
    line2?: string
    town: string
    postcode: string
    state?: State
    country: Country
  }
}

export type AdapterCustomerWithTaxModel = AdapterCustomerModel & {
  aliases?: string[]
  label: string
  tax: {
    status: TaxStatus
    registrationId?: string
    identifier?: string
    country?: Country
  }
}

export type AdapterCustomerWithIntegrationsModel = AdapterCustomerModel & {
  taxStatus: TaxStatus
  integrationIds: NonNullable<
    InvoiceEditorReducerState['data']['customer']['integrationIds']
  >
}

const createUpdatedCustomerSaveData = (
  customer: AdapterCustomerWithTaxModel
): {
  legalName: string
  address: AddressModel
  customerAliases?: string[]
  taxStatus: TaxStatus
  taxRegistration?: {
    id?: string
    country: Country
    identifier: string
  }
  label: string
  contacts: Array<any>
} => {
  const saveData = {
    legalName: customer.companyName,
    address: customer.address,
    customerAliases: customer.aliases,
    taxStatus: customer.tax.status,
    label: customer.label,
    contacts: customer.contacts
  }

  if (
    customer.tax.identifier &&
    customer.tax.identifier !== '' &&
    customer.tax.country
  ) {
    return {
      ...saveData,
      taxRegistration: {
        id: customer.tax.registrationId,
        identifier: customer.tax.identifier,
        country: customer.tax.country
      }
    }
  }

  return saveData
}

const createUpdatedCustomerIntegrationSaveData = (
  customer: AdapterCustomerWithIntegrationsModel
): {
  taxStatus: TaxStatus
  legalName: string
  address: AddressModel
  contacts: Array<any>
  integrationIds: NonNullable<
    InvoiceEditorReducerState['data']['customer']['integrationIds']
  >
} => {
  return {
    legalName: customer.companyName,
    address: customer.address,
    contacts: customer.contacts,
    taxStatus: customer.taxStatus,
    integrationIds: customer.integrationIds
  }
}

type UpdateInvoiceSaveData = {
  /**
   * This type contains the allowed attributes that can be sent to PUT /invoices/:id via usePutInvoicesByIdMutation,
   * excluding:
   * - `billingRunId`: isn't returned by GET /invoices/:id, but there is a field available to change it in usePutInvoicesByIdMutation
   * - `customerShippingAddress`: unused according to https://sequenceslack.slack.com/archives/C0424M6QG0Z/p1708332338631819
   */
  dueDate?: string
  purchaseOrderNumber?: string
  reference?: string
  customerEmails?: string[]
  customerLegalCompanyName?: string
  customerBillingAddress?: Customer['address']
  memo?: string
  paymentOptions?: InvoicePaymentOption[]
  billingPeriod?: {
    start: string
    endInclusive: string
  }
  metadata?: KeyValuePairModel[]
  creditBalances?: string
  accountingDate?: string
}

const createUpdatedInvoiceSaveData = (
  reducerState: InvoiceEditorReducerState['data']
): UpdateInvoiceSaveData => {
  return {
    ...reducerState.invoice,
    customerEmails: reducerState.customer.contacts
      .filter(contact => !contact.billingPreference.includes('NONE'))
      .map(contact => contact.email),
    customerLegalCompanyName: reducerState.customer.companyName ?? '',
    customerBillingAddress: reducerState.customer.address,
    billingPeriod:
      reducerState.invoice.billingPeriodStart &&
      reducerState.invoice.billingPeriodEndInclusive
        ? {
            start: reducerState.invoice.billingPeriodStart,
            endInclusive: reducerState.invoice.billingPeriodEndInclusive
          }
        : undefined
  }
}

export const invoiceEditorApiAdapter = {
  in: {
    fullResponse: createAdapterDataFromApiResponse,
    invoice: createAdapterInvoiceDataFromApiResponse,
    totals: createAdapterTotalsDataFromApiResponse,
    address: createAdapterAddressDataFromApiResponse,
    subAccountUsageBreakdown: createSubAccountUsageBreakdownFromApiResponse
  },
  out: {
    invoice: {
      update: createUpdatedInvoiceSaveData
    },
    customer: {
      updateWithIntegrations: createUpdatedCustomerIntegrationSaveData,
      updateWithTax: createUpdatedCustomerSaveData
    },
    lineItemGroup: {
      create: createNewLineItemGroupSaveData,
      update: createUpdatedLineItemGroupSaveData
    },
    lineItem: {
      create: createNewLineItemSaveData,
      update: createUpdatedLineItemSaveData
    }
  }
}
