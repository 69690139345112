import { Text } from '@chakra-ui/react'
import { Badge } from '@sequencehq/core-components'
import {
  GreenGreen70,
  GreyGrey70,
  Lato14Regular,
  RedRed70
} from '@sequencehq/design-tokens'
import { TriangleDownIcon, TriangleUpIcon } from 'components/icons'

interface SeatChangeBadgeProps {
  type: 'added' | 'removed'
  count: number
}

export const SeatChangeBadge = ({
  type = 'added',
  count
}: SeatChangeBadgeProps) => {
  const isZeroSum = count === 0
  const isAdded = type === 'added'
  const color = isZeroSum ? GreyGrey70 : isAdded ? GreenGreen70 : RedRed70

  return (
    <Badge
      sentiment={isZeroSum ? 'neutral' : isAdded ? 'success' : 'error'}
      textStyle={{
        flexShrink: 0,
        display: 'flex',
        placeItems: 'center',
        gap: 1
      }}
      wrapText
    >
      {isAdded ? (
        <TriangleUpIcon color={color} />
      ) : (
        <TriangleDownIcon color={color} />
      )}
      <Text as="span" {...Lato14Regular} color={color}>{`${count} ${
        count === 1 ? 'seat' : 'seats'
      }`}</Text>
    </Badge>
  )
}
