import { FC, Fragment, useEffect, useMemo } from 'react'
import { useGetApiSelfQuery } from 'features/api'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  isDemoEnv,
  isProductionEnv,
  isSandboxEnv,
  isTestEnv
} from 'lib/environment/environment'
import { redirectToLogin } from 'lib/auth/session'
import { useStytchSession } from 'modules/LoginApp/hooks/useStytchSession'
import { PageContainer } from 'modules/LoginApp/components/common/PageContainer'
import { Wordmark } from 'components/icons/Wordmark'
import { Section } from 'modules/LoginApp/components/common/Section'
import Spinner from 'components/Loading/Spinner'
import posthog from 'posthog-js'
import { developerLog } from '@sequencehq/utils'

type Props = { children?: React.ReactNode }

const DashboardAuth: FC<Props> = ({ children }) => {
  const isAtAuthPath = useMemo(() => {
    return window.location.pathname.startsWith('/auth')
  }, [])
  const isAtRootPath = useMemo(() => {
    return window.location.pathname === '/'
  }, [])

  const { data: session, error: sessionError } = useStytchSession()
  const { error: selfError, data: selfResponse } = useGetApiSelfQuery(
    !session || isDemoEnv() ? skipToken : undefined
  )

  const self = useMemo(() => {
    return selfResponse?.value()
  }, [selfResponse])

  useEffect(() => {
    /**
     * If we're in an auth path, don't do anything and leave it up to
     * the login process to handle what needs to be done!
     */
    if (!isDemoEnv() && !isAtAuthPath && (sessionError || selfError)) {
      redirectToLogin()
    }

    if (isAtAuthPath) {
      return
    }

    if ((isDemoEnv() || isTestEnv() || (session && self)) && isAtRootPath) {
      if ((isProductionEnv() || isSandboxEnv()) && self) {
        developerLog('[Posthog] Identifying user')
        posthog.identify(self.sequenceUser.id, {
          email: self.sequenceUser.email,
          vercelBuild: __DEPLOYMENT_ID__ ?? 'unknown'
        })
      }

      window.history.pushState({}, '', '/home')
      return
    }
  }, [session, sessionError, selfError, isAtRootPath, self, isAtAuthPath])

  if (isDemoEnv() || (session && self)) {
    return <Fragment>{children}</Fragment>
  }

  return (
    <PageContainer paddingTop="32px">
      <Wordmark width="126" height="26.74" />
      <Section gap="8px">
        <Spinner />
      </Section>
    </PageContainer>
  )
}

export default DashboardAuth
