export function mergeLineItemsIntoGroups<
  Group extends { id: string },
  LineItem extends { groupId: string },
  AggregatedGroup = Group & { lineItems: LineItem[] }
>(lineItems: LineItem[], groups: Group[]): AggregatedGroup[] {
  return groups.map(group => {
    return {
      ...group,
      lineItems: lineItems.filter(lineItem => lineItem.groupId === group.id)
    } as AggregatedGroup
  })
}
