import { IntegrationConfig } from 'modules/Integrations/domain'
import logo from './anrokLogo.svg'
import { NoConfiguration } from 'modules/Integrations/integrationsConfig/common/NoConfiguration'
import { AnrokIntegrationDetail } from './AttioIntegrationDetail'

export const anrokIntegrationConfig: IntegrationConfig = {
  logoUrl: logo,
  smallLogoUrl: logo,
  documentationUrl: '',
  title: 'Anrok',
  features: {
    canDeleteLinks: false,
    canUninstall: false,
    isDocumentationOnly: false
  },
  description: 'Automatically calculate sales tax for invoices.',
  components: {
    detail: AnrokIntegrationDetail,
    management: NoConfiguration
  }
}
