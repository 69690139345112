import {
  Box,
  Center,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Badge } from '@sequencehq/core-components'
import {
  CreditNoteModel,
  toInvoiceStatusBadgeProps,
  toMoney
} from '@sequencehq/core-models'
import {
  GreyGrey30,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import { useGetCreditNotesQuery } from 'features/api'
import { useCustomerContext } from 'Customer/hooks/useCustomerContext'
import { Link, useNavigate } from 'react-router-dom'

export const CreditNoteTable = () => {
  const navigate = useNavigate()
  const { customer } = useCustomerContext()
  const { data } = useGetCreditNotesQuery({
    customerId: customer.id
  })

  const creditNotes = data?.value()

  if (!creditNotes) {
    return (
      <Center height="200px" width="100%" mt={4}>
        <Spinner />
      </Center>
    )
  }
  return (
    <>
      <TableContainer
        borderRadius="lg"
        overflow="hidden"
        border={`1px solid ${GreyGrey30}`}
        mt={4}
        width="100%"
        boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.05)"
      >
        <Table variant="v2" width="100%">
          <Thead>
            <Tr>
              <Th width={100} textAlign="right">
                Amount
              </Th>
              <Th width={160}>Status</Th>
              <Th>Credit note #</Th>
              <Th width={200}>Billing period</Th>
              <Th width={100}>Sent</Th>
            </Tr>
          </Thead>
          <Tbody>
            {creditNotes.items.length > 0 ? (
              creditNotes.items.map((creditNote: CreditNoteModel) => {
                return (
                  <Tr
                    key={creditNote.id}
                    onClick={() => navigate(`/credit-notes/${creditNote.id}`)}
                  >
                    <Td textAlign="right">
                      <Text
                        {...Lato13Bold}
                        as={Link}
                        to={`/credit-notes/${creditNote.id}`}
                        color="#5F5CFF"
                      >
                        {`${toMoney({
                          currency: creditNote.currency,
                          value: creditNote.grossTotal
                        })}`}
                      </Text>
                    </Td>
                    <Td>
                      <Flex gap={2} alignItems="center">
                        <Badge
                          {...toInvoiceStatusBadgeProps({
                            status: creditNote.status,
                            size: 'sm'
                          })}
                        />
                      </Flex>
                    </Td>
                    <Td>{creditNote.creditNoteNumber || '-'}</Td>
                    <Td>
                      {creditNote.billingPeriodStart &&
                      creditNote.billingPeriodEnd
                        ? `${dateTimeWithFormat(
                            creditNote.billingPeriodStart,
                            'd MMM'
                          )} to ${dateTimeWithFormat(
                            creditNote.billingPeriodEnd,
                            'd MMM yyyy'
                          )}`
                        : '-'}
                    </Td>
                    <Td>
                      {creditNote.issueDate
                        ? dateTimeWithFormat(creditNote.issueDate, 'd MMM yyyy')
                        : '-'}
                    </Td>
                  </Tr>
                )
              })
            ) : (
              <Tr
                sx={{
                  cursor: 'auto!important',
                  _hover: {
                    bgColor: 'inherit!important'
                  }
                }}
              >
                <Td colSpan={5}>
                  <EmptyContent />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}

const EmptyContent = () => {
  return (
    <Box width="400px" margin="12px auto" textAlign="center">
      <Text {...Lato13Bold} color="gray.90">
        No credit notes to display
      </Text>
      <Text {...Lato13Regular} color="gray.80">
        You have not created credit notes for this customer yet.
      </Text>
    </Box>
  )
}
