import { Flex, Center, Text } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey30,
  GreyGrey40,
  GreyGrey70,
  IndigoIndigo50,
  Lato13Regular,
  LineHeights20
} from '@sequencehq/design-tokens'
import PuzzlePieceIcon from '@heroicons/react/16/solid/PuzzlePieceIcon'
import { Link } from 'react-router-dom'

export const IntegrationsEmptyState = () => {
  return (
    <Flex
      flexDirection="column"
      backgroundColor={GreyGrey10}
      paddingY="20px"
      paddingX="16px"
      gap="12px"
      alignItems="center"
      borderRadius="8px"
    >
      <Center
        borderRadius="6px"
        border={`${GreyGrey40} 1px solid`}
        background={GreyGrey30}
        boxSize="28px"
        boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.05)"
      >
        <PuzzlePieceIcon width="16px" height="16px" color={GreyGrey70} />
      </Center>

      <Flex flexDirection="column" alignItems="center">
        <Text
          {...Lato13Regular}
          lineHeight={LineHeights20}
          textAlign="center"
          width="100%"
          color={GreyGrey70}
        >
          No integrations available. Manage your payment and accounting
          integrations on the integrations page.
          <Link
            to="https://docs.sequencehq.com/integrations/overview"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text
              {...Lato13Regular}
              fontWeight="600"
              lineHeight={LineHeights20}
              textAlign="center"
              color={IndigoIndigo50}
            >
              Learn more
            </Text>
          </Link>
        </Text>
      </Flex>
    </Flex>
  )
}
