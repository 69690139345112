import { mergeLineItemsIntoGroups } from "CreditNotes/utils/lineItemGroup"
import { partition } from "lodash"

type LineItemGroup = {
  title: string
  id: string
}

type LineItem = {
  taxRate: string
  groupId: string
  grossTotal: string
  quantity: string,
  title: string,
  rate: string
}

export function rollUpGroupsIntoLineItems<
  G extends LineItemGroup, L extends LineItem, A extends G & { lineItems: L[] }
>(lineItemGroups: G[], lineItems: L[], newGroup: LineItemGroup): A[] {
  const aggregatedGroups = mergeLineItemsIntoGroups<G, L, A>(lineItems, lineItemGroups)

  const [groupsToRollUp, remainingGroups] = partition(aggregatedGroups, group => canBeRolledUp(group.lineItems))

  if (!groupsToRollUp.length) {
    return remainingGroups
  }

  const rolledUp = groupsToRollUp.map(group => rollUpGroup(group, newGroup.id))

  return [{ ...newGroup, lineItems: rolledUp } as A, ...remainingGroups]
}


function canBeRolledUp(lineItems: LineItem[]): boolean {
  const taxRates = new Set()

  for (const lineItem of lineItems) {
    taxRates.add(lineItem.taxRate)
  }

  return taxRates.size === 1
}

function rollUpGroup(lineItemGroup: LineItemGroup & { lineItems: LineItem[] }, parentGroupId: string): LineItem {
  const total = lineItemGroup.lineItems.reduce((acc, lineItem) => {
    return acc + Number(lineItem.grossTotal)
  }, 0)

  return {
    taxRate: lineItemGroup.lineItems[0].taxRate,
    groupId: parentGroupId,
    grossTotal: total.toString(),
    rate: total.toString(),
    quantity: '1',
    title: lineItemGroup.title
  }
}
