import { Box, Flex, Grid, Img } from '@chakra-ui/react'
import {
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { ReactNode } from 'react'
import { PopoverWrapper, Skeleton } from '@sequencehq/core-components'
import { Link } from 'react-router-dom'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/utils'
import invariant from 'tiny-invariant'
import { CustomerPreviewCard } from 'Customer/components/CustomerPreviewCard'
import {
  CalendarIcon,
  CpuChipIcon,
  CubeIcon,
  DocumentTextIcon,
  IdentificationIcon,
  PencilSquareIcon,
  RectangleStackIcon
} from '@heroicons/react/16/solid'
import { getLogoSrc } from 'Customer/utils/logo'
import { format } from '@sequencehq/utils/dist/dates'

const RowItem = (props: { label: string; content: ReactNode }) => {
  return (
    <Grid
      gridTemplateColumns={'81px 1fr'}
      width="100%"
      gridColumnGap="10px"
      alignItems="center"
      height="32px"
      {...Lato13Regular}
    >
      <Box color={GreyGrey60}>{props.label}</Box>
      <Box {...Lato13Regular} color={GreyGrey80}>
        {props.content}
      </Box>
    </Grid>
  )
}

type UseJournalDetailSummary = (props: { journalId: string }) =>
  | {
      summary: {
        id: string
        customer?: {
          id: string
          name: string
          domain?: string
        }
        product?: {
          id: string
          name: string
        }
        invoice?: {
          id: string
          name: string
        }
        narrative?: string
        journalType?: 'MANUAL' | 'SYSTEM'
        date: string
      }
      isLoading: false
    }
  | {
      summary: null
      isLoading: true
    }

/**
 * Data acquisition for the summary
 * @param props
 * @returns
 */
export const useJournalDetailSummary: UseJournalDetailSummary = (props: {
  journalId: string
}) => {
  const journalQuery = useQuery(
    dashboardv99990101Client.getJournal,
    { id: props.journalId },
    {
      select: res => {
        if (!res) {
          return null
        }

        return {
          id: res.id,
          customer: res.customer,
          product: res.product,
          invoice: res.invoice,
          narrative: res.narrative,
          journalType: res.journalType,
          date: res.date
        }
      }
    }
  )

  /**
   * We throw this error to be caught by the appropriate error boundary.
   */
  if (journalQuery.error) {
    throw new Error('Journal summary could not be loaded')
  }

  if (journalQuery.isPending) {
    return {
      isLoading: true,
      summary: null
    }
  }

  invariant(journalQuery.data, 'Journal should be defined, if loaded')

  return {
    isLoading: false,
    summary: journalQuery.data
  }
}

export const JournalDetailSummary = (props: { journalId: string }) => {
  const { isLoading, summary } = useJournalDetailSummary({
    journalId: props.journalId
  })

  return (
    <Box>
      <Box {...Lato13Bold} color={GreyGrey80} marginBottom="8px">
        Summary
      </Box>
      {isLoading ? (
        <Skeleton height="160px" width="100%" />
      ) : (
        <>
          {summary.customer && (
            <RowItem
              label="Customer"
              content={
                <PopoverWrapper
                  popover={
                    <CustomerPreviewCard customerId={summary.customer.id} />
                  }
                  offset={[-4, 4]}
                  openDelay={850}
                >
                  <Link to={`/customers/${summary.customer.id}`}>
                    <Flex gap="8px" alignItems="center">
                      {summary.customer.domain ? (
                        <Img
                          height="16px"
                          width="16px"
                          src={getLogoSrc({ domain: summary.customer.domain })}
                        />
                      ) : (
                        <IdentificationIcon
                          height="16px"
                          width="16px"
                          color={GreyGrey70}
                        />
                      )}
                      <Box>{summary.customer.name}</Box>
                    </Flex>
                  </Link>
                </PopoverWrapper>
              }
            />
          )}
          {summary.product && (
            <RowItem
              label="Product"
              content={
                <Link to={`/products/${summary.product.id}`}>
                  <Flex gap="8px" alignItems="center">
                    <CubeIcon height="16px" width="16px" color={GreyGrey70} />
                    <Box>{summary.product.name}</Box>
                  </Flex>
                </Link>
              }
            />
          )}
          {summary.invoice && (
            <RowItem
              label="Invoice"
              content={
                <Link to={`/invoices/${summary.invoice.id}`}>
                  <Flex gap="8px" alignItems="center">
                    <RectangleStackIcon
                      height="16px"
                      width="16px"
                      color={GreyGrey70}
                    />
                    <Box>{summary.invoice.name}</Box>
                  </Flex>
                </Link>
              }
            />
          )}
          <RowItem
            label="Entry date"
            content={
              <Flex gap="8px" alignItems="center" cursor="default">
                <CalendarIcon height="16px" width="16px" color={GreyGrey70} />
                <Box>{format(summary.date, 'dd MMM yyyy')} </Box>
              </Flex>
            }
          />
          {summary.journalType && (
            <RowItem
              label="Type"
              content={
                <Flex gap="8px" alignItems="center" cursor="default">
                  <DocumentTextIcon
                    height="16px"
                    width="16px"
                    color={GreyGrey70}
                  />
                  <Box>
                    {summary.journalType === 'MANUAL'
                      ? 'Adjustment journal'
                      : 'System generated'}
                  </Box>
                </Flex>
              }
            />
          )}
          {summary.narrative && (
            <RowItem label="Narrative" content={summary.narrative} />
          )}
        </>
      )}
    </Box>
  )
}
