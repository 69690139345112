import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useQuery } from '@sequencehq/api/utils'
import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useProductRoot } from 'modules/Products/hooks/useProductRoot'
import { RevenueRecognitionMethod } from 'Products/types'
import { useCallback, useMemo, useState } from 'react'

type Product = {
  name: string
  label: string
  taxCategoryId?: string
  revenueRecognitionMethod?: RevenueRecognitionMethod
}

export const useAddProduct = () => {
  const {
    functions: { createProduct }
  } = useProductRoot()
  const flags = useFlags()

  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const { data: taxCategoriesData } = useQuery(
    dashboard20240730Client.getTaxCategories
  )

  const taxCategories = taxCategoriesData?.items ?? []

  const defaultTaxCategory = taxCategories.find(({ isDefault }) => isDefault)

  const { fields, queries } = useForm<Product>({
    value: {
      label: '',
      name: '',
      taxCategoryId: defaultTaxCategory?.id
    },
    showValidationErrors,
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required]
      },
      {
        property: 'label',
        validation: []
      },
      {
        property: 'taxCategoryId',
        validation: flags.showNewTaxRatesSettings ? [required] : [],
        options: taxCategories.map(category => ({
          value: category.id,
          label: category.name
        }))
      },
      {
        property: 'revenueRecognitionMethod',
        disabled: () => !flags?.useRevRec,
        options: [
          {
            label: 'Milestone',
            value: 'MILESTONE'
          },
          {
            label: 'Straight line',
            value: 'STRAIGHT_LINE'
          },
          {
            label: 'Usage',
            value: 'USAGE'
          }
        ]
      }
    ],
    onChange: newData => {
      setShowValidationErrors(false)
      setProduct(newData)
    }
  })

  const [product, setProduct] = useState<Product>()

  const canSave = useMemo(() => {
    return product !== undefined && queries.isValid
  }, [product, queries.isValid])

  const onCreateProduct = useCallback(() => {
    if (!queries.isValid) {
      setShowValidationErrors(true)
      return
    }

    if (!product) {
      return
    }

    return createProduct(product)
  }, [createProduct, product, queries.isValid])

  const enhancedFields = useMemo(() => {
    return {
      ...fields,
      taxCategoryId: {
        ...fields.taxCategoryId,
        hidden: !flags.showNewTaxRatesSettings
      }
    }
  }, [fields, flags.showNewTaxRatesSettings])

  return {
    fieldsConfig: enhancedFields,
    functions: {
      createProduct: onCreateProduct
    },
    canSave
  }
}
