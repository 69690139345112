import { IntegrationConfig } from 'modules/Integrations/domain'
import logo from './bigQueryLogo.svg'
import { NoConfiguration } from 'modules/Integrations/integrationsConfig/common/NoConfiguration'
import { BigQueryIntegrationDetail } from 'modules/Integrations/integrationsConfig/bigQuery/BigQueryIntegrationDetail'

export const bigQueryIntegrationConfig: IntegrationConfig = {
  logoUrl: logo,
  smallLogoUrl: logo,
  documentationUrl: 'https://docs.sequencehq.com/integrations/data-overview',
  title: 'Google BigQuery',
  features: {
    canDeleteLinks: false,
    canUninstall: false,
    isDocumentationOnly: true
  },
  description: 'Sync Sequence data to your data stack.',
  components: {
    detail: BigQueryIntegrationDetail,
    management: NoConfiguration
  }
}
