import { Flex, Divider } from '@chakra-ui/react'

import { GreyGrey30, GreyWhite } from '@sequencehq/design-tokens'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { PublishedStatus } from './PublishedStatus'
import { useQuoteEditorSidebar } from './useQuoteEditorSidebar'
import { SidebarFields } from './widgets/SidebarFields'
import { SidebarSection } from './widgets/SidebarSection'
import { Signers } from './widgets/Signers'

const styles = {
  divider: {
    borderBottomColor: GreyGrey30,
    marginBottom: '16px'
  }
}

export const QuoteEditorSidebar = () => {
  const hook = useQuoteEditorSidebar()
  const flags = useFlags()

  return (
    <Flex
      backgroundColor={GreyWhite}
      padding="16px 20px"
      height="100%"
      flexDirection="column"
    >
      <SidebarSection title="Status">
        <PublishedStatus />
      </SidebarSection>

      <Divider style={styles.divider} />

      <SidebarSection title="Contract details">
        <SidebarFields hook={hook} />
      </SidebarSection>

      {flags.useSigners && hook.features.isSignersVisible && (
        <>
          <Divider style={styles.divider} />
          <SidebarSection title="Signers">
            <Signers />
          </SidebarSection>
        </>
      )}
    </Flex>
  )
}
