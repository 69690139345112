import { Box, Flex } from '@chakra-ui/react'
import {
  CreateCreditNoteFromInvoiceFormMutation,
  CreateCreditNoteFromInvoiceFormProps
} from 'components/CreateCreditNoteFromInvoice/types'
import { MutationFormProps } from 'components/Form/types'
import { handleFormResponse } from 'lib/formValidation'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

const CreateCreditNoteForm: FC<
  MutationFormProps<CreateCreditNoteFromInvoiceFormMutation> &
    CreateCreditNoteFromInvoiceFormProps
> = ({ invoiceId, currency, customerId, submitForm, formId }) => {
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={async (values, form) => {
        const res = await submitForm({
          createCreditNoteEndpointCreateCreditNoteRequestModel: {
            invoiceId,
            currency,
            customerId
          }
        })

        return handleFormResponse(res, form.getRegisteredFields())
      }}
      render={({ handleSubmit }) => {
        const dayzedDate = new Date()
        dayzedDate.setDate(dayzedDate.getDate() - 1)
        return (
          <Flex
            as="form"
            flexDirection="column"
            px={10}
            py={10}
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            <Box>
              To correct the original invoice you can issue a credit note and
              maintain an audit trail.
            </Box>

            <Box padding="20px">
              <ul>
                <li>You can select original line items from the invoice</li>
                <li>You can add new line items</li>
                <li>Original invoice number will be included</li>
              </ul>
            </Box>
          </Flex>
        )
      }}
    />
  )
}

export default CreateCreditNoteForm
