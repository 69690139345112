import { Box, Checkbox, Icon, InputGroup, Link, Text } from '@chakra-ui/react'
import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon'
import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon'
import {
  GreyGrey20,
  GreyGrey70,
  GreyGrey90,
  IndigoIndigo50,
  Lato14Bold,
  Lato14ExtendedRegular
} from '@sequencehq/design-tokens'
import {
  HorizontalSelectorField,
  NumberField,
  PriceField,
  SelectField,
  TextField,
  SelectFieldWithActions
} from '@sequencehq/core-components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useState } from 'react'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { useSeatBasedPriceForm } from './useSeatBasedPriceForm'
import { PricingInformation } from '../common/PricingInformation'
import {
  AvailableStandardFrequency,
  BillingType,
  SeatBasedProrationStrategy,
  SeatOveragesBillingFrequency
} from 'common/drawers/PricingEditor/domain'
import { SeatBasedTierInput } from './SeatBasedTierInput/SeatBasedTierInput'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'

export const SeatBasedPriceListPriceForm = () => {
  const { fieldsConfig, currency, conditions } = useSeatBasedPriceForm()
  const flags = useFlags()
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)
  const [showMinimumSeats, setShowMinimumSeats] = useState(
    conditions.isContractedMinimumSet
  )

  const toolTipBoxes = fieldsConfig.overagesBillingFrequency.options.map(
    value => (
      <>
        <Box {...Lato14Bold} marginBottom="8px">
          {value.label}
        </Box>
        <Box marginBottom="16px">{value.description}</Box>
      </>
    )
  )

  return (
    <>
      <Text
        color={GreyGrey90}
        fontWeight="bold"
        fontSize="l"
        textColor="##1D2939"
        mb="6"
      >
        Pricing Information
      </Text>
      <PricingInformation
        value={fieldsConfig.pricingModel.value}
        isDisabled={fieldsConfig.pricingModel.disabled}
        options={fieldsConfig.pricingModel.options}
        validationErrors={fieldsConfig.pricingModel.validationErrors}
        onChange={fieldsConfig.pricingModel.onChange}
        description={fieldsConfig.pricingModel.description}
      />

      <Box paddingBottom="8px">
        <SelectFieldWithActions
          label="Seat metric"
          data-testid="listPrice.seatBased.seatMetric"
          options={fieldsConfig.seatMetricId.options}
          validationErrors={fieldsConfig.seatMetricId.validationErrors}
          value={fieldsConfig.seatMetricId.value}
          onChange={newValue => fieldsConfig.seatMetricId.onChange(newValue)}
          isDisabled={fieldsConfig.seatMetricId.disabled}
          placeholder="Select seat metric"
          onAddNew={fieldsConfig.seatMetricId.onAddNew}
        />

        <SelectField
          label="Billing frequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
          placeholder="Select billing frequency"
        />

        {flags.inAdvanceSeats && (
          <Checkbox
            isChecked={conditions.isInAdvanceSelected}
            onChange={() => {
              const newValue = conditions.isInAdvanceSelected
                ? 'IN_ARREARS'
                : 'IN_ADVANCE'
              fieldsConfig.billingType.onChange(newValue as BillingType)
            }}
            placeItems="start"
          >
            <Box>
              <Text {...Lato14Bold}>Bill in advance</Text>
              <Text {...Lato14ExtendedRegular} color="inherit">
                Choose in advance to charge customers upfront. By default
                customers are charged in arrears.{' '}
                <Link
                  href="https://docs.sequencehq.com"
                  isExternal
                  color={IndigoIndigo50}
                >
                  Learn more
                </Link>
              </Text>
            </Box>
          </Checkbox>
        )}

        <Box mt={4} />

        {!fieldsConfig.pricePerSeat.hidden ? (
          <InputGroup display="flex" alignItems="center">
            <PriceField
              label="Seat Price"
              data-testid="listPrice.seatBasedLinear.price"
              value={fieldsConfig.pricePerSeat.value}
              onChange={newValue =>
                fieldsConfig.pricePerSeat.onChange(newValue)
              }
              validationErrors={fieldsConfig.pricePerSeat.validationErrors}
              currency={currency}
              isDisabled={fieldsConfig.pricePerSeat.disabled}
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
            />
            <Box mt={6}>
              <SelectField
                data-testid="common-field-currency"
                value={fieldsConfig.currency.value}
                onChange={newCurrency =>
                  fieldsConfig.currency.onChange(newCurrency as Currency)
                }
                options={fieldsConfig.currency.options}
                validationErrors={fieldsConfig.currency.validationErrors}
                isDisabled={fieldsConfig.currency.disabled}
                placeholder="Select currency"
                width="150px"
                inputStyle={{
                  borderLeft: 'none',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
              />
            </Box>
          </InputGroup>
        ) : (
          <Box marginBottom="16px" paddingBottom="8px" width="100%">
            <Box display="flex" gap={4} alignItems="center">
              <Box {...Lato14Bold}>Pricing Tiers</Box>
              <Box mt={4}>
                <SelectField
                  data-testid="common-field-currency"
                  value={fieldsConfig.currency.value}
                  onChange={newCurrency =>
                    fieldsConfig.currency.onChange(newCurrency as Currency)
                  }
                  options={fieldsConfig.currency.options}
                  validationErrors={fieldsConfig.currency.validationErrors}
                  isDisabled={fieldsConfig.currency.disabled}
                  placeholder="Select currency"
                  width="90px"
                  height="24px"
                  backgroundColor={GreyGrey20}
                />
              </Box>
            </Box>
            <SeatBasedTierInput
              value={fieldsConfig.tiers.value}
              onChange={fieldsConfig.tiers.onChange}
              isDisabled={fieldsConfig.tiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.tiers.validationErrors}
            />
          </Box>
        )}

        <Checkbox
          disabled={fieldsConfig.contractedMinimumSeats.disabled}
          isChecked={showMinimumSeats}
          onChange={() => {
            const newValue = !showMinimumSeats

            if (!newValue) {
              fieldsConfig.contractedMinimumSeats.onChange('0')
            }

            setShowMinimumSeats(newValue)
          }}
          size="md"
          spacing="8px"
        >
          <Text {...Lato14Bold}>Contract a minimum number of seats</Text>
        </Checkbox>
        {showMinimumSeats && (
          <Box display="flex" gap="8px" mt="16px">
            <NumberField
              label=""
              value={fieldsConfig.contractedMinimumSeats.value}
              precision={0}
              onChange={newValue =>
                fieldsConfig.contractedMinimumSeats.onChange(newValue)
              }
              validationErrors={
                fieldsConfig.contractedMinimumSeats.validationErrors
              }
              isDisabled={fieldsConfig.contractedMinimumSeats.disabled}
              width="100px"
            />
            <Text {...Lato14ExtendedRegular} color={GreyGrey70} pt={2}>
              Included
            </Text>
          </Box>
        )}

        <Box mt={3} />

        <Box
          display="flex"
          gap={2}
          my={3}
          cursor="pointer"
          onClick={() => setShowAdditionalOptions(!showAdditionalOptions)}
        >
          <Text {...Lato14Bold} color="#5F5CFF">
            Additional options
          </Text>
          {showAdditionalOptions ? (
            <Icon as={ChevronDownIcon} color="#5F5CFF" w="20px" h="20px" />
          ) : (
            <Icon as={ChevronRightIcon} color="#5F5CFF" w="20px" h="20px" />
          )}
        </Box>

        {showAdditionalOptions && (
          <>
            <TextField
              label="Display name"
              data-testid="common-field-name"
              value={fieldsConfig.name.value}
              onChange={fieldsConfig.name.onChange}
              validationErrors={fieldsConfig.name.validationErrors}
              isDisabled={fieldsConfig.name.disabled}
              placeholder="Enter a display name"
            />
            {flags.inAdvanceSeats &&
              fieldsConfig.overagesBillingFrequency.value &&
              conditions.isContractedMinimumSet &&
              conditions.isInAdvanceSelected &&
              conditions.isBillingFrequencyAnnuallyOrQuarterly && (
                <HorizontalSelectorField
                  label="Overages billing frequency"
                  data-testid="seatBased.overagesBillingFrequency"
                  tooltip={{
                    title: 'Overages billing frequency',
                    content: <Box paddingTop="8px">{toolTipBoxes}</Box>
                  }}
                  value={fieldsConfig.overagesBillingFrequency.value}
                  onChange={newValue =>
                    fieldsConfig.overagesBillingFrequency.onChange(
                      newValue as SeatOveragesBillingFrequency
                    )
                  }
                  options={fieldsConfig.overagesBillingFrequency.options}
                  validationErrors={
                    fieldsConfig.overagesBillingFrequency.validationErrors
                  }
                  isDisabled={fieldsConfig.overagesBillingFrequency.disabled}
                />
              )}
            <Checkbox
              display="flex"
              gap={2}
              alignItems="flex-start"
              isChecked={conditions.isProrateStrategyIncrements}
              onChange={() => {
                const newValue = conditions.isProrateStrategyIncrements
                  ? 'USE_MAXIMUM'
                  : 'PRORATE_INCREMENTS'
                fieldsConfig.prorationStrategy.onChange(
                  newValue as SeatBasedProrationStrategy
                )
              }}
            >
              <Box>
                <Text {...Lato14Bold}>
                  Prorate overage fees for added seats
                </Text>
                <Text {...Lato14ExtendedRegular} color="inherit">
                  Charge a prorated overage fee for seats added mid-billing
                  period (default). Disable to charge the full price for added
                  seats.{' '}
                  <Link
                    href="https://docs.sequencehq.com"
                    isExternal
                    color={IndigoIndigo50}
                  >
                    Learn more
                  </Link>
                </Text>
              </Box>
            </Checkbox>
          </>
        )}
      </Box>

      <ExternalLedgerForm />
    </>
  )
}
