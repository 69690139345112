import { useAggregatedCreditNote } from 'CreditNotes/hooks/useAggregatedCreditNote'
import { useClientCopy } from 'CreditNotes/hooks/useClientCopy'
import { AggregatedCreditNote, LineItem } from 'CreditNotes/types'
import { recalculateCreditNote } from 'CreditNotes/utils/calculation'
import { useCallback, useRef } from 'react'

export const useCreditNoteEditing = (creditNoteId: string) => {
  const { creditNote: serverCreditNote, isLoading } =
    useAggregatedCreditNote(creditNoteId)

  const [clientCreditNote, setClientCreditNote] =
    useClientCopy(serverCreditNote)

  const creditNote = useMaintainUnselectedLineItems(clientCreditNote)

  const updateCreditNote = useCallback(
    (newCreditNote: AggregatedCreditNote) => {
      setClientCreditNote(recalculateCreditNote(newCreditNote))
      // TODO: save to server after debounce
    },
    [setClientCreditNote]
  )

  // TODO: nicer way of updating
  const updateLineItems = useCallback(
    (lineItemsToUpdate: LineItem[]) => {
      if (!creditNote) {
        return
      }

      updateCreditNote({
        ...creditNote,
        lineItemGroups: creditNote.lineItemGroups.map(lineItemGroup => {
          if (
            !lineItemsToUpdate.find(item => item.groupId === lineItemGroup.id)
          ) {
            return lineItemGroup
          }
          return {
            ...lineItemGroup,
            lineItems: lineItemGroup.lineItems.map(lineItem => {
              const matchingLineItem = lineItemsToUpdate.find(
                item => item.id === lineItem.id
              )
              if (!matchingLineItem) {
                return lineItem
              }

              return matchingLineItem
            })
          }
        })
      })
    },
    [creditNote, updateCreditNote]
  )

  return { creditNote, updateLineItems, isLoading }
}

// For the purposes of our first iteration,
// The server has no concept of whether a line item is included or not
// i.e. when a line item is unselected, we'll delete it from the server
//
// To be able to show the unselected items even after they're deleted from the server,
// we need to keep the initial server response
const useMaintainUnselectedLineItems = (
  creditNote: AggregatedCreditNote | null
) => {
  const initialCreditNote = useFirstNotNull(creditNote)

  if (creditNote === null) {
    return null
  }

  return maintainUnselectedLineItems(creditNote, initialCreditNote)
}

function maintainUnselectedLineItems(
  creditNote: AggregatedCreditNote,
  initialCreditNote: AggregatedCreditNote
): AggregatedCreditNote {
  return {
    ...creditNote,
    lineItemGroups: creditNote.lineItemGroups.map((group, i) => {
      const initialGroup = initialCreditNote.lineItemGroups[i]

      return {
        ...group,
        lineItems: initialGroup.lineItems.map(initialLineItem => {
          const currentLineItem = group.lineItems.find(
            item => item.id === initialLineItem.id
          )
          if (currentLineItem) {
            return {
              ...currentLineItem,
              selected: currentLineItem.selected ?? true
            }
          }

          return initialLineItem
        })
      }
    })
  }
}

const useFirstNotNull = (value: any) => {
  const ref = useRef(value)
  return (ref.current ??= value)
}
