import { InvoiceStatus, LineItemGroupModel } from '@sequencehq/core-models'
import {
  InvoiceEditorReducerState,
  LineItemSubAccountUsageBreakdown
} from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import { isEmpty } from 'lodash'

const getCanCreateCreditNote = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'SENT'
}

const getCanEditInvoice = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'DRAFT'
}

const getCanFinaliseAndSendInvoice = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'DRAFT'
}

const getCanFinaliseInvoice = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'DRAFT'
}

const getCanRecalculateInvoice = (prevState: InvoiceEditorReducerState) => {
  return (
    ['IN_PROGRESS', 'DRAFT'].includes(prevState.data.invoice.status) &&
    Boolean(prevState.data.invoice.billingScheduleId)
  )
}

const getCanSendInvoice = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'FINAL'
}

const getCanSendPaymentReminder = (prevState: InvoiceEditorReducerState) => {
  return (
    prevState.data.invoice.status === 'SENT' &&
    ['UNPAID', 'PARTIALLY_PAID'].includes(prevState.data.invoice.paymentStatus)
  )
}

const getCanUpdatePaymentStatus = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'SENT'
}

const getCanViewInCustomerPortal = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'SENT'
}

const getCanVoidInvoice = (prevState: InvoiceEditorReducerState) => {
  return ['FINAL', 'DRAFT'].includes(prevState.data.invoice.status)
}

const getCanEditLineItems = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'DRAFT'
}

const getCanEditMemo = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'DRAFT'
}

const getCanEditPurchaseOrderNumber = (
  prevState: InvoiceEditorReducerState
) => {
  return prevState.data.invoice.status === 'DRAFT'
}

const getCanEditReference = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'DRAFT'
}

const getCanEditDueDate = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'DRAFT'
}

const getCanSendTestInvoice = (prevState: InvoiceEditorReducerState) => {
  return ['FINAL', 'DRAFT'].includes(prevState.data.invoice.status)
}

const getCanEditCustomer = (prevState: InvoiceEditorReducerState) => {
  return ['DRAFT', 'IN_PROGRESS'].includes(prevState.data.invoice.status)
}

const getCanSyncCustomerToIntegrations = (
  prevState: InvoiceEditorReducerState
) => {
  return ['DRAFT', 'IN_PROGRESS', 'SENT', 'FINAL'].includes(
    prevState.data.invoice.status
  )
}

const getCanSyncInvoiceToIntegrations = (
  prevState: InvoiceEditorReducerState
) => {
  return ['SENT', 'FINAL'].includes(prevState.data.invoice.status)
}

const STATUSES_ALLOWING_PAYMENT_COLLECTION_UPDATE: InvoiceStatus[] = [
  'DRAFT',
  'IN_PROGRESS',
  'FINAL',
  'SENT'
]
const getCanUpdatePaymentCollectionMethod = (
  prevState: InvoiceEditorReducerState
) => {
  return STATUSES_ALLOWING_PAYMENT_COLLECTION_UPDATE.includes(
    prevState.data.invoice.status
  )
}

export const getCanViewSubAccountUsage = (
  prevState: InvoiceEditorReducerState
) => {
  return (
    prevState.configuration.features.displaySubAccountUsage &&
    prevState.data.subAccountUsageBreakdown.length > 0
  )
}

export const getSubAccountUsageBreakdownByLineItemGroup = (
  prevState: InvoiceEditorReducerState
): Record<LineItemGroupModel['id'], LineItemSubAccountUsageBreakdown[]> => {
  return Object.values(prevState.data.lineItemGroups).reduce(
    (acc, lineItemGroup) => {
      const subAccountUsageBreakdownForThisGroup =
        prevState.data.subAccountUsageBreakdown.flatMap(subAccountUsage => {
          return subAccountUsage.usageData
            .map(usageData => {
              if (usageData.lineItemGroupId === lineItemGroup.id) {
                return {
                  description: subAccountUsage.subAccountLabel,
                  quantity: usageData.quantity,
                  id: subAccountUsage.subAccountLabel
                }
              }
            })
            .filter(Boolean)
        })

      return {
        ...acc,
        [lineItemGroup.id]: subAccountUsageBreakdownForThisGroup
      }
    },
    {}
  )
}

export const getCanUseTaxCategories = (
  prevState: InvoiceEditorReducerState
) => {
  const lineItemGroups = Object.values(prevState.data.lineItemGroups)
  const lineItems = Object.values(prevState.data.lineItemGroups)

  // If an invoice doesn't have any groups or line items i.e. is a fresh invoice
  // new tax categories can be used
  if (isEmpty(lineItemGroups) && isEmpty(lineItems)) {
    return true
  }

  // If all line item groups have a tax category, new tax categories can be used
  return lineItemGroups.every(lineItemGroup => lineItemGroup.taxCategory?.id)
}

const getCanEditInvoiceDate = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'DRAFT'
}

const getCanEditBillingPeriod = (prevState: InvoiceEditorReducerState) => {
  return prevState.data.invoice.status === 'DRAFT'
}

export const queries = () => (prevState: InvoiceEditorReducerState) => {
  const newQueryState: InvoiceEditorReducerState['derived']['queries'] = {
    availableFeatures: {
      canCreateCreditNote: getCanCreateCreditNote(prevState),
      canDownloadInvoice: true,
      canPreviewInvoice: true,
      canEditCustomer: getCanEditCustomer(prevState),
      canEditInvoice: getCanEditInvoice(prevState),
      canEditLineItems: getCanEditLineItems(prevState),
      canFinaliseAndSendInvoice: getCanFinaliseAndSendInvoice(prevState),
      canFinaliseInvoice: getCanFinaliseInvoice(prevState),
      canRecalculateInvoice: getCanRecalculateInvoice(prevState),
      canSendInvoice: getCanSendInvoice(prevState),
      canSendPaymentReminder: getCanSendPaymentReminder(prevState),
      canSendTestInvoice: getCanSendTestInvoice(prevState),
      canSyncCustomerToIntegrations:
        getCanSyncCustomerToIntegrations(prevState),
      canSyncInvoiceToIntegrations: getCanSyncInvoiceToIntegrations(prevState),
      canUpdatePaymentCollectionMethod:
        getCanUpdatePaymentCollectionMethod(prevState),
      canUpdatePaymentStatus: getCanUpdatePaymentStatus(prevState),
      canViewInCustomerPortal: getCanViewInCustomerPortal(prevState),
      canVoidInvoice: getCanVoidInvoice(prevState),
      canEditMemo: getCanEditMemo(prevState),
      canEditPurchaseOrderNumber: getCanEditPurchaseOrderNumber(prevState),
      canEditReference: getCanEditReference(prevState),
      canEditDueDate: getCanEditDueDate(prevState),
      canViewSubAccountUsage: getCanViewSubAccountUsage(prevState),
      canUseTaxCategories: getCanUseTaxCategories(prevState),
      canEditInvoiceDate: getCanEditInvoiceDate(prevState),
      canEditBillingPeriod: getCanEditBillingPeriod(prevState)
    },
    subAccountUsageBreakdownByLineItemGroup:
      getSubAccountUsageBreakdownByLineItemGroup(prevState)
  }

  return {
    ...prevState,
    derived: {
      ...prevState.derived,
      queries: newQueryState
    }
  }
}
