import { Box, Link } from '@chakra-ui/react'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'
import {
  Schedule,
  DisabledReasonType
} from 'modules/Cube/domain/cube.domain.types'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { ReactNode, useMemo } from 'react'

type TargetDataStructure = Pick<Schedule, 'stripePayment'>

type FieldConfig<
  Property extends keyof TargetDataStructure = keyof TargetDataStructure
> = {
  disabled: boolean
  validationErrors: string[]
  value: TargetDataStructure[Property]
  onChange: (newValue: TargetDataStructure[Property]) => void
}

type UsePaymentWidget = () => {
  fieldsConfig: {
    stripePayment: FieldConfig<'stripePayment'> & {
      disabledReason: ReactNode
    }
  }
}

const DISABLED_REASON_MAP: Partial<Record<DisabledReasonType, ReactNode>> = {
  [DisabledReasonType.FeatureDisabled]: (
    <Box>
      Integrate with Stripe to accept payments via Stripe.{' '}
      <Link href="/integrations" target="_blank" color={IndigoIndigo50}>
        Explore our integrations
      </Link>
    </Box>
  ),
  [DisabledReasonType.InvalidStatus]:
    'Accepting payments by Stripe cannot be updated for a completed schedule'
}

export const usePaymentWidget: UsePaymentWidget = () => {
  const cubeContext = useCubeContext()

  const fieldsConfig = useMemo(() => {
    return {
      stripePayment: {
        value: cubeContext.queries.rawData.data.schedule.stripePayment,
        disabled:
          !cubeContext.queries.availableFeatures.schedule.stripePayment
            .available.enabled,
        disabledReason:
          DISABLED_REASON_MAP[
            cubeContext.queries.availableFeatures.schedule.stripePayment
              .reasons[0]?.reasonType
          ] ?? '',
        onChange: (newValue: boolean) =>
          cubeContext.mutators.updateData({
            schedule: {
              stripePayment: newValue
            }
          }),
        validationErrors: []
      }
    }
  }, [cubeContext])

  return {
    fieldsConfig
  }
}
