import { FC, memo } from 'react'
import { EmptyStateContent } from 'components/Loading/EmptyState'
import { Button, Flex } from '@chakra-ui/react'
import Page from 'components/Page'
import { SeatTypesMagicTable } from 'components/SeatTypes/SeatTypesMagicTable'
import { Outlet, useNavigate } from 'react-router-dom'
import { SeatTypeProvider } from 'components/SeatType'
import { useFlags } from 'launchdarkly-react-client-sdk'
import PlusIcon from '@heroicons/react/24/solid/PlusIcon'

export type SeatTypesSharedTableProps = {
  emptyContent: EmptyStateContent
}

const SeatTypes: FC = memo(() => {
  const flags = useFlags()
  const navigate = useNavigate()
  if (!flags.enableSeats) {
    navigate('/home')
  }

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Metrics"
          actionElement={
            <ActionButton onClick={() => navigate('/seats/new')} />
          }
        >
          <SeatTypesMagicTable emptyContent={emptyContent} />
        </Page>
      </Flex>
      <SeatTypeProvider>
        <Outlet />
      </SeatTypeProvider>
    </Flex>
  )
})

const ActionButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      variant="component-library-primary"
      leftIcon={<PlusIcon width="16px" height="16px" color="inherit" />}
      iconSpacing="4px"
      onClick={onClick}
    >
      Create seat metric
    </Button>
  )
}

export default SeatTypes

const emptyContent: EmptyStateContent = {
  title: 'Create your first seat metric',
  description: 'Unlock seat-based pricing by creating a seat metric',
  route: '/seats/new',
  buttonCopy: 'Create seat metric',
  linkCopy: 'Learn more',
  docLink: 'https://docs.sequencehq.com/seats/examples'
}
