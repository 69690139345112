import { FC } from 'react'
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext.ts'
import { useIntegrations } from 'InvoiceEditor/components/Integrations/useIntegrations'

type ConfirmFinaliseInvoiceModalProps = {
  onClose: () => void
  onConfirm: (shouldRefetch: boolean) => void
}

export const ConfirmFinaliseInvoiceModal: FC<
  ConfirmFinaliseInvoiceModalProps
> = ({ onConfirm, onClose }) => {
  const { data } = useInvoiceEditorContext()
  const {
    data: { integrations }
  } = useIntegrations()

  const hasIntegrations = integrations.length > 0

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
            Finalize invoice
          </Box>
          <Box
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginBottom="24px"
          >
            {`Lock this invoice and prevent editing. Once finalized, you can send this invoice to ${data?.recipient.customerLegalName}.
            `}
          </Box>
          <Flex gap="8px" justifyContent="space-between">
            <Button
              variant="secondary2"
              onClick={onClose}
              height="32px"
              width="100%"
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              onClick={() => onConfirm(hasIntegrations)}
              height="32px"
              width="100%"
            >
              Finalize invoice
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
