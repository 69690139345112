import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useCallback } from 'react'

export type v1ApiNewBillingScheduleSettings =
  Dashboardv20240509Api.PostBillingScheduleSettings.BillingScheduleSettingsBody
export type v1ApiUpdateBillingScheduleSettings =
  Dashboardv20240509Api.PutBillingScheduleSettings.BillingScheduleSettingsBody
type ApiBillingScheduleSettings =
  Dashboardv20240509Api.PostBillingScheduleSettings.Response

type UseSaveBillingScheduleSettings = () => {
  updateBillingScheduleSettings: (
    data: v1ApiNewBillingScheduleSettings | v1ApiUpdateBillingScheduleSettings
  ) => Promise<ApiBillingScheduleSettings['data']>
}

export const useSaveBillingScheduleSettings: UseSaveBillingScheduleSettings =
  () => {
    const updateBillingScheduleSettings = useCallback(
      async ({
        ...data
      }: v1ApiUpdateBillingScheduleSettings): Promise<
        ApiBillingScheduleSettings['data']
      > => {
        const existingSettings = (
          await dashboardv20240509Client.getBillingScheduleSettings({
            billingScheduleId: data.billingScheduleId
          })
        )?.data

        if (!existingSettings?.id) {
          const response =
            await dashboardv20240509Client.postBillingScheduleSettings({
              body: {
                billingScheduleId: data.billingScheduleId,
                paymentProvider: data.paymentProvider
              }
            })

          return response.data
        }

        const response =
          await dashboardv20240509Client.putBillingScheduleSettings({
            id: existingSettings.id,
            body: {
              billingScheduleId: data.billingScheduleId,
              paymentProvider: data.paymentProvider
            }
          })

        return response.data
      },
      []
    )

    return {
      updateBillingScheduleSettings
    }
  }
