import { Flex } from '@chakra-ui/react'
import { Button, SequenceKebabMenu } from '@sequencehq/core-components'
import { EnvelopeIcon, EyeIcon } from '@heroicons/react/16/solid'
import { RedRed50 } from '@sequencehq/design-tokens'
import { CreditNoteIssueDialog } from 'CreditNotes/components/dialog/CreditNoteIssue'
import type { CreditNoteStatus } from 'CreditNotes/types'
import { CREDIT_NOTE_STATUSES } from 'CreditNotes/constants'
import { VoidCreditNoteDialog } from './dialog/VoidCreditNoteDialog'
import { useMutation } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useNotifications } from 'lib/hooks/useNotifications'
import { SendTestCreditNoteDialog } from './dialog/SendTestCreditNoteDialog'
import { useCreditNotePdfDownload } from 'components/CreditNote/useCreditNotePdfDownload.tsx'

const useCreditNoteActions = (creditNoteId: string) => {
  const notifications = useNotifications()
  const issueCreditNoteMutation = useMutation(
    dashboardv99990101Client.postFinalizeAndSendCreditNote,
    {
      onSuccess: () => {
        notifications.displayNotification('Credit note issued', {
          type: 'success'
        })
      },
      onError: () => {
        notifications.displayNotification('Failed to issue credit note', {
          type: 'error'
        })
      }
    }
  )
  const voidCreditNoteMutation = useMutation(
    dashboardv99990101Client.postVoidCreditNote,
    {
      onSuccess: () => {
        notifications.displayNotification('Credit note voided', {
          type: 'success'
        })
      },
      onError: () => {
        notifications.displayNotification('Failed to void credit note', {
          type: 'error'
        })
      }
    }
  )
  const sendTestCreditNoteMutation = useMutation(
    dashboardv99990101Client.postSendTestCreditNote,
    {
      onSuccess: () => {
        notifications.displayNotification('Test credit note sent', {
          type: 'success'
        })
      },
      onError: () => {
        notifications.displayNotification('Failed to send test credit note', {
          type: 'error'
        })
      }
    }
  )

  const issueCreditNote = () => {
    issueCreditNoteMutation.mutate({
      id: creditNoteId
    })
  }
  const voidCreditNote = () => {
    voidCreditNoteMutation.mutate({
      id: creditNoteId
    })
  }
  const sendTestCreditNote = (testEmail: string) => {
    sendTestCreditNoteMutation.mutate({
      id: creditNoteId,
      testEmail
    })
  }
  return {
    issueCreditNote,
    sendTestCreditNote,
    voidCreditNote
  }
}

interface Props {
  creditNoteId: string
  creditNoteNumber: string | undefined
  creditNoteStatus: CreditNoteStatus
  onPreviewCreditNotePDF: () => void
  customerLegalCompanyName: string
  customerEmails: string[]
}

export const CreditNoteDetailsHeaderActions = ({
  creditNoteId,
  creditNoteNumber,
  creditNoteStatus,
  onPreviewCreditNotePDF,
  customerLegalCompanyName,
  customerEmails
}: Props) => {
  const { issueCreditNote, sendTestCreditNote, voidCreditNote } =
    useCreditNoteActions(creditNoteId)

  const { downloadPdf } = useCreditNotePdfDownload()

  return (
    <Flex gap={2} alignItems="center" alignContent="center">
      <Button
        variant="secondary"
        leadingIcon={<EyeIcon width="16px" height="16px" />}
        onClick={onPreviewCreditNotePDF}
      >
        Preview credit note PDF
      </Button>

      {creditNoteStatus === CREDIT_NOTE_STATUSES.DRAFT && (
        <CreditNoteIssueDialog
          onConfirm={issueCreditNote}
          customerLegalCompanyName={customerLegalCompanyName}
          customerEmails={customerEmails}
          trigger={
            <Button leadingIcon={<EnvelopeIcon width="16px" height="16px" />}>
              Issue credit note
            </Button>
          }
        />
      )}

      <VoidCreditNoteDialog
        key="void-dialog"
        onConfirm={voidCreditNote}
        trigger={openVoidCreditNoteDialog => (
          <SendTestCreditNoteDialog
            key="send-test-email-dialog"
            onConfirm={sendTestCreditNote}
            trigger={openSendTestCreditNoteDialog => {
              return (
                <SequenceKebabMenu
                  width={226}
                  alignment="bottom-right"
                  items={[
                    {
                      label: 'Send test email',
                      onClick: openSendTestCreditNoteDialog
                    },
                    {
                      label: 'Download credit note PDF',
                      onClick: () =>
                        downloadPdf({
                          creditNoteId,
                          creditNoteNumber,
                          creditNoteStatus
                        }),
                      divider: true
                    },
                    {
                      label: 'Void credit note',
                      onClick: openVoidCreditNoteDialog,
                      style: { color: RedRed50 },
                      divider: creditNoteStatus === CREDIT_NOTE_STATUSES.VOIDED
                    }
                  ]}
                />
              )
            }}
          />
        )}
      />
    </Flex>
  )
}
