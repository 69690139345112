import { Flex } from '@chakra-ui/react'
import { FC } from 'react'
import { LineItemGroup } from '@sequencehq/core-components'
import { Currency } from '@sequencehq/core-models'
import { AggregatedLineItemGroup, LineItem } from 'CreditNotes/types'
import { match } from 'ts-pattern'
import { sumBy } from 'lodash'

type Props = {
  lineItemGroups: AggregatedLineItemGroup[]
  updateLineItems: (lineItemsToUpdate: LineItem[]) => void
  currency: Currency
}

export const CreditNoteLineItems: FC<Props> = ({
  lineItemGroups,
  updateLineItems,
  currency
}) => {
  return (
    <Flex gap={4} direction="column">
      {lineItemGroups.map(group => {
        return (
          <LineItemGroup
            key={group.id}
            variant="creditNotes"
            currency={currency}
            onChangeSelected={selected => {
              updateLineItems(
                group.lineItems.map(lineItem => {
                  return { ...lineItem, selected }
                })
              )
            }}
            selected={match(
              group.lineItems.filter(item => item.selected).length
            )
              .with(0, () => false)
              .with(group.lineItems.length, () => true)
              .otherwise(() => 'indeterminate')}
          >
            {group.lineItems.map(lineItem => (
              <LineItemGroup.EditableLineItem
                onChangeQuantity={quantity => {
                  updateLineItems([{ ...lineItem, quantity }])
                }}
                selected={lineItem.selected}
                onChangeSelected={selected => {
                  updateLineItems([{ ...lineItem, selected }])
                }}
                key={lineItem.id}
                title={lineItem.title}
                quantity={lineItem.quantity}
                rate={lineItem.rate}
                rateDisplay={lineItem.rateDisplay}
                netTotal={lineItem.netTotal}
                onChangeTotal={netTotal => {
                  updateLineItems([{ ...lineItem, netTotal }])
                }}
              />
            ))}
            <LineItemGroup.AddLineItem />
            <LineItemGroup.Subtotal value={group.netTotal} />
          </LineItemGroup>
        )
      })}
    </Flex>
  )
}
