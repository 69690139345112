import { Box, Flex } from '@chakra-ui/react'
import { FC } from 'react'
import { Card, Totals } from '@sequencehq/core-components'
import { Currency } from '@sequencehq/core-models'
import { LineItem } from 'CreditNotes/types'
import { toMoney } from '@sequencehq/utils'
import { GreyGrey10, GreyGrey90, Lato20Bold } from '@sequencehq/design-tokens'

type Props = {
  lineItems: LineItem[]
  currency: Currency
  netTotal: string
  totalTax: string
  grossTotal: string
  taxPercentage: string
}

export const CreditNoteAdjustmentTotal: FC<Props> = ({
  lineItems,
  currency,
  netTotal,
  totalTax,
  grossTotal,
  taxPercentage
}) => {
  return (
    <Card variant="v2" py={3}>
      <Flex gap={2} direction="column">
        <h2>Adjustment Total</h2>
        <Box {...Lato20Bold} color={GreyGrey90}>
          {toMoney(
            {
              currency: currency,
              value: grossTotal
            },
            '',
            2
          )}
        </Box>

        <Box
          background={GreyGrey10}
          padding={2}
          borderRadius={8}
          mx={-2}
          mt={2}
        >
          <Totals currency={currency}>
            {lineItems.map(lineItem => (
              <Totals.Row
                key={lineItem.id}
                title={lineItem.title}
                amount={lineItem.netTotal} // TODO: net or gross?
              />
            ))}
            <Totals.Divider />
            <Totals.Row title="Adjustment subtotal" amount={netTotal} />
            <Totals.Tax percentage={taxPercentage} amount={totalTax} />
            <Totals.Divider />
            <Totals.Total title="Adjustment total" amount={grossTotal} />
          </Totals>
        </Box>
      </Flex>
    </Card>
  )
}
