import { InputSelectControl } from '@sequencehq/core-components'
import { InfoPopoverProps } from '@sequencehq/forms'
import { useSeatType } from 'components/SeatType'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFetchSeatMetrics } from 'components/SeatType/useFetchSeatMetrics.ts'

type SeatMetricInputProps = {
  fieldName: string
  infoPopover?: InfoPopoverProps
  disabled?: boolean
}

export const SeatMetricInput: FC<SeatMetricInputProps> = ({ disabled }) => {
  const navigate = useNavigate()
  const { dispatch } = useSeatType()

  const seatMetricsResult = useFetchSeatMetrics()

  const seatMetrics = seatMetricsResult.metrics ?? []

  return (
    <InputSelectControl
      triggerStyle={{ paddingLeft: 16, userSelect: 'none' }}
      onChange={value => {
        dispatch({ type: 'setSeatTypeId', seatTypeId: value })
      }}
      options={seatMetrics.map(item => ({
        value: item.id,
        label: item.label
      }))}
      disabled={
        disabled || !!seatMetricsResult.error || seatMetricsResult.loading
      }
      onCreate={() => navigate('seats/new')}
      placeholder="Select seat metric"
      createLabel="Create new"
    />
  )
}
