import { useCallback } from 'react'
import {
  CustomerCreditBalanceModel,
  InvoiceMerchantDetailsModel,
  InvoiceModel,
  InvoiceUsageItemGroup,
  LineItemGroupModel,
  LineItemModel,
  MerchantModel
} from '@sequencehq/core-models'
import {
  useLazyGetCreditsByCustomerIdBalancesQuery,
  useLazyGetInvoicesByIdLineItemsAllQuery,
  useLazyGetInvoicesByIdQuery,
  useLazyGetInvoicesByIdLineItemGroupsAllQuery,
  useLazyGetInvoiceMerchantDetailsQuery,
  useLazyGetMerchantForSequenceAccountQuery,
  useLazyGetInvoicesByInvoiceIdUsageItemGroupsQuery
} from 'features/api'
import { useFlags } from 'launchdarkly-react-client-sdk'

type UseFetchDataForLocalPdfInvoice = () => {
  fetchData: ({
    invoiceId,
    customerId
  }: {
    invoiceId: string
    customerId: string
  }) => Promise<{
    invoice: InvoiceModel
    lineItems: LineItemModel[]
    lineItemGroups: LineItemGroupModel[]
    creditBalances: CustomerCreditBalanceModel[]
    merchantDetails: InvoiceMerchantDetailsModel
    merchant: MerchantModel
    subAccountUsageBreakdown: InvoiceUsageItemGroup[]
  }>
}

export const useFetchDataForLocalPdfInvoice: UseFetchDataForLocalPdfInvoice =
  () => {
    const flags = useFlags()
    /**
     * Invoice
     */
    const [fetchInvoice] = useLazyGetInvoicesByIdQuery()
    const getInvoice = useCallback(
      (args: { invoiceId: string }) =>
        fetchInvoice({ id: args.invoiceId }).then(response =>
          response.data?.value()
        ),
      [fetchInvoice]
    )

    /**
     * Line items
     */
    const [fetchLineItems] = useLazyGetInvoicesByIdLineItemsAllQuery()
    const getLineItems = useCallback(
      (args: { invoiceId: string }) =>
        fetchLineItems({ id: args.invoiceId }).then(
          response => response.data?.value()?.items ?? []
        ),
      [fetchLineItems]
    )

    /**
     * Line item groups
     */
    const [fetchLineItemGroups] = useLazyGetInvoicesByIdLineItemGroupsAllQuery()
    const getLineItemGroups = useCallback(
      (args: { invoiceId: string }) =>
        fetchLineItemGroups({ id: args.invoiceId }).then(
          response => response.data?.value()?.items ?? []
        ),
      [fetchLineItemGroups]
    )

    /**
     * Merchant details
     */
    const [fetchMerchantDetails] = useLazyGetInvoiceMerchantDetailsQuery()
    const getMerchantDetails = useCallback(
      (args: {
        currency: InvoiceModel['currency']
        country: InvoiceModel['customerBillingAddress']['country']
        state: InvoiceModel['customerBillingAddress']['state']
      }) =>
        fetchMerchantDetails({
          currency: args.currency,
          country: args.country,
          state: args.state
        }).then(response => response.data?.value()),
      [fetchMerchantDetails]
    )

    /**
     * Merchant
     */
    const [fetchMerchant] = useLazyGetMerchantForSequenceAccountQuery()
    const getMerchant = useCallback(
      () => fetchMerchant().then(response => response.data?.value()),
      [fetchMerchant]
    )

    /**
     * Credit balances
     */
    const [fetchCreditBalances] = useLazyGetCreditsByCustomerIdBalancesQuery()
    const getCreditBalances = useCallback(
      (args: { customerId: string }) =>
        fetchCreditBalances({ customerId: args.customerId }).then(
          response => response.data?.value()?.items ?? []
        ),
      [fetchCreditBalances]
    )

    /**
     * Sub account usage data
     */

    const [fetchSubAccountUsage] =
      useLazyGetInvoicesByInvoiceIdUsageItemGroupsQuery()
    const getSubAccountUsageData = useCallback(
      (args: { invoiceId: string }) => {
        if (!flags?.useParentChild) {
          return Promise.resolve([])
        }
        return fetchSubAccountUsage({ invoiceId: args.invoiceId }).then(
          response =>
            (response.data?.value()?.items ?? []) as InvoiceUsageItemGroup[]
        )
      },
      [fetchSubAccountUsage, flags]
    )

    /**
     * Fetch data
     */
    const fetchData = useCallback(
      async ({
        invoiceId,
        customerId
      }: {
        invoiceId: string
        customerId: string
      }) => {
        const fetchedInvoice = await getInvoice({ invoiceId })

        if (typeof fetchedInvoice === 'undefined') {
          throw new Error('fetchedInvoice is undefined')
        }

        const [
          fetchedLineItems,
          fetchedLineItemGroups,
          fetchedCreditBalances,
          fetchedMerchantDetails,
          fetchedMerchant,
          fetchedSubAccountUsageData
        ] = await Promise.all([
          getLineItems({ invoiceId }),
          getLineItemGroups({ invoiceId }),
          getCreditBalances({ customerId }),
          getMerchantDetails({
            currency: fetchedInvoice.currency,
            country: fetchedInvoice.customerBillingAddress.country,
            state: fetchedInvoice.customerBillingAddress.state
          }),
          getMerchant(),
          getSubAccountUsageData({ invoiceId })
        ])

        if (
          typeof fetchedInvoice === 'undefined' ||
          typeof fetchedLineItems === 'undefined' ||
          typeof fetchedLineItemGroups === 'undefined' ||
          typeof fetchedCreditBalances === 'undefined' ||
          typeof fetchedMerchantDetails === 'undefined' ||
          typeof fetchedMerchant === 'undefined'
        ) {
          throw new Error('Incomplete data to generate invoice PDF locally')
        }

        return {
          invoice: fetchedInvoice,
          lineItems: fetchedLineItems,
          lineItemGroups: fetchedLineItemGroups,
          creditBalances: fetchedCreditBalances,
          merchantDetails: fetchedMerchantDetails,
          merchant: fetchedMerchant,
          subAccountUsageBreakdown: fetchedSubAccountUsageData
        }
      },
      [
        getCreditBalances,
        getInvoice,
        getLineItemGroups,
        getLineItems,
        getMerchant,
        getMerchantDetails,
        getSubAccountUsageData
      ]
    )

    return { fetchData }
  }
