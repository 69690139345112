import ArrowTrendingUpIcon from '@heroicons/react/24/outline/ArrowTrendingUpIcon'
import { Badge } from '@sequencehq/core-components'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTableCell,
  MagicTableCellEmpty,
  MagicTableV2,
  useMagicTableInfiniteQuery
} from '@sequencehq/tables'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { EmptyStateContent } from 'components/Loading/EmptyState'
import SeatTableEmptyState from 'components/SeatBalances/common/SeatTableEmptyState'
import { FC } from 'react'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

type SeatTypesMagicTableProps = {
  emptyContent: EmptyStateContent
}

type SeatMetric = DashboardApi20240730.GetSeatMetrics.SeatMetric

export const SeatTypesMagicTable: FC<SeatTypesMagicTableProps> = ({
  emptyContent
}) => {
  const { infiniteQuery, setQueryParams } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getSeatMetrics
  )

  return (
    <CurrentUserId>
      {userId => (
        <MagicTableV2<{ model: SeatMetric }>
          entityNamePlural="seat metrics"
          entityIcon={props => <ArrowTrendingUpIcon {...props} />}
          sequenceUserId={userId}
          infiniteQuery={infiniteQuery}
          onQueryParamsChanged={setQueryParams}
          columns={[
            {
              id: 'label',
              accessorKey: 'label',
              header: 'Name',
              cell: value => (
                <MagicTableCell text={value.getValue<SeatMetric['label']>()} />
              )
            },
            {
              id: 'seatType',
              accessorKey: 'seatType',
              header: 'Seat type',
              cell: value => (
                <MagicTableCellEmpty>
                  <Badge sentiment="neutral" size="sm" textTransform="none">
                    {value.getValue<SeatMetric['seatType']>()}
                  </Badge>
                </MagicTableCellEmpty>
              )
            },
            {
              id: 'createdAt',
              accessorKey: 'createdAt',
              header: 'Created At (UTC)',
              cell: value => {
                const createdAt = value.getValue<SeatMetric['createdAt']>()

                return (
                  <MagicTableCell
                    text={dateTimeWithFormat(
                      createdAt,
                      'dd-MM-yyyy ∙ HH:mm:ss'
                    )}
                  />
                )
              }
            }
          ]}
          emptyState={props => (
            <SeatTableEmptyState emptyContent={emptyContent} {...props} />
          )}
        />
      )}
    </CurrentUserId>
  )
}
