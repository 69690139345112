import { Flex, Grid, GridItem } from '@chakra-ui/react'
import {
  Button,
  Modal,
  ModalContextProvider
} from '@sequencehq/core-components'
import { ReactNode } from 'react'
import { ErrorBoundary } from '@sentry/react'
import PageError from 'components/ErrorPages/PageError'
import { JournalDetailTotals } from './JournalDetailTotals'
import { JournalDetailSummary } from './JournalDetailSummary'
import { ManualJournalModal } from './ManualJournalModal/ManualJournalModal'

const JournalDetailDrawerContent = (props: {
  journalId: string
}) => {
  return (
    <Modal variant="drawer" unmountOnClose>
      <Modal.Header showCloseButton withBorder>
        Overview
      </Modal.Header>
      <ErrorBoundary fallback={PageError}>
        <Modal.Content>
          <Flex flexDirection="column" gap="24px">
            <JournalDetailTotals journalId={props.journalId} />
            <JournalDetailSummary journalId={props.journalId} />
          </Flex>
        </Modal.Content>
      </ErrorBoundary>
      <Modal.Footer>
        <ManualJournalModal
          journalId={props.journalId}
          trigger={open => (
            <Button
              style={{
                width: '100%'
              }}
              onClick={open}
              variant="secondary"
            >
              Create adjustment journal
            </Button>
          )}
        />
      </Modal.Footer>
    </Modal>
  )
}

export const JournalDetailDrawer = (props: {
  trigger: (openFn: () => void) => ReactNode
  journalId: string
}) => {
  return (
    <ModalContextProvider>
      <JournalDetailDrawerContent journalId={props.journalId} />
      <Modal.Trigger>{openFn => props.trigger(openFn)}</Modal.Trigger>
    </ModalContextProvider>
  )
}
