import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey30,
  GreyGrey60,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Badge, Button } from '@sequencehq/core-components'
import { PlusIcon } from '@heroicons/react/16/solid'
import SpinnerContainer from 'components/Loading/SpinnerContainer'
import type { TaxRate } from 'Settings/domain/taxRates.types'
import {
  countriesAlpha2,
  countriesAlpha2Emojis,
  type CountriesAlpha2
} from '@sequencehq/api/commonEnums'
import { percentageFromDecimal } from '@sequencehq/utils'
import TaxRatesModal from 'Settings/view/taxRatesSettings/modals/TaxRatesModal'
import type { CreateTaxRateInput } from 'Settings/view/taxRatesSettings/hooks/useCreateTaxRate'
import type { UpdateTaxRateInput } from '../hooks/useUpdateTaxRate'
import { type PropsWithChildren, useState } from 'react'

interface Props {
  onCreateNewTaxRate: (newTaxRateInput: CreateTaxRateInput) => Promise<void>
  onUpdateTaxRate: (
    updateTaxRateInput: UpdateTaxRateInput,
    taxRatesForCountry?: TaxRate[]
  ) => Promise<void>
  isLoading: boolean
  taxRates?: TaxRate[]
}

export const TaxRatesTableContainer = ({
  disabled = false,
  children
}: PropsWithChildren<{ disabled?: boolean }>) => {
  return (
    <TableContainer
      borderRadius="lg"
      overflow="hidden"
      border={`1px solid ${GreyGrey30}`}
      width="100%"
      pointerEvents={disabled ? 'none' : 'auto'}
    >
      <Table variant="v2" width="100%">
        <Thead opacity={disabled ? '0.5' : '1'}>
          <Tr>
            <Th width="20%" borderRight={`1px solid ${GreyGrey30}`}>
              Country
            </Th>
            <Th width="20%" borderRight={`1px solid ${GreyGrey30}`}>
              Category
            </Th>
            <Th width="10%" borderRight={`1px solid ${GreyGrey30}`}>
              Tax rate
            </Th>
            <Th>Last updated</Th>
          </Tr>
        </Thead>
        <Tbody pointerEvents={disabled ? 'none' : 'auto'}>{children}</Tbody>
      </Table>
    </TableContainer>
  )
}

const TaxRatesTable = ({
  onCreateNewTaxRate,
  onUpdateTaxRate,
  isLoading,
  taxRates = []
}: Props) => {
  const [hoveredCountry, setHoveredCountry] = useState<CountriesAlpha2 | null>(
    null
  )

  if (isLoading) {
    return (
      <Flex justifyContent="center" align="center" width="100%" py={20}>
        <SpinnerContainer />
      </Flex>
    )
  }

  const hasTaxRates = taxRates.length > 0

  const taxRatesWithCategories = taxRates.filter(rate => rate.taxCategoryId)

  const taxRatesSortedByCountry = taxRatesWithCategories.sort((a, b) => {
    const countryNameA = countriesAlpha2[a.country] || a.country
    const countryNameB = countriesAlpha2[b.country] || b.country

    return countryNameA.localeCompare(countryNameB)
  })

  const groupedTaxRates: Record<CountriesAlpha2, TaxRate[]> =
    taxRatesSortedByCountry.reduce(
      (acc, taxRate) => ({
        ...acc,
        [taxRate.country]: [...(acc[taxRate.country] || []), taxRate].sort(
          (a, b) => {
            return (
              a?.taxCategoryName?.localeCompare(b?.taxCategoryName || '') || -1
            )
          }
        )
      }),
      {} as Record<CountriesAlpha2, TaxRate[]>
    )

  return (
    <TaxRatesTableContainer>
      {hasTaxRates ? (
        Object.entries(groupedTaxRates).map(([country, rates]) => (
          <TaxRatesModal
            onSubmit={async (values, taxRatesForCountry) => {
              void (await onUpdateTaxRate?.(values, taxRatesForCountry))
            }}
            key={country}
            taxRatesForCountry={rates}
            trigger={openModal => (
              <TaxRatesTableRow
                key={country}
                country={country as CountriesAlpha2}
                taxRates={rates}
                onClick={openModal}
                isHovered={hoveredCountry === country}
                onHover={() => setHoveredCountry(country as CountriesAlpha2)}
                onLeave={() => setHoveredCountry(null)}
              />
            )}
          />
        ))
      ) : (
        <Tr
          sx={{
            cursor: 'auto!important',
            _hover: {
              bgColor: 'inherit!important'
            }
          }}
        >
          <Td colSpan={4}>
            <Flex width="full" placeContent="center" py={4}>
              <Flex
                width="265px"
                whiteSpace="pre-line"
                flexDirection="column"
                textAlign="center"
                gap={2}
              >
                <Text {...Lato13Bold}>No tax rates to display</Text>
                <Text {...Lato13Regular} color={GreyGrey60}>
                  You have not created any tax rates yet.
                </Text>
                <Flex justifyContent="center">
                  <TaxRatesModal
                    onSubmit={async values => {
                      void (await onCreateNewTaxRate(values))
                    }}
                    trigger={
                      <Button
                        variant="primary"
                        leadingIcon={
                          <PlusIcon
                            width="16px"
                            height="16px"
                            color="inherit"
                          />
                        }
                      >
                        New tax rate
                      </Button>
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
          </Td>
        </Tr>
      )}
    </TaxRatesTableContainer>
  )
}

const TaxRatesTableRow = ({
  country,
  taxRates,
  onClick,
  isHovered,
  onHover,
  onLeave
}: {
  country: CountriesAlpha2
  taxRates: TaxRate[]
  onClick: () => void
  isHovered: boolean
  onHover: () => void
  onLeave: () => void
}) => {
  return taxRates.map((rate, index) => (
    <Tr
      key={rate.id}
      onClick={onClick}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      style={{
        backgroundColor: isHovered ? GreyGrey10 : undefined
      }}
    >
      {index === 0 && (
        <Td rowSpan={taxRates.length} borderRight={`1px solid ${GreyGrey30}`}>
          <Flex gap={2} align="center">
            <Text fontSize="20px">{countriesAlpha2Emojis[country]}</Text>
            <Text {...Lato13Bold} color={IndigoIndigo50}>
              {countriesAlpha2[country]}
            </Text>
          </Flex>
        </Td>
      )}
      <Td borderRight={`1px solid ${GreyGrey30}`}>
        <Badge textTransform="none">{rate.taxCategoryName}</Badge>
      </Td>
      <Td borderRight={`1px solid ${GreyGrey30}`}>
        {percentageFromDecimal(rate.amount)}%
      </Td>
      <Td>
        {rate.updatedAt ? new Date(rate.updatedAt).toLocaleString() : '-'}
      </Td>
    </Tr>
  ))
}

export default TaxRatesTable
