import { Grid } from '@chakra-ui/react'
import { GreyGrey0, GreyGrey30 } from '@sequencehq/design-tokens'
import { InvoicedRevenueGraph } from './InvoicedRevenueGraph'
import { RecognizedRevenueGraph } from './RecognizedRevenueGraph'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'
import { useInvoicedRevenueData } from './useInvoicedRevenueData'
import { useRecognizedRevenueData } from './useRecognizedRevenueData'
import { useMemo } from 'react'
import { Skeleton } from '@sequencehq/core-components'
import { calculateYAxisLabels } from 'RevenueRecognition/view/utils/graphUtils'

export const RevenueOverviewGraphs = () => {
  const { currency } = useSelectedCurrency()
  const { data: invoicedRevenue, isLoading: isLoadingInvoicedRevenue } =
    useInvoicedRevenueData({
      currency
    })
  const { data: recognizedRevenue, isLoading: isLoadingRecognizedRevenue } =
    useRecognizedRevenueData({
      currency
    })

  /**
   * We keep the two graphs on the same relative scale by grabbing the totals
   * for both, and calculating the respective y-axis labels from the maximum
   * of the two.
   */
  const yAxisLabels = useMemo(() => {
    if (isLoadingInvoicedRevenue || isLoadingRecognizedRevenue) {
      return [0, 0]
    }

    return calculateYAxisLabels([
      ...invoicedRevenue.invoicedRevenueByMonth.map(ir => ir.value),
      ...recognizedRevenue.recognizedRevenueByMonth.map(rr => rr.value)
    ])
  }, [
    invoicedRevenue?.invoicedRevenueByMonth,
    isLoadingInvoicedRevenue,
    isLoadingRecognizedRevenue,
    recognizedRevenue?.recognizedRevenueByMonth
  ])

  return (
    <Grid
      gridTemplateColumns="1fr 1fr"
      gridColumnGap="16px"
      padding="12px 16px"
      backgroundColor={GreyGrey0}
      borderBottom={`1px solid ${GreyGrey30}`}
    >
      {currency ? (
        <>
          <RecognizedRevenueGraph
            currency={currency}
            yAxisLabels={yAxisLabels}
          />
          <InvoicedRevenueGraph currency={currency} yAxisLabels={yAxisLabels} />
        </>
      ) : (
        <>
          <Skeleton width="100%" height="291px" />
          <Skeleton width="100%" height="291px" />
        </>
      )}
    </Grid>
  )
}
