import { Box, Checkbox, Flex, FormLabel, Link, Text } from '@chakra-ui/react'
import { SeatSnapshotModel } from '@sequencehq/core-models'
import {
  IndigoIndigo50,
  Lato14Bold,
  Lato14ExtendedRegular
} from '@sequencehq/design-tokens'
import { TextInputField } from '@sequencehq/forms'
import { isPositiveInteger, required, skip } from '@sequencehq/validation'
import { Blocker } from 'components/Form'
import { CustomerAliasComboInput } from 'components/FormComponents/CustomerAliasComboInput'
import { CustomerComboInputComplete } from 'components/FormComponents/CustomerComboInputComplete'
import { DateInputField } from 'components/FormFields'
import { DrawerForm, useHandleMutationResult } from 'components/Modal'
import { SeatMetricInput } from 'components/SeatType/SeatMetricInput.tsx'
import { useSeatType } from 'components/SeatType/SeatTypeProvider'
import { usePostSeatSnapshotsMutation } from 'features/api'
import arrayMutators from 'final-form-arrays'
import { handleFormResponse } from 'lib/formValidation'
import { ExtractMutationParams } from 'lib/types'
import { FC, useCallback, useState } from 'react'
import { Form } from 'react-final-form'

type FormValues = {
  customerAlias: string
  customerId: string
  seatType: string
  snapshotTimestamp: string
  total: number
}

type CreateSeatSnapshotFormProps = {
  onSuccess: (seatType: SeatSnapshotModel) => void
  handleCancel: () => void
}

type MutationParams = ExtractMutationParams<typeof usePostSeatSnapshotsMutation>

export const CreateSeatSnapshotForm: FC<CreateSeatSnapshotFormProps> = ({
  onSuccess,
  handleCancel
}) => {
  const [submitForm, result] = usePostSeatSnapshotsMutation()
  const [withAlias, setWithAlias] = useState<boolean>(false)

  const { state } = useSeatType()
  const { seatType } = state

  useHandleMutationResult<MutationParams>({
    result,
    successMessage: useCallback(() => `Seat event created`, []),
    onSuccess
  })

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      destroyOnUnregister
      mutators={{
        ...arrayMutators
      }}
      onSubmit={async (values, form) => {
        if (seatType) {
          const res = await submitForm({
            createSeatSnapshotEndpointCreateSeatSnapshotRequestModel: {
              ...values,
              customerAlias: withAlias
                ? values.customerAlias
                : values.customerId,
              seatType: seatType.key,
              seatChanges: []
            }
          })

          return handleFormResponse(res, form.getRegisteredFields())
        }
      }}
      render={formProps => (
        <DrawerForm
          {...formProps}
          title="Create seat event"
          submitLabel="Create seat event"
          handleCancel={handleCancel}
          drawerStyles={{
            maxWidth: '520px'
          }}
        >
          <Blocker
            name="seatSnapshot"
            dirty={formProps.dirty}
            submitting={formProps.submitting}
            modalTitle="Cancel seat assingment editing"
            message="Progress you made so far will not be saved."
            submitTitle="Yes, Cancel"
            cancelTitle="Keep editing"
            ignoreBlock={({ pathname }) => {
              return pathname.startsWith('/seats/balances/new')
            }}
          />

          <Flex direction="column" gap="16px">
            <Text {...Lato14ExtendedRegular} color="inherit">
              Use the 'Seat Events' API endpoint to programmatically submit seat
              events to update the balance for a customer.{' '}
              <Link
                href="https://docs.sequencehq.com/seats/seat-updates"
                isExternal
                color={IndigoIndigo50}
              >
                Learn more
              </Link>
            </Text>

            <CustomerComboInputComplete
              fieldName="customerId"
              disabled={withAlias}
              validate={withAlias ? skip : required}
              labelPadding={0}
            />

            <Checkbox
              checked={withAlias}
              onChange={event => {
                setWithAlias(event.target.checked)
              }}
            >
              Use customer alias instead
            </Checkbox>
            {withAlias ? (
              <>
                <CustomerAliasComboInput
                  fieldName="customerAlias"
                  labelPadding={0}
                />
              </>
            ) : null}

            <DateInputField
              fieldName="snapshotTimestamp"
              fieldLabel="Event timestamp"
              validate={required}
              isSelectable={() => true}
            />

            <Box>
              <Text {...Lato14Bold} paddingRight="8px" mb={2}>
                Seat metric
              </Text>
              <SeatMetricInput fieldName="seatType" />
            </Box>

            <FormLabel>Total seats</FormLabel>
            <TextInputField fieldName="total" validate={isPositiveInteger} />
          </Flex>
        </DrawerForm>
      )}
    />
  )
}
