import { FormFields, useForm } from '@sequencehq/utils'
import {
  greaterThan,
  greaterThanEqualTo,
  required
} from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import {
  AvailableStandardFrequency,
  BillingType,
  PricingEditorReducerState,
  SeatBasedProrationStrategy
} from 'common/drawers/PricingEditor/domain'
import {
  defaultAvailableFrequenciesOptions,
  PRICING_MODEL_OPTIONS
} from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useMemo } from 'react'
import { useCommonFieldConfig } from '../common/useCommonFieldConfig'
import deepmerge from 'deepmerge'
import { useNavigate } from 'react-router-dom'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { getAvailableSeatOveragesFrequenciesFromSelectedPriceFrequency } from './seatBasedPrice.utils'
import { tiersValidator } from 'common/drawers/PricingEditor/utils/validators/tiers.validators'
import { commonPriceDetails } from '../common/ReadOnlyDetails/readOnlyDetails.utils'
import { useFetchSeatMetrics } from 'components/SeatType/useFetchSeatMetrics.ts'

type CommonFields =
  PricingEditorReducerState['data']['pricingEditorData']['common']
type SeatBasedPriceFields =
  PricingEditorReducerState['data']['pricingEditorData']['SEAT_BASED_GRADUATED']

type SeatBasedPriceFormFieldConfig = Omit<
  FormFields<CommonFields>,
  'pricingModel'
> & {
  pricingModel: FormFields<CommonFields>['pricingModel'] & {
    description: string
  }
} & Omit<
    FormFields<SeatBasedPriceFields>,
    'seatTypeId' | 'overagesBillingFrequency' | 'prorationStrategy'
  > & {
    seatMetricId: FormFields<SeatBasedPriceFields>['seatMetricId'] & {
      onAddNew: () => void
    }
    overagesBillingFrequency: Omit<
      FormFields<SeatBasedPriceFields>['overagesBillingFrequency'],
      'options'
    > & {
      options: { value: string; label: string; description: string }[]
      selected: { value: string; label: string; description: string }
    }
    prorationStrategy: Omit<
      FormFields<SeatBasedPriceFields>['prorationStrategy'],
      'options'
    > & {
      options: { value: string; label: string; description: string }[]
      selected: { value: string; label: string; description: string }
    }
    pricePerSeat: FormFields<SeatBasedPriceFields>['pricePerSeat'] & {
      hidden: boolean
    }
  }

type UseSeatBasedPriceForm = () => {
  fieldsConfig: SeatBasedPriceFormFieldConfig
  conditions: {
    isInAdvanceSelected: boolean
    isContractedMinimumSet: boolean
    isBillingFrequencyAnnuallyOrQuarterly: boolean
    isProrateStrategyIncrements: boolean
  }
  currency: Currency
  priceDetails: { label: string; value: string }[]
}

const standardPriceFrequencies: {
  value: AvailableStandardFrequency
  label: string
}[] = [
  {
    value: 'ONE_TIME',
    label: 'Once'
  },
  ...defaultAvailableFrequenciesOptions
]

const availableBillingTypeOptions: { value: BillingType; label: string }[] = [
  { label: 'In arrears', value: 'IN_ARREARS' },
  { label: 'In advance', value: 'IN_ADVANCE' }
]

export const prorationStrategyOptions: {
  value: SeatBasedProrationStrategy
  label: string
  description: string
}[] = [
  {
    value: 'USE_FIRST',
    label: 'Charge in next billing period (no proration)',
    description:
      'Added or removed seat charges take effect in the next billing period.'
  },
  {
    value: 'USE_MAXIMUM',
    label: 'Charge full amount (no proration)',
    description:
      "'Charge the full monthly price for any seats added in the current billing period.'"
  },
  {
    value: 'PRORATE_INCREMENTS',
    label: 'Prorate added seats only (recommended)',
    description:
      'Charge a prorated fee for seats added in the billing period. Removed seats are charged the full fee.'
  },
  {
    value: 'PRORATE_ALL_CHANGES',
    label: 'Prorate added and removed seats',
    description:
      'Charge a prorated fee for any added and removed seats in the billing period.'
  }
]

export const useSeatBasedPriceForm: UseSeatBasedPriceForm = () => {
  const pricingEditorContext = usePricingEditorDomainContext()
  const seatMetricsLoader = useFetchSeatMetrics()
  const navigate = useNavigate()

  const availableFrequencies = useMemo(() => {
    return standardPriceFrequencies.filter(frequency => {
      return pricingEditorContext.queries.rawData.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [
    pricingEditorContext.queries.rawData.configuration
      .availableStandardFrequencies
  ])

  const seatTypeOptions = useMemo(() => {
    if (!seatMetricsLoader.metrics) {
      return []
    }

    return (
      seatMetricsLoader.metrics?.map(seatMetric => ({
        value: seatMetric.id,
        label: seatMetric.label
      })) ?? []
    )
  }, [seatMetricsLoader.metrics])

  const pricingModel = useMemo(
    () =>
      pricingEditorContext.queries.rawData.data.pricingEditorData.common
        .pricingModel,
    [
      pricingEditorContext.queries.rawData.data.pricingEditorData.common
        .pricingModel
    ]
  )

  const commonFields = useCommonFieldConfig<
    SeatBasedPriceFields & CommonFields
  >()

  const { fields, queries } = useForm({
    value: {
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.common,
      ...(pricingEditorContext.queries.rawData.data.pricingEditorData[
        pricingModel
      ] as SeatBasedPriceFields & CommonFields)
    },
    fieldConfiguration: [
      ...commonFields.fields,
      {
        property: 'billingFrequency',
        validation: [required],
        options: availableFrequencies
      },
      {
        property: 'billingType',
        validation: [required],
        options: availableBillingTypeOptions
      },
      {
        property: 'pricePerSeat',
        validation:
          pricingModel === 'SEAT_BASED_LINEAR'
            ? [required, greaterThan(0, "Price per seat can't be zero")]
            : []
      },
      {
        property: 'tiers',
        validation:
          pricingModel === 'SEAT_BASED_GRADUATED'
            ? [required, tiersValidator]
            : []
      },
      {
        property: 'contractedMinimumSeats',
        validation: [
          required,
          greaterThanEqualTo(0, 'Please enter at least 0 seats')
        ]
      },
      {
        property: 'prorationStrategy',
        validation: [required]
      },
      {
        property: 'overagesBillingFrequency',
        validation: [required]
      },
      {
        property: 'seatMetricId',
        validation: [required],
        options: seatTypeOptions
      }
    ],
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    disabled:
      !pricingEditorContext.queries.availableFeatures.common.form.available
        .enabled,
    onValidationStateChange: isValid => {
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      })
    },
    onChange: newData => {
      const { name, pricingModel: newPricingModel, currency, ...rest } = newData

      pricingEditorContext.mutators.common.updatePricingEditorData({
        common: {
          name,
          pricingModel: newPricingModel,
          currency
        },
        [pricingModel]: rest
      })
    }
  })

  const overagesFrequencies = useMemo(() => {
    return getAvailableSeatOveragesFrequenciesFromSelectedPriceFrequency(
      queries.formData.billingFrequency
    )
  }, [queries.formData.billingFrequency])

  const enhancedFields = useMemo(() => {
    return deepmerge(
      fields,
      {
        currency: {
          ...fields.currency,
          options: fields.currency.options.map(option => ({
            ...option,
            label: option.label.split(' ')[0]
          }))
        },
        pricingModel: {
          ...fields.pricingModel,
          description:
            PRICING_MODEL_OPTIONS.find(
              option => option.value === queries.formData.pricingModel
            )?.description ?? ''
        },
        seatMetricId: {
          onAddNew: () => navigate('./seats/new')
        },
        prorationStrategy: {
          options: prorationStrategyOptions,
          selected: prorationStrategyOptions.find(
            opt => queries.formData.prorationStrategy === opt.value
          )
        },
        overagesBillingFrequency: {
          options: overagesFrequencies,
          selected: overagesFrequencies.find(
            opt => queries.formData.overagesBillingFrequency === opt.value
          )
        },
        pricePerSeat: {
          hidden: pricingModel === 'SEAT_BASED_GRADUATED'
        }
      },
      {
        arrayMerge: (_, source: unknown[]) => source
      }
    ) as SeatBasedPriceFormFieldConfig
  }, [
    fields,
    navigate,
    overagesFrequencies,
    pricingModel,
    queries.formData.overagesBillingFrequency,
    queries.formData.pricingModel,
    queries.formData.prorationStrategy
  ])

  const isInAdvanceSelected = useMemo(
    () => queries.formData.billingType == 'IN_ADVANCE',
    [queries.formData.billingType]
  )
  const isContractedMinimumSet = useMemo(
    () => parseInt(queries.formData.contractedMinimumSeats) > 0,
    [queries.formData.contractedMinimumSeats]
  )
  const isBillingFrequencyAnnuallyOrQuarterly = useMemo(
    () =>
      isInAdvanceSelected &&
      ['QUARTERLY', 'YEARLY'].includes(queries.formData.billingFrequency),
    [isInAdvanceSelected, queries.formData.billingFrequency]
  )
  const isProrateStrategyIncrements = useMemo(
    () => queries.formData.prorationStrategy === 'PRORATE_INCREMENTS',
    [queries.formData.prorationStrategy]
  )

  const priceDetails = useMemo(
    () => [
      ...commonPriceDetails({
        pricingModel: queries.formData.pricingModel,
        billingFrequency: queries.formData.billingFrequency,
        billingType: queries.formData.billingType
      }),
      {
        label: 'Seat metric',
        value:
          fields.seatMetricId.options.find(
            opt => opt.value === queries.formData.seatMetricId
          )?.label ?? 'Not selected'
      },
      {
        label: 'Prorate overage fees',
        value:
          queries.formData.prorationStrategy === 'PRORATE_INCREMENTS'
            ? 'Yes'
            : 'No'
      }
    ],
    [
      fields.seatMetricId.options,
      queries.formData.billingFrequency,
      queries.formData.billingType,
      queries.formData.pricingModel,
      queries.formData.prorationStrategy,
      queries.formData.seatMetricId
    ]
  )

  return {
    fieldsConfig: enhancedFields,
    currency: queries.formData.currency,
    conditions: {
      isInAdvanceSelected,
      isContractedMinimumSet,
      isBillingFrequencyAnnuallyOrQuarterly,
      isProrateStrategyIncrements
    },
    priceDetails
  }
}
