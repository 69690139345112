import { SimpleDialog } from '@sequencehq/core-components'
import { Text } from '@chakra-ui/react'
import { GreyGrey80, Lato13Regular } from '@sequencehq/design-tokens'

interface Props {
  onConfirm: VoidFunction
  trigger: (openDialog: () => void) => React.ReactNode
}

export const VoidCreditNoteDialog = ({ onConfirm, trigger }: Props) => {
  return (
    <SimpleDialog
      title="Void credit note"
      confirmButtonText="Void credit note"
      destructive
      onConfirm={onConfirm}
      closeOnCancelOrSubmit
      trigger={trigger}
    >
      <Text {...Lato13Regular} color={GreyGrey80}>
        You won't be able to make any further changes to this credit note after
        voiding.
      </Text>
    </SimpleDialog>
  )
}
