import { Flex, Box, Text } from '@chakra-ui/react'
import { Card } from '@sequencehq/core-components'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { Address } from '@sequencehq/api/utils/commonModels'
import { formatAddressForInvoice } from '@sequencehq/invoice-content'
import {
  GreyGrey60,
  GreyGrey80,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Link } from 'react-router-dom'

interface Props {
  customerId: string
  customerEmails: string[]
  customerAddress: Address
  creditNoteNumber?: string
  creditNoteIssueDate?: string
  currency: string
  customerTaxId?: string
  invoiceNumber?: string
  invoiceId?: string
}

export const CreditNoteDetailsSummaryCard = ({
  customerId,
  creditNoteIssueDate,
  creditNoteNumber,
  currency,
  customerEmails,
  customerAddress,
  customerTaxId,
  invoiceNumber,
  invoiceId
}: Props) => {
  return (
    <Card px={4} py={3} variant="v2">
      <Flex gap={4}>
        <Flex width="50%">
          <Flex
            alignContent="center"
            alignItems="center"
            width="140px"
            height="28px"
          >
            <Text {...Lato13Regular} color={GreyGrey60}>
              Credited to
            </Text>
          </Flex>
          <Flex flexDirection="column" gap={1} {...Lato13Regular}>
            <CustomerPreviewCardPill customerId={customerId} />
            <Text>{customerEmails[0]}</Text>
            <Flex flexDirection="column" gap={1}>
              {formatAddressForInvoice(customerAddress).map(
                (textValue, index) => (
                  <Text
                    {...Lato13Regular}
                    color={GreyGrey80}
                    key={`${index}-${textValue}`}
                    style={{
                      overflowWrap: 'anywhere'
                    }}
                  >
                    {textValue}
                  </Text>
                )
              )}
            </Flex>
            {customerTaxId && <Text>Tax ID: {customerTaxId}</Text>}
          </Flex>
        </Flex>

        <Flex flexDirection="column" width="50%">
          <SummaryRow
            label="Credit note"
            value={
              <Text
                {...Lato13Regular}
                color={creditNoteNumber ? GreyGrey90 : GreyGrey60}
              >
                {creditNoteNumber || 'Pending'}
              </Text>
            }
          />
          {invoiceId && invoiceNumber ? (
            <SummaryRow
              label="Apploed to invoice"
              value={
                <Link to={`/invoices/${invoiceId}`}>
                  <Text {...Lato13Bold} color={IndigoIndigo50}>
                    {invoiceNumber}
                  </Text>
                </Link>
              }
            />
          ) : null}
          <SummaryRow
            label="Issue date"
            value={<Text {...Lato13Regular}>{creditNoteIssueDate || '-'}</Text>}
          />
          <SummaryRow
            label="Currency"
            value={<Text {...Lato13Regular}>{currency}</Text>}
          />
          <SummaryRow
            label="Tax calculation"
            value={
              <Text
                {...Lato13Regular}
                color={customerTaxId ? GreyGrey90 : GreyGrey60}
              >
                {customerTaxId ? 'Taxed' : 'Untaxed'}
              </Text>
            }
          />
        </Flex>
      </Flex>
    </Card>
  )
}

const SummaryRow = ({
  label,
  value
}: { label: string; value: React.ReactNode }) => {
  return (
    <Flex height="28px" alignItems="center" alignContent="center">
      <Box width="140px">
        <Text {...Lato13Regular} color={GreyGrey60}>
          {label}
        </Text>
      </Box>
      {value}
    </Flex>
  )
}
