import { Box, Flex } from '@chakra-ui/react'
import {
  InputSelectControl,
  PageTitle,
  StandardLayout
} from '@sequencehq/core-components'
import { useAvailableCurrencies } from '../utils/useAvailableCurrencies'
import { useSelectedCurrency } from '../utils/useSelectedCurrency'
import { Currency } from '@sequencehq/core-models'

export const JournalReportsHeader = () => {
  const { availableCurrencies } = useAvailableCurrencies()
  const { currency, setCurrency } = useSelectedCurrency()

  return (
    <Box>
      <StandardLayout.Header
        title={
          <>
            <PageTitle title={['Revenue Recognition']} />
            <StandardLayout.Title>Journal Reports</StandardLayout.Title>
          </>
        }
        actions={
          <Flex gap="8px">
            <Box width="84px">
              {!!currency && !!availableCurrencies && (
                <InputSelectControl
                  data-testid="revrec.home.currencySelect"
                  options={
                    availableCurrencies?.map(ac => ({
                      label: ac,
                      value: ac
                    })) ?? []
                  }
                  initialValue={currency}
                  onChange={newCurrency => setCurrency(newCurrency as Currency)}
                />
              )}
            </Box>
          </Flex>
        }
      />
    </Box>
  )
}
