import { Dashboardv20240509Api } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { RecursivePartial } from 'modules/Cube/domain/cube.domain.types'
import { EditorMode as PriceEditorMode } from 'common/drawers/PricingEditor/domain'
import { ListPrice } from 'Products/types'

export type { RecursivePartial }

export enum ListPriceEditorMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  REVIEW = 'REVIEW',
  VIEW = 'VIEW'
}

export type ValidationError = {
  fieldName: string
  message: string
  metadata?: Record<string, unknown>
  value: unknown
}

export type ApiListPrice = Dashboardv20240509Api.GetListPrice.ListPrice
export type ApiListPriceTier =
  | Dashboardv20240509Api.CommonModels.PricingStructure.GraduatedPricingTier
  | Dashboardv20240509Api.CommonModels.PricingStructure.VolumeFixedPricingTier
  | Dashboardv20240509Api.CommonModels.PricingStructure.VolumePercentagePricingTier
  | Dashboardv20240509Api.CommonModels.PricingStructure.SeatBasePricingTier

export type NewListPriceEditorProps = {
  initialPriceEditorMode: PriceEditorMode
  onSave: (data: { price: ListPrice }) => void
}

export type ListPriceEditorProps = {
  canUseListPrices: boolean
  productId: string
  mode: ListPriceEditorMode
  existingData?: {
    product?: Product
    price?: Price
  }
  onClose: () => void
  onSave: (price: Price) => void | Promise<void>
}

export type ListPriceEditorReducerState = {
  data: {
    prices: Record<Price['id'], Price>
    metrics: Record<UsageMetric['id'], UsageMetric>
    product: Product
    formData: ListPriceEditorFormData
    ledgerIntegrations: Record<
      ListPriceEditorLedgerIntegration['id'],
      ListPriceEditorLedgerIntegration
    >
  }
  derived: {
    queries: {
      selectedPrice: Price | null
      existingPrices: Record<Price['id'], Price>
      availableFeatures: {
        review: boolean
        save: boolean
      }
      priceHasBeenUpdated: boolean
      canSave: boolean
    }
  }
  configuration: {
    currency: Currency
    availableCurrencies: Currency[]
    availableStandardFrequencies: AvailableStandardFrequency[]
    mode: ListPriceEditorMode
  }
  editor: {
    formsValid: Partial<
      Record<
        keyof ListPriceEditorFormData | 'product' | 'externalLedger',
        boolean
      >
    >
    loaded: boolean
    selectedPrice: Price['id'] | null
    showValidationErrors: boolean
  }
  initialData: {
    price: Price | null
    product: Product
  }
}

export type Action<T extends string, P> = {
  type: T
  payload: P
}

export type ActionHandler<A extends ListPriceEditorActions> = (
  prevState: ListPriceEditorReducerState
) => (action: A) => ListPriceEditorReducerState

export type LoadListPriceEditorAction = Action<
  'loadListPriceEditor',
  Pick<ListPriceEditorReducerState, 'data' | 'configuration' | 'editor'>
>

export type UpdateEditor = Action<
  'updateEditor',
  RecursivePartial<ListPriceEditorReducerState['editor']>
>

export type UpdateConfiguration = Action<
  'updateConfiguration',
  RecursivePartial<ListPriceEditorReducerState['configuration']>
>

export type UpdateData = Action<
  'updateData',
  RecursivePartial<ListPriceEditorReducerState['data']>
>

export type ListPriceEditorActions =
  | LoadListPriceEditorAction
  | UpdateEditor
  | UpdateConfiguration
  | UpdateData

export type PricingModel =
  | 'STANDARD'
  | 'LINEAR'
  | 'VOLUME'
  | 'GRADUATED'
  | 'PACKAGED'
  | 'SEAT_BASED_LINEAR'
  | 'SEAT_BASED_GRADUATED'

export type BillingType = 'IN_ADVANCE' | 'IN_ARREARS'

type BasePrice<PM extends PricingModel, S> = {
  id: string
  common: {
    pricingModel: PM
    currency: Currency
    name: string
    externalIds: Record<ListPriceEditorLedgerIntegration['id'], string>
  }
  modelSpecific: S
}

export type AvailableStandardFrequency =
  | 'ONE_TIME'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'

export type UsageTierType = 'FIXED' | 'PERCENTAGE'

export type LinearPriceType = 'FIXED' | 'PERCENTAGE'

export type UsageCalculationMode = 'BILLING_PERIOD' | 'CUMULATIVE' | 'PERIODIC'

export type UsageCalculationFrequency = 'MONTHLY' | 'QUARTERLY' | 'YEARLY'

type ProrationStrategy =
  | 'USE_FIRST'
  | 'USE_MAXIMUM'
  | 'PRORATE_INCREMENTS'
  | 'PRORATE_ALL_CHANGES'

export type ListPriceEditorTier = {
  id: string
  firstUnit: string
  lastUnit: string
  unitPrice: string
  flatFee: string
}

export type ListPriceEditorPercentageTier = {
  id: string
  firstUnit: string
  lastUnit: string
  maxPrice: string
  minPrice: string
  unitPercentage: string
  flatFee: string
}

export type StandardPrice = BasePrice<
  'STANDARD',
  {
    price: string
    billingType: BillingType
    billingFrequency: AvailableStandardFrequency | 'ONE_TIME'
  }
>
export type LinearPrice = BasePrice<
  'LINEAR',
  {
    price: string
    percentage: string
    usageMetricId: string
    linearPriceType: LinearPriceType
    billingFrequency: AvailableStandardFrequency
    billingType: 'IN_ARREARS'
    minPrice: string
    maxPrice: string
    parameters: Record<string, string>
  }
>
export type GraduatedPrice = BasePrice<
  'GRADUATED',
  {
    usageMetricId: string
    tiers: ListPriceEditorTier[]
    percentageTiers: ListPriceEditorPercentageTier[]
    usageTierType: UsageTierType
    usageCalculationMode: UsageCalculationMode
    usageCalculationPeriod?: {
      frequency: UsageCalculationFrequency
      interval: number
    }
    billingFrequency: AvailableStandardFrequency
    billingType: 'IN_ARREARS'
    parameters: Record<string, string>
  }
>
export type VolumePrice = BasePrice<
  'VOLUME',
  {
    usageMetricId: string
    tiers: ListPriceEditorTier[]
    percentageTiers: ListPriceEditorPercentageTier[]
    usageTierType: UsageTierType
    billingFrequency: AvailableStandardFrequency
    billingType: 'IN_ARREARS'
    includePercentageLimits: boolean
    parameters: Record<string, string>
  }
>
export type PackagedPrice = BasePrice<
  'PACKAGED',
  {
    usageMetricId: string
    pricePerPackage: string
    packageSize: string
    billingFrequency: AvailableStandardFrequency
    billingType: 'IN_ARREARS'
    parameters: Record<string, string>
  }
>
export type SeatOveragesBillingFrequency = 'NEVER' | AvailableStandardFrequency

export type SeatBasedProrationStrategy = ProrationStrategy
export type SeatBasedLinearPrice = BasePrice<
  'SEAT_BASED_LINEAR',
  {
    seatTypeId: string
    pricePerSeat: string
    minimumSeats: string
    prorationStrategy: SeatBasedProrationStrategy
    overagesBillingFrequency?: SeatOveragesBillingFrequency
    billingFrequency: AvailableStandardFrequency
    billingType: BillingType
  }
>
export type SeatBasedGraduatedPrice = BasePrice<
  'SEAT_BASED_GRADUATED',
  {
    seatTypeId: string
    pricePerSeat: string
    minimumSeats: string
    prorationStrategy: SeatBasedProrationStrategy
    overagesBillingFrequency?: SeatOveragesBillingFrequency
    billingFrequency: AvailableStandardFrequency
    billingType: BillingType
    tiers: ListPriceEditorTier[]
  }
>
export type ListPriceEditorPrice =
  | StandardPrice
  | GraduatedPrice
  | VolumePrice
  | PackagedPrice
  | SeatBasedLinearPrice
  | SeatBasedGraduatedPrice
  | LinearPrice

export type ApiLedgerIntegrationAccount = {
  id: string
  name: string
  code: string
}

export type ListPriceEditorLedgerIntegration = {
  id: string
  defaultLedgerAccount?: string
  options: {
    id: string
    name: string
    code: string
  }[]
}

type UsageMetricParameter = {
  id: string
  usageMetricId: string
  name: string
  type: 'INTEGER' | 'POSITIVE_INTEGER'
  description: string
  defaultValue: string
}

export type UsageMetric = {
  id: string
  name: string
  aggregationType: 'COUNT' | 'UNIQUE' | 'SUM' | 'CUSTOM'
  parameters: Array<UsageMetricParameter>
}

export const availableBillingTypeOptions: {
  value: BillingType
  label: string
}[] = [
  { label: 'In arrears', value: 'IN_ARREARS' },
  { label: 'In advance', value: 'IN_ADVANCE' }
]

export type Product = {
  id: string
  name: string
  label?: string
}

export type Price =
  | StandardPrice
  | LinearPrice
  | GraduatedPrice
  | VolumePrice
  | PackagedPrice
  | SeatBasedGraduatedPrice
  | SeatBasedLinearPrice

export type ListPriceEditorFormData = {
  common: {
    name: string
    pricingModel: PricingModel
    currency: Currency
    externalIds: Record<ListPriceEditorLedgerIntegration['id'], string>
  }
  STANDARD: StandardPrice['modelSpecific']
  LINEAR: LinearPrice['modelSpecific']
  VOLUME: VolumePrice['modelSpecific']
  GRADUATED: GraduatedPrice['modelSpecific']
  PACKAGED: PackagedPrice['modelSpecific']
  SEAT_BASED_LINEAR: SeatBasedLinearPrice['modelSpecific']
  SEAT_BASED_GRADUATED: SeatBasedGraduatedPrice['modelSpecific']
}

export type PostActionContext = {
  preActionState: ListPriceEditorReducerState
  action: ListPriceEditorActions
}
export type PostActionStage = (
  ctx: PostActionContext
) => (prevState: ListPriceEditorReducerState) => ListPriceEditorReducerState
