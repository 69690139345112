import { useMemo } from 'react'
import { Flex } from '@chakra-ui/react'
import { SeatSnapshotModel } from '@sequencehq/core-models'
import {
  GreyGrey10,
  RedRed60,
  TurquoiseTurquoise80
} from '@sequencehq/design-tokens'
import JSONPretty from 'react-json-pretty'

const SnapshotData = ({ snapshot }: { snapshot: SeatSnapshotModel }) => {
  /**
   * Removes attributes that will be removed in the upcoming API changes,
   * but will still be returned as we'll lock onto an older version of the API --
   * mostly doing this for consistency!
   */
  const filteredSnapshot = useMemo(
    () =>
      Object.keys(snapshot).reduce((acc, key) => {
        if (['sequenceAccountId', 'sequenceUserId'].includes(key)) {
          return acc
        }

        return {
          ...acc,
          [key]: snapshot[key as keyof SeatSnapshotModel]
        }
      }, {}),
    [snapshot]
  )

  return (
    <Flex
      p={5}
      bg={GreyGrey10}
      borderRadius="6px"
      gap="12px"
      flexDirection="column"
      sx={{
        maxHeight: 'calc( 100vh - 460px )',
        overflowY: 'auto'
      }}
    >
      <JSONPretty
        id="json-pretty"
        data={filteredSnapshot}
        theme={{
          main: 'font-size: 14px; line-height: 20px;white-space: pre-wrap; ',
          key: `color:${TurquoiseTurquoise80};`,
          string: `color:${RedRed60};`
        }}
      />
    </Flex>
  )
}

export default SnapshotData
