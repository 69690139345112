import { FC } from 'react'

interface TriangleDownIconProps {
  width?: number
  height?: number
  color?: string
}

export const TriangleDownIcon: FC<TriangleDownIconProps> = ({
  width = 10,
  height = 8,
  color
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.17801 6.81355C4.57569 7.38756 5.42431 7.38756 5.82199 6.81355L9.10877 2.06949C9.56825 1.40629 9.0936 0.5 8.28678 0.5H1.71322C0.906401 0.5 0.431746 1.40629 0.891226 2.06949L4.17801 6.81355Z"
      fill={color}
    />
  </svg>
)
