import { SimpleDialog } from '@sequencehq/core-components'
import { FC } from 'react'
import { Text } from '@chakra-ui/react'
import { GreyGrey80, Lato13Regular } from '@sequencehq/design-tokens'

type Props = {
  onConfirm: VoidFunction
  trigger: React.ReactNode
  customerLegalCompanyName: string
  customerEmails: string[]
  onCancel?: VoidFunction
}

export const CreditNoteIssueDialog: FC<Props> = ({
  onConfirm,
  trigger,
  customerLegalCompanyName,
  customerEmails
}) => {
  return (
    <SimpleDialog
      title="Issue a credit note"
      onConfirm={onConfirm}
      trigger={trigger}
    >
      <Text {...Lato13Regular} color={GreyGrey80}>
        Email this credit note to <b>{customerLegalCompanyName}</b> (
        {customerEmails.join(', ')}). After sending you can no longer edit the
        credit note
      </Text>
    </SimpleDialog>
  )
}
