import { Flex } from '@chakra-ui/react'
import { GreyGrey0 } from '@sequencehq/design-tokens'

export const navigationWidth = 190

export const Container = ({ children }: { children: React.ReactNode }) => (
  <Flex
    direction="column"
    justifyContent="space-between"
    width={`${navigationWidth}px`}
    height="100vh"
    bg={GreyGrey0}
    flexShrink="0"
    position="sticky"
    top="0"
    left="0"
  >
    {children}
  </Flex>
)
