import { CustomerComboInput } from 'components/FormComponents'
import { FC, useRef, useId, useState, useEffect } from 'react'
import { Label, ListContentProps } from '@sequencehq/forms'
import { Box } from '@chakra-ui/react'
import { CustomerModel } from '@sequencehq/core-models'
import {
  useGetCustomersQuery,
  useLazyGetCustomersByIdQuery
} from 'features/api'
import * as Sentry from '@sentry/react'
import { ExtractQArgs, ExtractQD } from 'lib/types'
import { FieldValidator } from 'final-form'

type CustomerComboInputCompleteProps = {
  fieldName: string
  disabled?: boolean
  addNewAction?: () => void
  filterProperty?: keyof ExtractQArgs<ExtractQD<typeof useGetCustomersQuery>>
  fieldLabel?: string
  itemToContent?: (item: CustomerModel) => ListContentProps
  validate: FieldValidator<string> | undefined
  setCustomer?: (customer: CustomerModel) => void
  labelPadding?: number
}

export const CustomerComboInputComplete: FC<
  CustomerComboInputCompleteProps
> = ({ fieldLabel = 'Customer', labelPadding, ...props }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const customerInputId = useId()

  const [customerId, setCustomerId] = useState<string>()
  const [customer, setCustomer] = useState<CustomerModel>()
  const [customerQueryTrigger] = useLazyGetCustomersByIdQuery()

  useEffect(() => {
    if (!customerId) {
      return setCustomer(undefined)
    }

    customerQueryTrigger({ id: customerId })
      .then(res => {
        const customerValue = res.data?.value()
        setCustomer(customerValue)
        if (customerValue && props.setCustomer) {
          props.setCustomer(customerValue)
        }
      })
      .catch(err => Sentry.captureException(err))
  }, [customerId, customerQueryTrigger])

  return (
    <>
      <Label htmlFor={customerInputId}>{fieldLabel}</Label>
      {!!labelPadding && labelPadding > 0 && <Box height={labelPadding || 2} />}
      <CustomerComboInput
        id={customerInputId}
        inputRef={inputRef}
        model={customer ?? null}
        modelId={customerId ?? ''}
        setModelId={setCustomerId}
        {...props}
      />
    </>
  )
}
