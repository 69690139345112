import { Box, Text } from '@chakra-ui/react'
import {
  HorizontalSelectorField,
  NumberField,
  PriceField,
  SelectField,
  SelectFieldWithActions,
  TextField
} from '@sequencehq/core-components'
import { UsageMetricParameterFields } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/utils/UsageMetricParameterFields'
import { AvailableStandardFrequency } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { usePackagedPriceForm } from './usePackagedPriceForm'
import { PricingInformation } from '../common/PricingInformation'
import { GreyGrey90 } from '@sequencehq/design-tokens'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'

export const PackagedPriceStandaloneVariantPriceForm = () => {
  const { fieldsConfig, currency } = usePackagedPriceForm()
  return (
    <>
      <Text
        color={GreyGrey90}
        fontWeight="bold"
        fontSize="l"
        textColor="##1D2939"
        mb="6"
      >
        Pricing Information
      </Text>
      <PricingInformation
        value={fieldsConfig.pricingModel.value}
        isDisabled={fieldsConfig.pricingModel.disabled}
        options={fieldsConfig.pricingModel.options}
        validationErrors={fieldsConfig.pricingModel.validationErrors}
        onChange={fieldsConfig.pricingModel.onChange}
        description={fieldsConfig.pricingModel.description}
      />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="standard-field-name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      {!fieldsConfig.currency.hidden && (
        <SelectField
          label="Currency"
          data-testid="standard-field-currency"
          value={fieldsConfig.currency.value}
          onChange={newCurrency =>
            fieldsConfig.currency.onChange(newCurrency as Currency)
          }
          options={fieldsConfig.currency.options}
          validationErrors={fieldsConfig.currency.validationErrors}
          isDisabled={fieldsConfig.currency.disabled}
          placeholder="Select currency"
        />
      )}

      <Box paddingBottom="8px">
        <HorizontalSelectorField
          label="Frequency"
          data-testid="common-field-billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />
        <SelectFieldWithActions
          label="Usage Metric"
          options={fieldsConfig.usageMetricId.options}
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          value={fieldsConfig.usageMetricId.value}
          onChange={fieldsConfig.usageMetricId.onChange}
          isDisabled={fieldsConfig.usageMetricId.disabled}
          placeholder="Select usage metric"
          onAddNew={fieldsConfig.usageMetricId.onAddNew}
        />
        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>
        <PriceField
          label="Price per package"
          validationErrors={fieldsConfig.pricePerPackage.validationErrors}
          value={fieldsConfig.pricePerPackage.value}
          onChange={fieldsConfig.pricePerPackage.onChange}
          isDisabled={fieldsConfig.pricePerPackage.disabled}
          currency={currency}
        />
        <NumberField
          label="Package size"
          validationErrors={fieldsConfig.packageSize.validationErrors}
          precision={0}
          value={fieldsConfig.packageSize.value}
          onChange={fieldsConfig.packageSize.onChange}
          isDisabled={fieldsConfig.packageSize.disabled}
        />
      </Box>

      <ExternalLedgerForm />
    </>
  )
}
