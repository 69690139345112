import { Flex } from '@chakra-ui/react'
import { MutationFormProps } from 'components/Form/types'
import Spinner from 'components/Loading'
import {
  UpdateCreditNoteStatusFormMutation,
  UpdateCreditNoteStatusFormProps
} from 'components/UpdateInvoiceStatus/types'
import { useGetMerchantForSequenceAccountQuery } from 'features/api'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

const VoidCreditNoteForm: FC<
  MutationFormProps<UpdateCreditNoteStatusFormMutation> &
    UpdateCreditNoteStatusFormProps
> = ({ id, submitForm, formId }) => {
  const { data: merchant, isFetching: isFetchingMerchant } =
    useGetMerchantForSequenceAccountQuery()

  const merchantValue = merchant?.value()

  if (isFetchingMerchant || !merchantValue) {
    return <Spinner></Spinner>
  }

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={async () => {
        const promises: Promise<any>[] = [
          submitForm({
            id
          })
        ]

        await Promise.all(promises)
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            You won't be able to make any further changes to this Credit Note
            after voiding.
          </Flex>
        )
      }}
    />
  )
}

export default VoidCreditNoteForm
